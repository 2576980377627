import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 878 824">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  >
 
 

 <path fill="#E8343A" opacity="1.000000" stroke="none" 
	d="
M1.000000,441.000000 
	C1.000000,435.977905 1.000000,430.955811 1.262254,425.142944 
	C1.349672,416.568085 1.174836,408.784058 1.000000,401.000000 
	C1.000000,395.311951 1.000000,389.623932 1.390074,383.359924 
	C2.145181,380.837585 2.532048,378.895020 2.871887,376.944244 
	C6.551900,355.820038 8.764055,334.312408 14.172383,313.640839 
	C30.545498,251.059982 61.016506,195.807877 104.598732,147.949493 
	C174.810425,70.848862 262.084778,25.163271 364.517578,7.956114 
	C381.880005,5.039493 399.560089,4.013879 417.543915,2.223065 
	C432.256775,2.313597 446.519104,2.295665 461.116272,2.552186 
	C486.195984,2.953036 510.574707,6.441434 534.701782,11.514780 
	C612.548950,27.884207 681.939880,61.608978 741.279541,114.771828 
	C816.401794,182.074402 862.087219,265.406464 875.218750,366.304810 
	C875.500122,368.798157 875.864807,370.893036 876.207642,373.325562 
	C876.266968,374.105255 876.348206,374.547241 876.308960,375.281006 
	C876.372192,376.145416 876.555969,376.718079 876.705566,377.630310 
	C876.749878,378.645203 876.828430,379.320435 876.780762,380.174988 
	C876.786255,380.895721 876.918030,381.437134 877.021973,382.410828 
	C877.147400,384.849121 877.300598,386.855194 877.333008,389.317505 
	C877.450195,398.588196 877.832703,407.401886 877.881470,416.217377 
	C877.921936,423.521240 877.569031,430.827301 877.315125,438.430359 
	C877.261658,439.135498 877.283508,439.542664 877.197632,440.216400 
	C877.135376,440.669128 877.180908,440.855286 877.149170,441.451538 
	C877.012268,443.527557 876.952576,445.193481 876.762451,447.017609 
	C876.534668,447.472839 876.606140,447.721344 876.730652,448.319885 
	C876.525452,449.814270 876.436035,450.910065 876.166443,452.375214 
	C875.767395,455.506378 875.548462,458.268127 875.229858,461.402069 
	C875.051331,462.797760 874.972534,463.821289 874.691772,464.982727 
	C874.262207,465.429138 874.268433,465.732819 874.438477,466.354340 
	C874.383484,467.115967 874.398560,467.554901 874.224854,468.346771 
	C873.767761,470.820831 873.499451,472.941956 873.144043,475.472443 
	C859.133667,558.851135 821.885498,629.970337 763.644043,690.268616 
	C723.504639,731.825684 676.520264,763.639832 623.600037,786.669495 
	C590.986816,800.862061 557.147949,811.138367 521.679443,817.186890 
	C518.524475,817.758484 515.765259,818.382629 512.673706,819.001221 
	C511.903687,819.057312 511.466003,819.118958 510.620331,819.097168 
	C507.333527,819.551514 504.454712,820.089355 501.310059,820.601501 
	C500.699615,820.658447 500.355042,820.741089 499.680237,820.705933 
	C494.574982,821.219727 489.799957,821.851379 484.661041,822.458130 
	C483.576965,822.534241 482.856842,822.635254 481.878052,822.613037 
	C481.082306,822.664917 480.545166,822.840088 479.582520,822.980347 
	C477.453979,823.052979 475.750977,823.160461 473.602417,823.178040 
	C467.097992,823.421509 461.039154,823.754822 454.520081,823.998047 
	C443.707031,823.978149 433.354248,824.048401 422.548706,824.081299 
	C416.382446,823.792297 410.668915,823.540771 404.563354,823.215698 
	C403.108826,823.121643 402.046387,823.101074 400.736908,822.910034 
	C399.315552,822.768799 398.141235,822.798035 396.621704,822.739868 
	C395.331757,822.641113 394.386963,822.629761 393.128113,822.380005 
	C389.866882,821.863831 386.919769,821.585999 383.562469,821.225220 
	C381.215393,820.997864 379.278503,820.853516 377.045532,820.464966 
	C374.159912,819.915283 371.570404,819.609802 368.660400,819.190674 
	C367.887817,819.075562 367.435669,819.074097 366.647339,818.888794 
	C363.195374,818.226685 360.079651,817.748474 356.560913,817.176147 
	C343.013519,814.113159 329.722229,811.675476 316.748688,808.088562 
	C236.802551,785.985352 167.734482,745.484619 110.631981,685.085938 
	C77.626503,650.175171 51.509365,610.669312 32.583126,566.469360 
	C16.338346,528.531677 6.260427,489.036346 2.667092,447.897400 
	C2.463583,445.567505 1.571568,443.297760 1.000000,441.000000 
M47.744961,487.522949 
	C48.731770,491.387726 49.762348,495.241974 50.698338,499.119019 
	C57.173481,525.940369 66.885139,551.598877 79.556404,576.041016 
	C98.615891,612.805847 123.203445,645.507935 153.034348,674.326416 
	C188.635254,708.719116 229.328445,735.416138 274.719299,754.800537 
	C312.115509,770.770752 351.168182,780.954956 391.681244,785.291504 
	C432.823669,789.695435 473.730865,788.662476 514.545166,781.375549 
	C540.871704,776.675354 566.543152,769.768372 591.384766,760.086975 
	C650.133179,737.190918 701.296814,702.952637 743.825623,656.188477 
	C766.672485,631.066284 785.877808,603.408142 801.136841,573.032898 
	C820.929749,533.632507 833.105713,492.014771 837.542603,448.152924 
	C840.746582,416.478424 839.803650,384.834503 834.349976,353.536713 
	C827.912537,316.593842 815.974060,281.373260 798.468018,248.062119 
	C776.995117,207.202606 748.837341,171.613358 714.410461,141.047745 
	C677.840088,108.579048 636.596069,83.659317 590.857422,66.163239 
	C559.457764,54.152115 527.133606,45.889542 493.816010,41.686081 
	C461.309204,37.584919 428.731812,37.359718 396.090546,40.557743 
	C353.831482,44.698074 313.205841,55.202538 274.214600,71.779701 
	C243.325012,84.912460 214.550858,101.794006 187.786285,122.195923 
	C151.809082,149.620377 121.597298,182.276779 96.997803,220.156403 
	C73.269882,256.693909 56.564941,296.374939 47.910156,338.953674 
	C43.422741,361.030304 41.566944,383.786072 40.295864,406.335022 
	C38.773232,433.346375 42.175179,460.182953 47.744961,487.522949 
z"/>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" 
	d="
M876.429504,374.989258 
	C876.348206,374.547241 876.266968,374.105255 876.397339,373.018066 
	C876.173340,370.217407 875.737732,368.061859 875.302124,365.906342 
	C862.087219,265.406464 816.401794,182.074402 741.279541,114.771828 
	C681.939880,61.608978 612.548950,27.884207 534.701782,11.514780 
	C510.574707,6.441434 486.195984,2.953036 461.187622,2.235772 
	C460.949463,1.429936 460.974731,1.214968 461.000000,1.000000 
	C600.257690,1.000000 739.515320,1.000000 879.000000,1.000000 
	C879.000000,130.020813 879.000000,259.041748 878.740906,388.511108 
	C878.139160,388.926758 877.796448,388.894012 877.453796,388.861267 
	C877.300598,386.855194 877.147400,384.849121 877.184998,382.239319 
	C877.219482,381.088959 877.063232,380.542328 876.906921,379.995697 
	C876.828430,379.320435 876.749878,378.645203 876.803284,377.336121 
	C876.766663,376.131317 876.598083,375.560272 876.429504,374.989258 
z"/>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" 
	d="
M874.893738,464.844818 
	C874.972534,463.821289 875.051331,462.797760 875.471680,461.138977 
	C875.990967,457.671082 876.168762,454.838440 876.346558,452.005829 
	C876.436035,450.910065 876.525452,449.814270 876.834595,448.155884 
	C877.130676,447.319977 877.076843,447.075348 876.892822,446.859406 
	C876.952576,445.193481 877.012268,443.527557 877.300232,441.355347 
	C877.575134,440.739288 877.687256,440.602966 877.655640,440.525024 
	C877.572266,440.319733 877.426392,440.139862 877.305420,439.949860 
	C877.283508,439.542664 877.261658,439.135498 877.585388,438.434387 
	C878.287292,438.093658 878.643677,438.046844 879.000000,438.000000 
	C879.000000,566.928833 879.000000,695.857666 879.000000,825.000000 
	C743.979187,825.000000 608.958252,825.000000 473.572235,824.767822 
	C473.487427,824.113098 473.767700,823.690552 474.047974,823.267944 
	C475.750977,823.160461 477.453979,823.052979 479.734772,823.137085 
	C480.920593,823.131287 481.528656,822.933777 482.136688,822.736328 
	C482.856842,822.635254 483.576965,822.534241 485.009888,822.630981 
	C490.485291,822.160400 495.247864,821.492065 500.010468,820.823730 
	C500.355042,820.741089 500.699615,820.658447 501.742249,820.733948 
	C505.302948,820.321594 508.165649,819.751099 511.028320,819.180603 
	C511.466003,819.118958 511.903687,819.057312 512.951599,819.188599 
	C516.399597,818.667542 519.237366,817.953430 522.075134,817.239380 
	C557.147949,811.138367 590.986816,800.862061 623.600037,786.669495 
	C676.520264,763.639832 723.504639,731.825684 763.644043,690.268616 
	C821.885498,629.970337 859.133667,558.851135 873.457397,475.322876 
	C874.043152,472.507294 874.228394,470.250580 874.413635,467.993866 
	C874.398560,467.554901 874.383484,467.115967 874.602234,466.199341 
	C874.997253,465.438782 875.016479,465.146484 874.893738,464.844818 
z"/>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" 
	d="
M417.093414,2.114600 
	C399.560089,4.013879 381.880005,5.039493 364.517578,7.956114 
	C262.084778,25.163271 174.810425,70.848862 104.598732,147.949493 
	C61.016506,195.807877 30.545498,251.059982 14.172383,313.640839 
	C8.764055,334.312408 6.551900,355.820038 2.871887,376.944244 
	C2.532048,378.895020 2.145181,380.837585 1.390074,382.891968 
	C1.000000,255.736481 1.000000,128.472961 1.000000,1.000000 
	C139.354156,1.000000 277.708435,1.000000 416.546753,1.279315 
	C417.051697,1.743953 417.072571,1.929276 417.093414,2.114600 
z"/>
<path fill="#FEFDFD" opacity="1.000000" stroke="none" 
	d="
M400.983917,823.080505 
	C402.046387,823.101074 403.108826,823.121643 404.669159,823.444885 
	C405.444672,824.165039 405.722321,824.582520 406.000000,825.000000 
	C271.074860,825.000000 136.149734,825.000000 1.000000,825.000000 
	C1.000000,697.312500 1.000000,569.624939 1.000000,441.468658 
	C1.571568,443.297760 2.463583,445.567505 2.667092,447.897400 
	C6.260427,489.036346 16.338346,528.531677 32.583126,566.469360 
	C51.509365,610.669312 77.626503,650.175171 110.631981,685.085938 
	C167.734482,745.484619 236.802551,785.985352 316.748688,808.088562 
	C329.722229,811.675476 343.013519,814.113159 356.784790,817.459351 
	C360.602295,818.248657 363.792938,818.660645 366.983551,819.072632 
	C367.435669,819.074097 367.887817,819.075562 368.916016,819.460938 
	C372.108612,820.132812 374.725098,820.420959 377.341583,820.709106 
	C379.278503,820.853516 381.215393,820.997864 383.809937,821.506287 
	C387.459076,822.119690 390.450653,822.369019 393.442200,822.618347 
	C394.386963,822.629761 395.331757,822.641113 396.848938,822.966797 
	C398.608856,823.214233 399.796387,823.147400 400.983917,823.080505 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M879.000000,437.531342 
	C878.643677,438.046844 878.287292,438.093658 877.660706,438.136414 
	C877.569031,430.827301 877.921936,423.521240 877.881470,416.217377 
	C877.832703,407.401886 877.450195,398.588196 877.333008,389.317505 
	C877.796448,388.894012 878.139160,388.926758 878.740906,388.979736 
	C879.000000,405.020905 879.000000,421.041779 879.000000,437.531342 
z"/>
<path fill="#FFD5D4" opacity="1.000000" stroke="none" 
	d="
M417.543915,2.223064 
	C417.072571,1.929276 417.051697,1.743953 417.015411,1.279315 
	C431.354218,1.000000 445.708466,1.000000 460.531342,1.000000 
	C460.974731,1.214968 460.949463,1.429936 460.852814,1.961319 
	C446.519104,2.295665 432.256775,2.313597 417.543915,2.223064 
z"/>
<path fill="#FF9294" opacity="1.000000" stroke="none" 
	d="
M423.001434,824.118713 
	C433.354248,824.048401 443.707031,823.978149 454.527924,824.225952 
	C454.996002,824.543945 455.000000,825.000000 455.000000,825.000000 
	C444.645782,825.000000 434.291534,825.000000 423.467590,824.779663 
	C422.997833,824.559326 423.001434,824.118713 423.001434,824.118713 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M455.467957,825.000000 
	C455.000000,825.000000 454.996002,824.543945 454.988159,824.316040 
	C461.039154,823.754822 467.097992,823.421509 473.602417,823.178101 
	C473.767700,823.690552 473.487427,824.113098 473.103577,824.767822 
	C467.311951,825.000000 461.623932,825.000000 455.467957,825.000000 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M422.548706,824.081299 
	C423.001434,824.118713 422.997833,824.559326 422.998901,824.779663 
	C417.645081,825.000000 412.290131,825.000000 406.467590,825.000000 
	C405.722321,824.582520 405.444672,824.165039 405.061218,823.518433 
	C410.668915,823.540771 416.382446,823.792297 422.548706,824.081299 
z"/>
<path fill="#FA5254" opacity="1.000000" stroke="none" 
	d="
M1.000000,401.468628 
	C1.174836,408.784058 1.349672,416.568085 1.262254,424.676086 
	C1.000000,417.312408 1.000000,409.624847 1.000000,401.468628 
z"/>
<path fill="#03AFF2" opacity="1.000000" stroke="none" 
	d="
M47.625824,487.120514 
	C42.175179,460.182953 38.773232,433.346375 40.295864,406.335022 
	C41.566944,383.786072 43.422741,361.030304 47.910156,338.953674 
	C56.564941,296.374939 73.269882,256.693909 96.997803,220.156403 
	C121.597298,182.276779 151.809082,149.620377 187.786285,122.195923 
	C214.550858,101.794006 243.325012,84.912460 274.214600,71.779701 
	C313.205841,55.202538 353.831482,44.698074 396.090546,40.557743 
	C428.731812,37.359718 461.309204,37.584919 493.816010,41.686081 
	C527.133606,45.889542 559.457764,54.152115 590.857422,66.163239 
	C636.596069,83.659317 677.840088,108.579048 714.410461,141.047745 
	C748.837341,171.613358 776.995117,207.202606 798.468018,248.062119 
	C815.974060,281.373260 827.912537,316.593842 834.349976,353.536713 
	C839.803650,384.834503 840.746582,416.478424 837.542603,448.152924 
	C833.105713,492.014771 820.929749,533.632507 801.136841,573.032898 
	C785.877808,603.408142 766.672485,631.066284 743.825623,656.188477 
	C701.296814,702.952637 650.133179,737.190918 591.384766,760.086975 
	C566.543152,769.768372 540.871704,776.675354 514.545166,781.375549 
	C473.730865,788.662476 432.823669,789.695435 391.681244,785.291504 
	C351.168182,780.954956 312.115509,770.770752 274.719299,754.800537 
	C229.328445,735.416138 188.635254,708.719116 153.034348,674.326416 
	C123.203445,645.507935 98.615891,612.805847 79.556404,576.041016 
	C66.885139,551.598877 57.173481,525.940369 50.698338,499.119019 
	C49.762348,495.241974 48.731770,491.387726 47.625824,487.120514 
M551.888428,572.631897 
	C551.888428,572.631897 551.822083,572.894348 552.287170,572.472473 
	C552.482056,572.103699 552.676941,571.734863 552.794189,571.061707 
	C552.858032,570.847778 552.991699,570.695190 553.929504,570.526855 
	C555.916504,569.370911 557.903503,568.214905 559.890503,567.058960 
	C560.515198,567.774109 561.139893,568.489319 561.764526,569.204468 
	C559.292358,570.253052 556.820129,571.301636 554.347961,572.350220 
	C554.814453,572.988831 555.280945,573.627380 555.747375,574.265991 
	C557.266235,574.976440 558.785095,575.686890 560.062378,576.284363 
	C565.394653,571.270874 562.411072,566.321838 560.454346,561.778320 
	C559.832642,560.334534 556.836731,559.913208 554.496521,558.807922 
	C554.249207,558.409424 554.001953,558.010986 553.932983,556.880798 
	C555.291748,553.861084 556.650452,550.841370 558.009155,547.821594 
	C557.829346,547.430359 557.649597,547.039062 557.469788,546.647827 
	C555.979065,547.739685 554.016296,548.536194 553.105652,549.991211 
	C551.657349,552.305237 550.740479,554.317566 547.430115,552.570374 
	C546.511353,552.085388 544.876160,552.957825 543.974731,553.130066 
	C545.628906,555.169556 546.821411,556.639832 547.626526,558.552490 
	C546.795227,559.054016 545.963928,559.555603 544.344299,560.021301 
	C543.296082,560.011292 542.247803,560.001343 540.676025,559.633972 
	C540.120972,559.329102 539.565857,559.024292 539.015137,557.952759 
	C539.027832,556.673462 539.040588,555.394226 539.047302,554.044006 
	C539.047302,554.044006 539.118347,554.048096 539.720276,553.816162 
	C540.097473,553.100647 540.474670,552.385193 541.021912,551.034912 
	C541.775696,549.692871 542.529480,548.350830 543.753784,546.828369 
	C543.432983,546.244446 543.112183,545.660522 542.147888,545.157654 
	C541.501709,545.134521 540.855469,545.111450 539.357483,545.036743 
	C537.551880,545.122681 535.746338,545.208679 532.958435,545.341492 
	C534.792114,547.037842 535.709839,547.886841 536.553040,549.264832 
	C536.302429,549.507141 536.051880,549.749512 535.159485,549.929688 
	C534.001587,549.534851 532.843689,549.140015 531.685791,548.745178 
	C531.832886,549.135132 531.979980,549.525085 532.127136,549.915039 
	C531.116638,550.246826 530.106201,550.578613 528.988342,550.181519 
	C528.016479,545.824036 523.882446,546.166016 521.266907,546.291382 
	C519.091492,546.395569 514.355042,545.930298 514.333374,550.998718 
	C513.888916,550.999146 513.444458,550.999573 512.943848,550.316528 
	C512.198120,549.687256 510.948547,548.403809 510.784698,548.530029 
	C509.157318,549.783447 507.709412,551.269897 506.210785,552.690430 
	C506.532928,553.079773 506.855072,553.469116 507.177216,553.858398 
	C509.118134,552.905640 511.059082,551.952820 513.134216,551.502136 
	C513.756165,551.334778 514.378052,551.167419 515.755676,550.948425 
	C518.175171,550.282898 520.594727,549.617371 524.329041,548.590149 
	C522.652710,551.634888 521.819031,553.149048 520.532959,555.058044 
	C519.916870,555.548523 519.300720,556.038940 518.043030,556.656982 
	C517.851868,556.761902 517.660706,556.866882 516.670593,556.970093 
	C514.545471,557.197998 512.420349,557.425903 509.451538,557.744263 
	C511.862122,560.941650 513.529297,563.153015 515.176147,566.161987 
	C515.666626,568.585999 516.157104,571.010010 517.152893,573.962830 
	C518.459717,573.681274 519.766541,573.399780 521.693542,573.109070 
	C522.301636,573.007568 522.909790,572.906067 524.541016,572.797485 
	C524.881470,572.777527 525.221924,572.757568 526.191895,572.590393 
	C526.804382,572.785034 527.416931,572.979614 528.290833,573.335876 
	C528.640137,573.356140 528.856567,573.187439 529.123657,572.431335 
	C529.289307,572.175598 529.516052,571.997803 530.618042,571.951233 
	C531.162476,571.932861 531.706970,571.914490 532.726929,572.324341 
	C534.078125,572.858398 535.429321,573.392395 537.259399,573.744995 
	C537.505188,573.513672 537.750977,573.282288 538.369751,572.509705 
	C539.898682,572.354431 541.427612,572.199158 542.971924,572.017639 
	C542.971924,572.017639 542.955017,572.041992 542.932495,572.580750 
	C543.627991,572.786560 544.323425,572.992432 545.756897,573.254211 
	C546.482666,573.129272 547.208496,573.004395 548.683105,573.037537 
	C549.441772,572.940491 550.200439,572.843506 551.146912,573.003418 
	C551.146912,573.003418 551.542969,572.994263 551.542969,572.994263 
	C551.542969,572.994263 551.627075,572.561157 551.888428,572.631897 
M578.269043,559.021851 
	C577.174744,559.021729 576.080444,559.021545 574.997986,559.002441 
	C574.997986,559.002441 574.979736,559.014465 575.016785,558.282104 
	C575.652405,556.716370 576.288086,555.150696 577.034912,553.311279 
	C572.978821,552.509766 571.625916,554.798218 570.943726,558.571838 
	C570.766785,558.868591 570.507202,559.040405 569.717102,559.383057 
	C569.505615,559.669128 569.294067,559.955261 568.789612,560.864502 
	C567.574768,563.647888 565.661621,566.348450 565.319153,569.235291 
	C564.875366,572.976074 567.084290,574.649353 571.752625,572.904602 
	C572.879944,572.866333 574.007324,572.828064 575.151978,572.845703 
	C575.151978,572.845703 575.190125,572.890198 575.205627,573.482910 
	C576.218201,573.428711 577.230835,573.374512 578.868774,573.131287 
	C579.518188,572.133423 580.167603,571.135559 581.180908,569.556885 
	C582.405212,569.038818 583.629578,568.520813 585.114136,568.620605 
	C586.410522,569.363281 587.706848,570.106018 589.111389,571.459412 
	C589.740417,571.962341 590.369507,572.465271 591.643311,573.130554 
	C592.078674,573.056824 592.513977,572.983093 593.570679,573.048157 
	C593.991699,572.995239 594.412781,572.942261 595.481506,572.931946 
	C596.656189,573.289124 597.830811,573.646301 598.543274,574.303223 
	C598.210449,574.850647 597.877563,575.398071 597.544678,575.945557 
	C597.785095,576.066345 598.025513,576.187134 598.265930,576.307922 
	C598.508606,575.536499 598.751282,574.765076 599.619568,573.759094 
	C600.987061,572.523682 602.354492,571.288269 603.921692,570.825684 
	C605.125000,574.903015 607.985229,573.234680 611.450256,572.850342 
	C612.386780,572.858887 613.323303,572.867432 615.019104,572.954285 
	C616.478577,572.337952 617.938049,571.721680 620.067078,571.129150 
	C621.155334,571.529053 622.243530,571.928955 623.269592,572.995239 
	C624.193542,573.131653 625.117432,573.268066 626.555481,573.131653 
	C626.927246,573.028137 627.299072,572.924622 628.354919,573.063293 
	C629.564636,573.896851 630.781677,574.720032 631.982483,575.566223 
	C634.358704,577.240784 635.596985,576.532654 636.295410,573.310608 
	C636.855713,573.075806 637.416016,572.840942 638.192383,572.863281 
	C638.288940,572.637817 638.385498,572.412415 638.530090,571.732910 
	C638.823547,571.397888 639.117065,571.062927 640.017822,570.774597 
	C640.259033,570.148682 640.500244,569.522827 640.663696,568.384033 
	C640.721741,568.045776 640.779785,567.707581 640.800171,566.679504 
	C637.064026,564.109253 640.583801,560.721436 639.774475,557.939331 
	C639.774475,557.939331 639.773376,557.813538 640.509949,557.720398 
	C640.850525,556.892334 641.191162,556.064331 641.540405,554.763367 
	C641.638000,554.447388 641.826782,554.201416 642.796021,553.858826 
	C645.397522,551.957703 646.495239,550.048950 642.901550,548.113586 
	C642.901550,548.113586 642.876465,548.185974 642.844604,547.635254 
	C642.545471,547.350403 642.246399,547.065552 641.947266,546.780701 
	C641.659668,547.248230 641.372009,547.715759 640.880737,548.628906 
	C640.466553,548.867798 640.052368,549.106628 638.829956,549.099060 
	C637.168274,548.783081 635.506592,548.467102 633.278381,548.043396 
	C633.674744,550.009399 633.912476,551.188416 634.150146,552.367493 
	C633.691284,552.139404 633.232361,551.911316 632.773438,551.683228 
	C631.213806,554.190063 629.654175,556.696899 627.858093,559.601685 
	C627.638000,559.835083 627.369812,559.978882 626.351807,559.945007 
	C625.516785,559.265564 624.681702,558.586182 624.018616,557.223450 
	C624.704407,555.484741 625.390259,553.746033 626.751648,551.830322 
	C627.393372,550.715149 628.035095,549.600037 628.676819,548.484863 
	C627.439514,547.994202 626.202148,547.503540 624.310608,547.044434 
	C623.504089,547.637512 622.697632,548.230652 621.468262,549.226196 
	C620.917175,549.776001 620.366089,550.325745 619.185425,550.885742 
	C618.573914,551.010742 617.962402,551.135681 616.598999,551.115112 
	C615.884155,550.746826 615.130066,550.437927 614.462891,549.997437 
	C613.210205,549.170532 611.207703,547.313416 610.918274,547.556030 
	C609.393921,548.834045 608.061035,550.510254 607.161438,552.299500 
	C606.627014,553.362427 607.014099,554.888733 606.322205,556.148376 
	C605.881348,556.091675 605.440552,556.035034 604.848816,555.310791 
	C601.510376,552.831543 597.954407,551.095215 593.006348,551.406799 
	C593.877258,553.459412 594.434387,554.772583 594.549438,556.076477 
	C594.549438,556.076477 594.107666,556.056519 593.445618,555.823120 
	C590.700378,552.165771 587.109131,548.842285 590.567993,543.531494 
	C591.317200,542.381287 590.426636,540.163147 590.291382,538.436890 
	C589.830139,538.412476 589.368896,538.388000 588.907715,538.363525 
	C587.940063,540.574890 586.972412,542.786194 585.347107,545.027588 
	C584.561584,545.354248 583.776123,545.680908 582.303711,546.300781 
	C581.925110,548.307861 581.005371,550.402222 581.311401,552.298828 
	C581.657104,554.440918 583.104553,556.405212 583.502380,558.528992 
	C583.125122,558.483154 582.747864,558.437378 581.871582,557.935242 
	C579.992004,556.044250 579.052917,556.429932 579.016907,559.027405 
	C579.016907,559.027405 579.089905,559.062500 578.269043,559.021851 
M337.157806,557.880615 
	C337.227417,558.170410 337.297058,558.460266 337.570862,558.987610 
	C337.347168,559.306274 337.123505,559.624939 336.159485,559.958801 
	C335.177734,559.933411 334.195984,559.908081 332.927490,559.381958 
	C332.626678,558.891235 332.325867,558.400574 331.800629,557.300293 
	C330.867889,556.866882 329.935181,556.433411 329.003998,555.328491 
	C329.006287,554.880798 329.008575,554.433044 329.776581,553.972290 
	C333.937347,553.336914 333.659302,551.412964 331.427368,548.689209 
	C328.410919,545.008057 325.742218,547.252136 322.369568,548.901672 
	C321.578094,548.600281 320.786621,548.298889 319.952606,547.274292 
	C319.240570,546.314392 318.528534,545.354553 317.816467,544.394653 
	C317.080750,545.148621 316.107269,545.783447 315.657501,546.680786 
	C314.635742,548.719482 313.871429,550.887146 312.202454,553.001038 
	C311.136200,553.001526 310.069916,553.001953 309.150665,552.294617 
	C309.542542,550.485474 309.934387,548.676270 310.326263,546.867126 
	C309.726013,546.778687 308.635162,546.521240 308.598694,546.627075 
	C307.989441,548.395752 307.500214,550.206726 307.016663,552.016968 
	C306.934418,552.324890 307.002563,552.672913 306.331909,553.001770 
	C305.885620,553.003601 305.439362,553.005432 304.329651,553.054504 
	C303.874451,553.998779 303.034363,554.950806 303.053192,555.885559 
	C303.082275,557.329773 303.698669,558.762146 303.563324,560.661682 
	C302.278870,559.978088 300.926270,559.395813 299.725098,558.588318 
	C297.658386,557.199036 295.708649,556.072876 293.407196,559.268921 
	C292.623260,559.915283 291.839325,560.561584 290.596954,560.732361 
	C289.428650,560.140930 288.260376,559.549500 286.835144,558.713562 
	C286.835144,558.713562 286.642975,558.415405 286.641327,557.738281 
	C284.227295,554.896973 282.998932,556.538391 281.676117,559.351746 
	C281.452118,559.591064 281.195618,559.788086 280.486481,560.051392 
	C280.212219,560.182739 280.016754,560.388794 279.528290,560.911255 
	C279.528290,560.911255 279.120392,561.085205 278.474518,561.172241 
	C278.039948,561.856262 277.605377,562.540283 276.897614,563.701660 
	C276.528046,564.105408 276.158508,564.509155 275.369537,565.104370 
	C275.255554,565.745117 275.141571,566.385864 275.050842,567.766785 
	C275.052551,568.507141 275.054260,569.247559 274.708160,570.334106 
	C275.053650,570.930115 275.399109,571.526062 276.170135,572.566467 
	C276.728271,573.167664 277.286407,573.768921 278.161011,574.957520 
	C279.815643,574.994690 281.542908,575.349487 283.107239,574.991638 
	C285.245575,574.502563 287.259949,573.471436 290.103760,572.943665 
	C292.335846,572.430969 294.567963,571.918274 297.546844,571.378235 
	C298.773132,571.151184 299.999451,570.924072 301.532593,570.955994 
	C301.532593,570.955994 301.868591,571.175781 302.059204,571.820679 
	C304.265991,572.034241 306.472748,572.247803 309.056152,572.951660 
	C310.527863,572.517883 311.999573,572.084045 314.126160,571.695374 
	C317.963928,573.601807 322.635559,570.243408 326.346619,573.623230 
	C326.702911,573.947632 328.315430,572.877991 329.352051,572.474243 
	C330.522949,572.018188 331.702057,571.583191 333.317078,571.761108 
	C335.222565,572.191528 337.128052,572.622009 339.824493,573.101379 
	C341.838318,572.770386 343.852173,572.439453 345.977661,572.064209 
	C345.977661,572.064209 345.882172,572.137817 346.011505,572.852783 
	C348.013092,574.453247 361.142303,574.671753 363.495880,572.911682 
	C363.495880,572.911682 363.814514,572.647949 364.582245,572.657166 
	C366.068512,572.403076 367.554779,572.148987 369.244293,572.335266 
	C369.450806,572.644409 369.734741,572.755371 370.810699,573.047546 
	C371.845978,572.920471 372.881287,572.793335 374.647980,572.576538 
	C377.337280,572.041809 380.026581,571.507080 383.623230,570.901184 
	C383.924835,570.868774 384.226471,570.836304 384.713348,571.360840 
	C384.846008,572.131653 384.978638,572.902405 385.354187,574.120300 
	C385.916443,574.033386 386.478729,573.946472 387.553009,573.508972 
	C388.350922,573.306519 389.148834,573.104126 390.381256,573.383423 
	C392.540070,574.159729 394.698853,574.935974 396.585999,576.267456 
	C395.784821,577.205322 394.983673,578.143127 393.527527,579.354004 
	C393.353516,581.217285 393.179504,583.080627 392.867798,585.633606 
	C391.605377,587.707153 390.342957,589.780762 388.782593,592.343689 
	C392.886780,592.156250 396.012970,592.013489 399.510864,591.853760 
	C398.280609,589.448975 397.115784,587.172119 396.022430,584.347717 
	C396.037231,583.978760 396.052063,583.609802 396.586700,582.818787 
	C398.086456,581.223450 399.586212,579.628174 401.707550,577.763672 
	C402.817169,576.203674 403.926819,574.643738 405.382812,572.615234 
	C406.100677,571.964844 406.818512,571.314392 408.301422,570.811829 
	C408.816406,570.839478 409.331360,570.867126 410.317322,571.461670 
	C411.201996,573.293762 412.086639,575.125793 412.971313,576.957886 
	C413.365967,576.739502 413.760590,576.521057 414.155243,576.302612 
	C414.056732,575.427429 413.958221,574.552185 413.844391,573.540771 
	C415.521484,573.871094 416.894836,574.141602 418.268188,574.412109 
	C418.556091,573.924438 418.843964,573.436768 419.131866,572.949097 
	C416.727325,571.620300 414.322784,570.291504 411.880859,568.235779 
	C410.894867,565.834473 409.908844,563.433228 408.493408,560.838684 
	C408.200531,560.698059 407.973602,560.486633 408.196869,559.783386 
	C408.472229,559.524536 408.747559,559.265625 409.753479,558.942627 
	C410.765259,557.683289 411.878052,556.483276 412.687592,555.105103 
	C412.819824,554.880005 411.284607,553.220703 411.079071,553.325012 
	C409.635834,554.057922 408.339203,555.079529 406.425446,556.083435 
	C405.951752,556.376831 405.478058,556.670166 404.353760,556.999451 
	C403.922363,556.954468 403.490967,556.909485 403.080139,556.364563 
	C403.065948,556.198181 403.051727,556.031860 402.624695,555.451843 
	C402.733704,554.635315 402.743164,553.789001 402.976746,553.009827 
	C403.276428,552.009827 403.756195,551.063843 404.158417,550.094604 
	C402.909790,550.287415 400.660492,550.284180 400.577972,550.705444 
	C400.108856,553.100037 400.157318,555.596008 399.626587,558.614380 
	C398.158630,559.757874 396.690674,560.901306 395.130951,561.050781 
	C395.068604,560.393982 395.006256,559.737183 394.962860,558.328491 
	C393.919037,556.609375 392.875183,554.890259 391.831329,553.171143 
	C391.411133,553.434570 390.990906,553.697998 390.570679,553.961426 
	C390.705200,555.214966 390.839722,556.468567 390.379547,558.049927 
	C388.502014,558.720154 386.624481,559.390381 384.446625,559.505615 
	C383.637634,559.347412 382.828674,559.189148 381.747101,558.474915 
	C381.445374,557.928162 381.143646,557.381348 380.919250,556.118896 
	C380.279724,554.410217 379.640198,552.701599 378.977692,550.206482 
	C378.776062,545.798889 375.378174,547.349976 372.805237,546.352905 
	C371.733948,544.943054 370.662659,543.533264 369.591400,542.123413 
	C368.510559,545.795227 369.891113,547.210876 372.857239,547.613281 
	C372.508331,548.125061 372.159424,548.636841 371.139771,549.115234 
	C370.465942,549.112183 369.792145,549.109131 368.338074,549.054749 
	C365.426636,548.656677 362.515198,548.258545 358.613739,547.725037 
	C358.457550,547.534485 357.501709,546.368347 356.041901,544.587341 
	C355.054474,547.726379 354.347595,549.973633 353.338898,553.180298 
	C355.777649,552.655151 357.419128,552.111389 359.091064,551.991028 
	C361.079193,551.847961 363.093536,552.068787 364.773529,552.748169 
	C361.513092,554.147766 358.252625,555.547302 354.786957,556.752441 
	C354.512604,556.613281 354.260620,556.653992 353.335327,556.960449 
	C352.582520,557.687500 351.829712,558.414551 350.817200,559.579590 
	C350.400085,559.863464 349.982971,560.147278 348.851013,560.177185 
	C347.703247,559.737732 346.555481,559.298279 344.959442,558.500000 
	C344.667847,558.252075 344.376251,558.004211 343.943970,557.296631 
	C343.250824,557.382690 342.557709,557.468750 341.113342,557.782959 
	C340.095093,558.016357 339.076813,558.249695 337.800842,557.921265 
	C337.533539,557.614197 337.266266,557.307068 336.989166,556.336853 
	C336.714050,555.514160 336.438934,554.691467 336.163788,553.868713 
	C335.746338,554.134521 335.328888,554.400391 334.911407,554.666199 
	C335.608032,555.444153 336.304626,556.222046 337.157806,557.880615 
M467.205322,558.481140 
	C467.144592,558.291382 467.083862,558.101562 466.776855,557.275879 
	C463.809509,557.603149 460.842133,557.930420 457.347046,558.533997 
	C456.044800,559.418335 454.742554,560.302673 452.714600,560.957458 
	C451.733307,559.884521 450.795807,558.767151 449.761505,557.747986 
	C447.689972,555.706665 445.649323,554.734131 443.759949,558.674683 
	C443.168030,558.977234 442.576080,559.279785 440.875336,559.302856 
	C440.121033,559.252686 439.366760,559.202515 438.275360,559.136719 
	C438.275360,559.136719 438.000916,558.940308 437.965881,558.305359 
	C437.096222,557.941895 436.145844,557.157043 435.374176,557.304810 
	C433.881989,557.590698 432.490540,558.402466 430.808380,558.854858 
	C430.808380,558.854858 430.807434,558.568542 430.923553,557.761719 
	C431.808533,555.823669 432.693512,553.885620 433.848053,551.357239 
	C428.890961,551.744019 428.594025,555.532715 427.177795,558.199463 
	C427.177795,558.199463 427.259796,558.196716 426.591736,558.248047 
	C426.042664,559.106567 425.493622,559.965088 424.808624,561.378845 
	C424.092438,561.944397 423.376221,562.510010 422.014832,563.489929 
	C421.724091,563.905212 421.433319,564.320496 421.568390,565.025635 
	C421.464050,565.335999 421.359680,565.646423 420.673157,566.181946 
	C420.115845,566.453857 419.558533,566.725708 418.586334,566.561768 
	C417.657990,566.219971 416.729645,565.878113 415.801300,565.536316 
	C415.613312,565.905701 415.425293,566.275146 415.237274,566.644592 
	C416.491119,567.425354 417.744934,568.206177 419.539307,569.259033 
	C420.088165,569.516052 420.637024,569.773071 421.612457,570.458618 
	C422.070465,571.350891 422.528473,572.243103 423.054565,573.968323 
	C423.622803,575.257812 424.191040,576.547302 424.803864,578.514771 
	C424.624573,579.630737 424.445282,580.746643 423.834259,582.325500 
	C424.332672,583.136353 424.831085,583.947144 425.329498,584.757996 
	C426.171783,584.015076 427.014069,583.272217 427.899445,582.164368 
	C428.035126,581.920410 428.241699,581.813599 429.292175,581.689575 
	C429.718842,580.836121 430.145477,579.982727 430.478851,578.676514 
	C430.565491,578.353943 430.763672,578.130310 431.667450,577.820801 
	C432.087097,577.159485 432.506775,576.498108 433.171417,575.418823 
	C433.593109,575.199219 434.014832,574.979675 435.170593,574.991638 
	C437.080170,575.150696 439.051666,575.693115 440.887665,575.396545 
	C446.262756,574.528381 451.500824,572.249817 457.075775,574.674866 
	C457.798035,574.988953 459.070129,574.038940 460.073395,573.113586 
	C460.024597,572.933044 459.975830,572.752502 460.578918,572.896118 
	C461.049835,572.817627 461.520721,572.739136 462.468414,572.735657 
	C462.816986,572.616821 463.001404,572.373535 463.444733,571.828979 
	C463.713074,571.667847 463.926147,571.452087 464.674530,571.160583 
	C465.233673,570.981018 465.792786,570.801453 467.116821,570.689087 
	C470.000397,569.867249 472.485535,568.666443 472.679810,565.754517 
	C470.438934,564.573303 468.659485,563.635315 466.992432,562.264343 
	C466.992432,562.264343 467.371063,562.026184 467.980072,561.969482 
	C468.541779,561.482117 469.103516,560.994812 469.665192,560.507446 
	C468.954193,559.998840 468.243225,559.490295 467.205322,558.481140 
M384.409760,475.685211 
	C383.899811,476.787811 383.389893,477.890411 382.850250,479.930908 
	C382.754578,489.280121 382.658905,498.629364 382.019287,508.018219 
	C381.577698,508.358856 381.136139,508.699493 380.694580,509.040161 
	C381.318390,509.367401 381.942200,509.694641 382.824005,510.923431 
	C382.830505,517.287292 382.837006,523.651184 382.457855,530.378357 
	C382.644104,531.236877 382.830383,532.095459 383.184937,533.573792 
	C384.411316,535.203857 387.414551,537.069214 383.232483,538.405273 
	C381.381958,538.996460 379.192444,538.915649 380.478485,541.352478 
	C380.919617,542.188232 383.827393,542.318054 385.348145,541.875916 
	C389.840820,540.569702 388.723480,536.539673 389.525696,532.874756 
	C390.112335,532.258423 390.698975,531.642090 391.891113,530.788330 
	C394.017792,529.091370 396.144470,527.394409 398.271179,525.697449 
	C397.825745,526.209106 397.380341,526.720764 396.934937,527.232483 
	C401.497162,529.543701 406.059357,531.854919 410.621582,534.166138 
	C409.297699,535.843262 410.294952,534.564880 411.419403,534.441040 
	C417.259186,533.797791 423.131439,533.448914 429.502960,533.003784 
	C429.673126,533.005798 429.843323,533.007751 430.189819,533.590820 
	C431.124207,533.396301 432.058624,533.201782 433.006958,532.283813 
	C433.669861,531.189209 434.332794,530.094604 435.718445,528.991455 
	C436.808563,528.330078 437.898712,527.668701 439.479523,527.190125 
	C440.319794,527.128418 441.160034,527.066711 442.199799,527.657898 
	C443.927582,531.333069 445.878540,534.699463 450.674438,533.507629 
	C450.061523,535.244263 449.555237,536.678772 449.048950,538.113403 
	C450.612091,537.669678 452.188904,537.268433 453.734650,536.770752 
	C455.416016,536.229370 457.077850,535.623047 458.730835,534.999084 
	C460.169006,534.456177 461.583008,533.849304 463.626801,532.968872 
	C464.080292,532.962158 464.533783,532.955444 465.501923,533.386047 
	C466.945984,532.407959 468.390015,531.429932 469.946472,529.933655 
	C470.528900,529.594299 471.111298,529.254883 472.210083,528.638794 
	C472.434845,528.323364 472.659607,528.007996 472.856018,527.843384 
	C472.856018,527.843384 472.707672,527.804443 473.434814,527.782166 
	C474.367188,526.794983 475.155426,525.578247 476.268738,524.879211 
	C477.627869,524.025818 479.259949,523.607056 481.209534,523.532471 
	C481.289459,523.976196 481.369415,524.419861 480.638245,524.921692 
	C479.555054,525.769836 478.471863,526.617981 477.388672,527.466064 
	C478.713715,527.550232 480.041840,527.723511 481.362457,527.678101 
	C481.961151,527.657593 482.544952,527.203186 483.625732,527.092773 
	C483.809723,527.536377 483.993713,527.980042 484.189514,529.199646 
	C487.890747,536.115540 495.168274,534.112549 499.745605,535.241333 
	C498.386810,538.140930 497.092407,540.852844 495.843079,543.585388 
	C494.872406,545.708252 493.958099,547.856995 492.944489,549.362854 
	C492.516144,548.809509 492.087799,548.256104 491.659454,547.702759 
	C490.830841,549.250000 490.002258,550.797241 488.994659,552.678772 
	C488.994659,552.678772 488.687836,552.901611 488.029877,552.916931 
	C487.006989,553.548096 485.984131,554.179321 484.441162,555.116577 
	C483.708496,555.446838 482.975830,555.777039 481.688171,556.385254 
	C481.467072,556.938416 481.246002,557.491516 481.112762,558.467041 
	C481.112762,558.467041 481.069672,558.896301 480.977875,559.711792 
	C480.882263,561.058594 480.786652,562.405457 480.011810,564.070557 
	C476.531464,567.180176 477.830902,571.045166 478.791443,574.556702 
	C479.042725,575.475403 481.613983,576.106689 483.172729,576.237061 
	C485.367615,576.420776 487.611877,576.014404 490.036926,575.846313 
	C489.688263,574.676819 489.231384,573.144348 489.465759,571.452209 
	C490.019287,571.847473 490.572845,572.242737 491.370819,573.338806 
	C492.983643,573.673035 494.747437,574.588135 496.176788,574.216553 
	C498.867981,573.517090 501.366364,572.075684 504.767487,570.972107 
	C506.407898,570.732666 508.048279,570.493286 509.688690,570.253845 
	C509.760712,569.749512 509.832733,569.245178 509.904755,568.740906 
	C508.593811,568.166870 507.282867,567.592834 505.484680,567.011658 
	C505.484680,567.011658 504.997589,566.997559 504.731537,566.621643 
	C504.534515,566.383545 504.306396,566.180298 503.593262,565.966431 
	C503.593262,565.966431 503.137085,565.962830 502.998260,565.172974 
	C502.456268,563.324951 501.914276,561.476990 501.218475,559.120789 
	C501.194061,558.945557 501.169647,558.770264 501.082977,557.955444 
	C499.642883,555.313538 498.233948,555.354614 496.485657,558.350464 
	C496.321930,558.391296 496.158173,558.432190 495.392761,558.205322 
	C494.516876,558.123962 493.640961,558.042542 492.815735,557.172058 
	C493.024414,556.140259 493.233063,555.108398 493.947449,553.864685 
	C494.287201,553.245544 494.626984,552.626404 495.836121,552.002869 
	C498.082336,551.385620 500.328583,550.768372 502.574768,550.151123 
	C501.674133,547.325134 500.773499,544.499084 499.584839,540.769348 
	C499.776520,540.653259 501.006470,539.390503 502.410675,539.151306 
	C507.448120,538.293518 509.551239,535.525208 509.050690,529.943604 
	C510.320496,528.944214 511.590271,527.944824 513.626221,526.942871 
	C515.421814,524.958252 517.217346,522.973633 519.012939,520.989014 
	C518.497620,521.075012 517.982300,521.161011 517.466980,521.247009 
	C519.450500,525.095703 520.967224,529.310791 523.592285,532.656128 
	C525.172607,534.670044 528.563965,537.574829 530.914856,531.997742 
	C531.284973,531.666504 531.655029,531.335205 532.742859,530.696350 
	C533.154419,529.675781 533.879883,528.669800 533.928406,527.632263 
	C534.343933,518.740540 534.636963,509.843109 535.175232,500.355988 
	C535.717651,500.099091 536.260071,499.842224 537.272705,500.143677 
	C540.424805,500.435913 543.576965,500.728149 547.259766,501.695526 
	C547.282227,503.091125 547.304626,504.486725 546.645752,506.163635 
	C545.472229,506.838531 544.298645,507.513458 543.624390,507.901245 
	C548.148315,518.909180 546.294861,529.078491 542.986633,539.311340 
	C545.916931,537.743164 546.059265,533.022217 550.869385,534.972107 
	C549.880188,536.708130 549.046753,538.170776 548.213379,539.633362 
	C548.439087,540.107422 548.664795,540.581543 548.890503,541.055664 
	C550.914978,540.308655 554.749817,539.226135 554.683167,538.866638 
	C554.038086,535.385986 559.295654,532.602722 555.455933,528.129639 
	C555.528259,523.118896 555.600586,518.108154 556.222473,512.560364 
	C557.192322,509.713074 557.568787,506.249603 562.000000,507.797577 
	C562.000000,508.865143 562.000000,509.932709 561.332214,511.049194 
	C560.777527,511.515717 559.716919,512.016052 559.745483,512.443604 
	C560.069153,517.287659 560.871460,522.117859 560.913086,526.957214 
	C560.950684,531.345520 562.918945,533.051453 567.467712,533.452515 
	C568.648376,534.634216 569.829102,535.815857 571.452148,537.408386 
	C571.968933,538.272278 572.485657,539.136108 572.206360,540.023193 
	C567.172791,540.535950 569.636353,544.482971 568.963989,547.753052 
	C567.682190,550.470642 566.400391,553.188293 564.554871,557.101074 
	C570.239624,554.644958 572.583618,551.926880 571.679382,546.718323 
	C572.119568,544.478088 572.559753,542.237793 573.715088,540.009521 
	C575.258179,538.533691 578.149841,544.114563 579.633972,538.882568 
	C580.758057,538.253174 581.882141,537.623779 583.635437,536.806396 
	C584.090332,534.870911 584.545166,532.935486 585.722290,531.039368 
	C586.885925,531.603882 588.000366,532.340027 589.225220,532.691284 
	C590.904480,533.172729 592.667480,533.362183 594.394592,533.676819 
	C594.622314,532.036255 595.106934,530.381775 595.019226,528.758240 
	C594.877686,526.137634 594.598694,523.474670 593.935120,520.945679 
	C593.220886,518.223328 591.996765,515.634705 590.982361,512.265503 
	C590.255615,511.322876 589.528809,510.380249 588.802002,509.437622 
	C588.535706,510.621765 588.269470,511.805908 587.505127,513.410645 
	C586.668457,513.607056 585.831787,513.803528 584.995789,513.064819 
	C584.995789,508.106171 584.995789,503.147522 584.995789,498.427826 
	C582.420959,497.954620 580.392639,497.581879 578.049805,497.137207 
	C578.049805,497.137207 577.976501,496.822998 577.851257,496.530914 
	C577.743286,496.343140 577.598389,496.190369 577.022522,495.656769 
	C576.960205,495.288147 576.897888,494.919525 576.706665,493.757111 
	C575.853088,493.291534 574.999451,492.825989 574.054443,492.424591 
	C574.054443,492.424591 573.972534,492.348602 573.853394,491.548676 
	C571.035583,488.266815 567.689392,488.006866 563.354553,491.152008 
	C562.625977,491.236877 561.897400,491.321747 560.710083,490.809845 
	C558.518982,489.928070 556.327820,489.046295 554.136719,488.164520 
	C556.025879,491.795258 554.442200,492.826416 551.771667,493.260895 
	C551.771667,493.260895 551.764465,493.341797 551.718933,492.601440 
	C549.814514,491.472656 547.830200,490.456543 546.030884,489.179688 
	C544.572205,488.144623 542.720581,487.038727 542.128357,485.536469 
	C540.873718,482.353790 539.457397,481.514404 536.673401,483.759949 
	C535.161133,484.979706 533.339417,485.898956 532.075989,487.324768 
	C530.120667,489.531372 528.714172,489.420746 526.854553,487.229614 
	C525.995605,486.217468 524.462463,485.777466 523.234985,485.078064 
	C523.111023,485.429016 522.987061,485.779938 522.863098,486.130890 
	C523.669373,487.882355 524.475647,489.633850 525.263245,491.285736 
	C525.263245,491.285736 525.364563,491.286987 524.627258,491.224457 
	C520.674683,491.555420 515.951538,490.932159 517.247559,497.553284 
	C517.316284,497.904449 515.299377,498.663849 514.195679,499.140625 
	C514.195679,499.140625 514.261108,499.053345 514.597168,498.191467 
	C513.826050,496.612671 513.467773,494.287476 512.208618,493.591003 
	C507.932495,491.225861 503.112640,489.809784 498.944977,487.294617 
	C496.195343,485.635254 494.322418,482.550873 493.217773,488.417450 
	C493.073639,489.182892 490.467163,489.484680 488.302002,489.696350 
	C486.535492,489.464630 484.768951,489.232941 482.346863,489.008453 
	C481.522125,489.284851 480.697418,489.561249 479.872711,489.837646 
	C480.248871,490.225922 480.625061,490.614166 481.000641,491.802734 
	C480.997986,492.869995 480.995331,493.937286 480.299011,495.167297 
	C478.383972,496.471802 476.468903,497.776306 474.464508,499.009308 
	C474.464508,499.009308 474.507050,498.903046 474.684357,498.107452 
	C474.458130,497.071228 474.231903,496.035004 474.711334,494.960236 
	C475.610870,494.410370 476.510406,493.860474 477.409943,493.310577 
	C476.715118,492.567078 476.153503,491.466919 475.300018,491.148254 
	C472.925964,490.261749 470.430695,489.699860 467.327087,489.011353 
	C466.889618,489.010620 466.452148,489.009857 465.506226,489.004974 
	C465.336761,489.004791 465.167267,489.004608 464.105865,489.002167 
	C461.071503,489.648132 458.037140,490.294067 454.517334,491.372772 
	C453.688812,491.501282 452.860321,491.629791 451.193054,491.420929 
	C449.432159,491.420929 447.671265,491.420929 445.838654,491.420929 
	C445.677704,490.093140 445.530518,488.879059 445.261719,486.661957 
	C443.072327,488.541687 441.523438,489.871521 440.778900,490.510742 
	C442.232666,493.524078 443.203125,495.535645 444.322418,497.887543 
	C444.274902,498.188080 444.099274,498.329926 442.933228,498.169250 
	C439.100006,496.286804 434.843109,500.452484 430.986664,497.311066 
	C430.986664,497.311066 430.984985,497.197906 431.014465,496.437714 
	C431.816864,494.987305 432.619293,493.536865 433.421722,492.086426 
	C433.990784,492.225220 434.559845,492.364014 435.128906,492.502777 
	C434.865540,490.149231 434.602203,487.795685 434.338837,485.442108 
	C433.933502,485.327087 433.528137,485.212067 433.122772,485.097046 
	C431.722748,489.164337 429.749542,492.165894 424.786133,489.954651 
	C424.616150,488.561829 424.429413,487.031921 424.182831,485.011475 
	C421.993042,485.184723 418.616333,484.894287 418.417847,485.556458 
	C416.935455,490.502197 413.090637,488.666443 410.115387,488.314331 
	C409.657471,486.746460 409.199554,485.178619 408.741638,483.610748 
	C407.315338,484.028961 405.900085,484.491028 404.459717,484.853271 
	C402.960632,485.230316 401.437317,485.511047 399.297028,485.967804 
	C399.297028,488.467285 399.147675,491.883240 399.335297,495.280609 
	C399.563873,499.419556 396.130341,506.622406 392.966125,507.368988 
	C392.378479,506.576660 391.790802,505.784302 391.099884,504.333282 
	C391.123352,503.890472 391.146820,503.447693 391.760956,502.768127 
	C392.178314,501.837799 392.595673,500.907501 393.764709,499.910950 
	C394.840149,497.565247 396.706940,495.264465 396.842987,492.865479 
	C397.255219,485.597382 396.996979,478.291290 397.684570,470.962311 
	C398.613617,470.363861 399.542694,469.765442 400.471741,469.167023 
	C400.050354,468.765015 399.628937,468.363037 399.207550,467.961029 
	C398.471680,468.973999 397.735840,469.987000 396.345581,471.207916 
	C395.232819,473.140656 394.120087,475.073395 392.310547,477.005157 
	C391.845795,477.013275 391.381073,477.021393 390.386719,476.779633 
	C389.934570,476.410187 389.482391,476.040710 389.063782,475.028900 
	C389.063782,473.824860 389.063782,472.620819 389.063782,470.421387 
	C387.172791,472.778778 386.097626,474.119110 384.409760,475.685211 
M317.253754,537.659546 
	C321.365540,539.615234 322.160370,539.059265 323.228577,533.281982 
	C324.029999,532.462341 324.831421,531.642700 326.363892,531.018311 
	C327.213989,532.970886 328.064117,534.923462 329.233398,537.609070 
	C331.647705,536.132385 332.424164,533.175720 331.611359,530.011108 
	C330.578033,525.988220 327.939117,528.306763 325.496887,528.298035 
	C325.530182,523.805969 325.563477,519.313904 326.425201,514.872498 
	C330.291168,514.668457 330.830597,511.812408 330.938202,508.893494 
	C331.097321,504.577545 330.824554,500.241333 331.092194,495.935913 
	C331.194519,494.290558 332.109955,492.609711 332.958160,491.117462 
	C334.154724,489.012360 335.633087,487.067413 337.656097,484.965118 
	C338.101990,484.989410 338.547852,485.013733 338.982971,485.818512 
	C337.937073,489.344238 336.891174,492.869965 335.548279,497.396759 
	C336.977203,496.331116 337.926575,496.000854 338.112366,495.424469 
	C339.537933,491.003235 342.164551,489.130219 347.084717,488.522888 
	C350.629089,488.085358 352.419067,483.986267 352.811493,479.983276 
	C351.998016,479.904816 351.723236,479.820557 351.684265,479.883118 
	C351.002045,480.978943 350.378235,482.111664 349.683594,483.199188 
	C347.486694,486.638550 344.717346,487.176636 342.127960,484.307373 
	C342.676910,483.851501 343.225891,483.395599 344.299225,482.481903 
	C344.616791,480.632843 347.408752,478.453369 343.174652,476.882935 
	C342.719482,476.406372 342.264282,475.929840 341.887665,474.612335 
	C340.912689,473.271942 339.946991,470.773224 338.961273,470.765289 
	C324.656464,470.650269 310.348358,470.827057 296.042389,471.028870 
	C290.407288,471.108337 289.330231,472.507935 289.926300,478.463409 
	C292.135193,478.975311 294.380554,479.495697 296.802246,480.764862 
	C297.287689,484.467499 299.886108,485.130951 302.995270,485.851807 
	C302.997253,486.901611 302.999237,487.951416 303.164276,489.555450 
	C304.443665,489.370300 305.723053,489.185150 307.716370,489.006134 
	C308.811310,489.337921 309.906281,489.669708 311.002533,490.938629 
	C311.002563,501.923889 310.917236,512.910522 311.091736,523.893005 
	C311.118927,525.604187 312.330994,527.296509 312.999023,529.499512 
	C312.999908,529.666809 313.000824,529.834167 312.604706,530.460266 
	C312.159241,531.272400 311.713776,532.084595 310.843506,533.671326 
	C312.918396,532.855530 314.034576,532.416748 315.252106,532.284851 
	C315.252106,532.284851 315.218506,532.606384 314.898193,533.290955 
	C313.778564,535.558899 314.267578,536.915894 317.253754,537.659546 
M279.738159,412.616516 
	C279.588287,412.294952 279.341522,412.090149 278.999481,411.251801 
	C279.015839,410.501892 279.032196,409.751953 279.568054,408.778961 
	C279.470093,406.987274 279.372101,405.195587 279.284546,403.594879 
	C276.744171,403.811401 274.873566,403.970856 273.076660,403.364594 
	C273.358887,402.788940 273.485504,401.973511 273.946960,401.674133 
	C279.583099,398.017365 279.955658,388.941162 274.604095,385.263824 
	C274.604095,383.751953 274.604095,382.262146 274.812012,380.214508 
	C274.885468,379.823853 274.958923,379.433228 275.441467,378.650543 
	C275.741852,378.173767 276.042267,377.697021 276.924774,376.753387 
	C277.324402,371.498108 277.723999,366.242798 278.604736,360.835815 
	C278.366180,360.219208 278.127625,359.602631 277.802429,358.342560 
	C277.258209,357.513062 276.714020,356.683533 275.925354,355.530457 
	C275.925354,355.530457 275.652161,355.230804 275.717865,354.669708 
	C275.712128,354.481476 275.706390,354.293243 276.417542,353.762878 
	C277.276337,352.137238 278.793427,350.542603 278.871674,348.880219 
	C279.115997,343.689911 280.162842,338.109406 275.138367,333.772003 
	C275.164734,333.606567 275.191132,333.441101 275.751343,333.164398 
	C276.262207,332.975800 276.773041,332.787201 277.999634,333.013123 
	C279.249908,333.635742 280.500183,334.258392 281.750458,334.881042 
	C282.176819,334.628906 282.603149,334.376770 283.029480,334.124634 
	C282.551300,332.131775 282.073090,330.138947 281.725647,328.690979 
	C279.751953,330.248383 278.638214,331.127197 277.560577,331.299377 
	C277.351715,330.216797 277.142853,329.134216 276.928436,327.109222 
	C276.924957,321.408844 276.921478,315.708466 277.316071,309.662598 
	C277.091827,308.852570 276.867584,308.042511 276.655792,306.722260 
	C276.488342,306.454895 276.320923,306.187531 276.153442,305.920135 
	C275.817780,306.194031 275.482086,306.467926 274.193481,306.738281 
	C259.206299,306.705627 244.212555,306.915039 229.235550,306.507843 
	C224.057327,306.367035 222.174896,307.978821 222.508133,313.664062 
	C222.364548,320.776733 222.220963,327.889404 221.614792,334.521515 
	C221.356186,333.901672 221.097580,333.281860 220.752975,332.007690 
	C219.553436,330.990631 218.353897,329.973602 216.987839,328.087769 
	C217.037231,325.730652 217.086624,323.373535 217.619080,320.368134 
	C218.360229,316.357208 219.101395,312.346283 220.007797,307.441101 
	C217.163101,307.052307 213.680832,306.191254 210.191895,306.163391 
	C190.704437,306.007629 171.210831,306.271729 151.730347,305.864410 
	C147.307312,305.771912 146.014816,307.046509 146.013260,310.988800 
	C146.010880,316.981232 145.918106,322.974213 145.803162,328.965790 
	C145.740662,332.223511 147.070618,334.003754 150.506943,333.938110 
	C153.336975,333.884033 156.169022,333.937103 159.012573,334.762482 
	C159.669434,337.306915 160.904266,339.853851 160.893478,342.395447 
	C160.788727,367.034393 160.465698,391.672394 160.264740,416.311188 
	C160.258453,417.082947 160.897629,417.859955 161.595764,419.458527 
	C162.700882,417.040466 163.421921,415.462799 164.207962,414.831421 
	C164.202972,417.977905 164.186905,421.124390 164.195297,424.270844 
	C164.212997,430.904297 165.280090,431.901520 172.068481,431.887482 
	C179.021378,431.873077 185.980621,432.095490 192.923798,431.845245 
	C195.178955,431.763977 197.392227,430.519897 200.325821,430.057587 
	C201.497147,430.957184 202.668472,431.856781 204.188553,433.024200 
	C205.751709,428.247925 201.875336,427.782501 199.936661,425.096436 
	C199.922241,422.393036 199.907822,419.689636 200.653076,417.066437 
	C201.874954,418.661804 203.096848,420.257172 204.987350,422.725555 
	C204.987350,392.520569 204.987350,363.221252 205.658646,333.863586 
	C206.107193,333.880096 206.555725,333.896606 207.002106,334.817444 
	C207.002106,361.631073 207.002106,388.444702 207.002106,415.296844 
	C211.404663,415.492676 213.069885,414.227234 213.050903,410.440704 
	C212.939224,388.162231 213.010208,365.882874 212.962387,343.603943 
	C212.959702,342.354431 212.602859,340.085297 212.149490,340.016632 
	C208.083847,339.401001 209.125076,336.404572 209.758423,333.889435 
	C212.990509,334.241058 216.283493,335.053253 219.105698,333.486603 
	C219.070068,337.122406 219.005219,340.758209 219.003632,344.394012 
	C218.993973,366.532379 219.004623,388.670715 218.986816,410.809082 
	C218.985458,412.499908 218.816620,414.190613 218.725632,415.881378 
	C218.120316,415.871918 217.515015,415.862457 216.909698,415.852997 
	C216.909698,389.948975 216.909698,364.044922 216.909698,338.140900 
	C216.351486,338.141296 215.793289,338.141693 215.235077,338.142059 
	C215.156937,339.884705 215.011993,341.627289 215.010803,343.369995 
	C214.995377,365.841248 215.019852,388.312592 214.966080,410.783691 
	C214.961411,412.734924 214.442474,414.681244 214.219513,416.636383 
	C213.871292,419.690125 212.905258,422.880676 213.472717,425.762878 
	C213.890991,427.887451 216.123413,429.900574 218.026031,431.323456 
	C218.535217,431.704254 220.798584,429.739441 222.733932,428.823883 
	C222.733932,428.823883 223.206680,428.727936 223.355820,429.251740 
	C224.246780,429.396545 225.137726,429.541321 226.868515,429.760956 
	C242.270706,429.779999 257.672913,429.799042 273.278290,430.265137 
	C273.742310,430.404572 274.206329,430.544006 274.980469,431.008148 
	C275.769806,430.936249 276.559143,430.864349 278.050385,430.498199 
	C278.760101,426.773346 279.469818,423.048492 281.515961,419.076355 
	C282.402985,418.905579 283.289978,418.734833 284.013580,418.418274 
	C284.006989,416.608643 284.000427,414.798981 283.008484,412.993744 
	C282.023132,412.995453 281.037781,412.997162 279.738159,412.616516 
M388.777222,466.993225 
	C390.259369,466.543671 391.753693,466.125854 393.195984,465.572540 
	C393.291168,465.535980 393.020538,463.963043 392.868225,463.953735 
	C391.177063,463.850464 389.346039,463.536255 387.819672,464.058685 
	C386.681244,464.448334 385.932983,465.977997 385.186584,467.584106 
	C386.120728,467.388580 387.054871,467.193085 388.777222,466.993225 
M345.024200,551.780640 
	C346.297180,552.810608 347.542053,553.883362 348.903809,554.778137 
	C348.998596,554.840393 349.961548,553.581421 350.520752,552.936890 
	C349.169067,552.294983 347.835388,551.609741 346.454803,551.037476 
	C346.036560,550.864136 345.488556,551.003784 344.945007,550.236084 
	C343.668488,544.852051 338.417328,548.113953 335.025787,544.553162 
	C337.398651,549.887451 339.049683,550.776917 345.024200,551.780640 
M341.780365,527.798035 
	C342.277771,528.134766 342.775177,528.471436 343.183777,528.805969 
	C343.183777,528.805969 343.199493,528.718567 343.130615,529.505798 
	C345.253296,531.576416 347.376007,533.646973 349.681183,535.895569 
	C348.373444,536.651245 347.154938,537.355408 345.936462,538.059509 
	C349.151489,538.995117 352.151154,538.655945 355.046387,539.061951 
	C362.260590,540.073547 366.056763,536.090088 368.782349,530.057678 
	C368.782349,530.057678 369.020477,530.001831 369.790649,529.963318 
	C370.753296,528.714783 371.715942,527.466248 372.612854,525.658569 
	C373.076691,525.105530 373.540558,524.552490 374.645081,523.834961 
	C375.969330,522.749207 377.293579,521.663452 378.828339,520.405090 
	C376.971954,519.105530 375.634979,518.169556 373.837006,516.910828 
	C375.715546,515.692261 376.987000,514.867554 378.585175,513.830811 
	C377.403412,512.102905 376.341187,510.549835 374.945343,508.207703 
	C374.816986,506.509277 374.688629,504.810883 375.329163,503.168060 
	C377.795410,503.807068 379.076294,503.109009 379.017426,500.304626 
	C378.913239,495.342041 379.034393,490.375153 378.977814,485.410980 
	C378.953674,483.292114 378.719940,481.175659 378.581421,479.058105 
	C378.157501,479.074310 377.733582,479.090515 377.309662,479.106689 
	C376.273376,485.477783 377.916840,492.241974 376.382233,498.539948 
	C375.766937,498.474915 375.151672,498.409912 374.536377,498.344879 
	C374.536377,496.564789 374.536377,494.784698 374.536377,493.004608 
	C374.793762,493.415009 375.051117,493.825439 375.308502,494.235840 
	C373.689117,495.068237 372.069702,495.900665 370.359741,496.727356 
	C370.359741,496.727356 370.317017,496.647278 370.634338,495.668152 
	C371.593170,487.671936 372.552002,479.675751 373.510834,471.679535 
	C372.917023,471.608917 372.323212,471.538269 371.729401,471.467651 
	C371.228882,474.260376 371.107727,477.193665 370.137512,479.812347 
	C368.739563,483.585510 366.496521,487.054596 368.745728,491.285278 
	C369.129211,492.006561 368.597443,493.214478 367.837921,494.195282 
	C367.142548,493.723816 366.447205,493.252319 365.593353,491.989227 
	C361.194305,487.441193 351.324646,487.680145 347.769653,492.868347 
	C347.118713,493.027405 346.467804,493.186462 345.019592,493.254395 
	C340.075653,495.238678 338.167145,498.159729 339.359985,502.666107 
	C339.114563,503.176941 338.869110,503.687744 337.797516,504.325684 
	C334.241364,510.399292 335.697510,516.642578 337.594330,522.695618 
	C338.144897,524.452576 340.406342,525.673279 341.880310,527.707397 
	C341.869476,527.518616 341.858612,527.329773 341.780365,527.798035 
M519.592773,423.556213 
	C518.486206,426.518768 519.219727,428.787262 522.043945,430.565979 
	C522.815735,429.172272 523.433411,428.056763 524.675598,427.266846 
	C537.048584,434.369507 549.970032,436.024750 563.866943,432.240540 
	C576.650635,428.759460 585.325256,421.412994 587.057678,408.104614 
	C588.467773,397.272308 588.755859,386.247345 588.847168,375.302002 
	C588.955322,362.341797 588.156189,349.376648 588.053162,336.411255 
	C587.962646,325.016296 584.298340,315.554047 574.088135,309.703644 
	C567.655151,306.017609 561.019165,302.198212 552.225525,305.102539 
	C551.138550,305.027985 550.051636,304.953430 548.306091,304.413910 
	C541.982910,302.842010 536.162292,304.932404 530.445923,307.107727 
	C520.414185,310.925323 515.722351,319.404724 512.521667,328.852051 
	C511.692627,331.299164 512.007629,334.174805 512.003540,336.853943 
	C511.971924,357.631927 512.084229,378.410614 511.950562,399.187775 
	C511.893036,408.133362 514.063782,416.160950 519.592773,423.556213 
M431.567688,310.270233 
	C431.521606,307.548340 430.135315,306.503540 427.432983,306.540527 
	C419.279114,306.652283 411.121216,306.727112 402.968903,306.585663 
	C399.149109,306.519379 397.548737,307.954254 397.555328,311.837952 
	C397.619751,349.791809 397.629364,387.745819 397.550232,425.699615 
	C397.541718,429.787781 399.068695,431.343262 403.176086,431.240326 
	C411.160309,431.040314 419.154633,431.124298 427.142426,431.245758 
	C430.329865,431.294250 431.722168,429.942932 431.697845,426.829315 
	C431.584778,412.353668 431.417603,397.877777 431.421753,383.402039 
	C431.427887,361.933228 431.562256,340.464508 432.579956,318.999878 
	C433.828461,319.000000 435.076996,319.000122 436.525452,319.906891 
	C436.556458,320.572388 436.613464,321.237885 436.614624,321.903412 
	C436.636230,334.427490 434.899567,333.473969 448.031525,333.323120 
	C454.782837,333.245544 454.929260,333.423950 454.933777,340.381531 
	C454.952301,369.019531 454.933807,397.657562 454.961792,426.295563 
	C454.964844,429.442627 456.458893,431.242157 459.841217,431.213226 
	C467.490570,431.147888 475.140594,431.140839 482.790344,431.142700 
	C489.143555,431.144226 489.514099,430.753967 489.501892,424.360260 
	C489.448364,396.374542 489.399597,368.388763 489.392181,340.403015 
	C489.390533,334.124329 490.074249,333.476440 496.208130,333.373688 
	C498.705139,333.331848 501.206146,333.376678 503.702179,333.467712 
	C506.567657,333.572205 508.179932,332.265259 508.210846,329.396820 
	C508.279022,323.068146 508.372040,316.734009 508.173492,310.410919 
	C508.086334,307.635773 506.828857,306.397949 503.350922,306.452820 
	C483.200958,306.770538 463.041016,306.822937 442.891815,306.502472 
	C438.962189,306.439972 436.817352,307.004791 435.368530,310.998108 
	C434.141479,310.999603 432.914429,311.001099 431.567688,310.270233 
M131.094727,440.988403 
	C350.082214,440.988403 569.069702,440.988403 788.057251,440.988403 
	C788.057251,440.333557 788.057312,439.678741 788.057312,439.023895 
	C568.764343,439.023895 349.471313,439.023895 130.044952,438.464752 
	C129.764694,437.978943 129.484436,437.493134 128.970016,436.094910 
	C128.959015,375.717194 128.946747,315.339447 128.948074,254.961731 
	C128.948105,253.971909 129.113922,252.982071 129.541183,251.665024 
	C129.431519,251.112442 129.321854,250.559845 128.991684,249.188950 
	C129.068924,247.788254 129.146164,246.387558 129.624435,244.394745 
	C130.390244,241.594864 129.095901,240.354034 126.422043,240.053986 
	C121.647430,239.518173 117.269081,240.131332 114.848724,243.052826 
	C115.308182,246.634048 115.937569,249.265030 115.940353,251.896667 
	C115.998817,307.186859 116.099609,362.477570 115.881439,417.766937 
	C115.843231,427.449615 118.251144,437.528839 112.060287,447.004974 
	C104.717506,446.928314 97.374733,446.851624 89.789619,446.149567 
	C88.401695,444.108765 87.013763,442.067963 85.625832,440.027161 
	C85.094261,440.497375 84.562691,440.967621 84.031120,441.437836 
	C83.743523,442.826385 83.909752,444.997925 83.092033,445.471893 
	C78.900635,447.901428 80.113625,451.774323 80.059418,455.268280 
	C80.001305,459.014709 82.020515,460.451447 85.764000,460.337433 
	C93.909996,460.089325 102.065651,460.177216 110.214836,460.006927 
	C114.423447,459.919006 116.108543,461.639862 116.056442,465.983368 
	C115.876762,480.961304 116.066360,495.943359 115.942963,510.922455 
	C115.903671,515.690857 115.175354,520.451172 115.060928,525.222351 
	C115.004883,527.559509 115.691841,529.848755 119.904228,528.847290 
	C122.272835,528.911194 124.641449,528.975037 127.465797,529.448486 
	C131.001251,529.794067 130.062256,527.885254 129.016251,525.165588 
	C129.070938,523.439941 129.125626,521.714233 129.590744,519.457275 
	C129.462723,517.975830 129.334686,516.494324 128.996216,514.094727 
	C129.063324,497.058746 129.130417,480.022736 129.656998,462.419586 
	C132.121399,461.620880 134.585449,460.123962 137.050247,460.122742 
	C352.155212,460.015411 567.260254,460.022491 782.365234,460.035645 
	C784.525696,460.035797 786.698364,460.111511 788.843323,460.350098 
	C791.331421,460.626801 793.795166,461.122772 797.658997,461.748627 
	C798.058960,457.955322 799.011963,453.714233 798.689026,449.572571 
	C798.563293,447.960449 795.774963,445.866943 793.811951,445.272461 
	C791.661377,444.621185 788.178894,442.036682 786.065247,447.071320 
	C568.873291,447.079498 351.681366,447.088562 134.489426,447.077148 
	C133.013855,447.077057 131.538300,446.794495 130.088913,446.069519 
	C129.802582,445.383575 129.516251,444.697601 129.030823,443.350189 
	C129.091949,442.898926 129.153061,442.447662 129.608627,441.735718 
	C129.830063,441.506989 130.020538,441.254852 131.094727,440.988403 
M284.499634,405.929901 
	C285.244720,407.440002 286.541199,408.917908 286.631561,410.466248 
	C286.902771,415.112427 286.692230,419.786102 286.689911,424.449036 
	C286.686920,430.433960 287.370483,431.190704 293.157654,431.157104 
	C298.982391,431.123291 304.810333,430.780640 310.629547,430.908234 
	C316.892487,431.045502 317.245636,430.901367 317.236572,424.647247 
	C317.217896,411.822205 317.149506,398.997192 317.166870,386.172241 
	C317.169006,384.590515 317.569336,383.009277 317.785187,381.427826 
	C319.338654,382.681580 319.934692,383.848938 320.105988,385.075653 
	C321.986481,398.543762 324.005554,411.998199 325.514648,425.509125 
	C325.999969,429.854034 327.734344,431.357910 331.813538,431.194153 
	C336.130615,431.020874 340.516785,431.487061 344.750275,430.854370 
	C346.406372,430.606842 348.801239,428.281830 349.033447,426.644104 
	C350.804688,414.151276 352.091736,401.590393 353.624268,389.062317 
	C353.824066,387.428986 354.563751,385.861664 355.053406,384.263763 
	C355.561279,384.387360 356.069153,384.510956 356.577057,384.634552 
	C356.667542,386.398285 356.833893,388.161926 356.836304,389.925781 
	C356.852570,401.751495 356.658081,413.582306 356.992279,425.398407 
	C357.050934,427.472229 359.174988,431.162872 360.522827,431.246582 
	C367.955505,431.708252 375.443024,431.222900 382.912292,431.188843 
	C386.108063,431.174255 387.445740,429.702606 387.377441,426.601257 
	C387.296783,422.938385 387.297760,419.273285 387.299530,415.609131 
	C387.316223,381.131104 387.300934,346.652954 387.416412,312.175232 
	C387.429871,308.153290 386.147644,306.503876 381.932281,306.572266 
	C370.610992,306.755890 359.284119,306.580750 347.959442,306.573639 
	C344.887299,306.571716 343.015503,307.777771 342.687103,311.199829 
	C341.797211,320.474335 340.731537,329.732697 339.658539,338.988525 
	C339.184113,343.081055 338.523590,347.152008 337.947571,351.232788 
	C336.349640,349.148560 335.870880,347.103210 335.591614,345.030975 
	C334.102722,333.983826 332.566162,322.941437 331.262878,311.871887 
	C330.833954,308.228485 329.414551,306.560883 325.571503,306.611938 
	C314.747223,306.755768 303.919556,306.631470 293.093292,306.650116 
	C287.108215,306.660400 286.706329,307.162628 286.712433,313.323761 
	C286.739899,340.972229 286.798492,368.621094 286.627380,396.268433 
	C286.614899,398.283478 284.864166,400.287750 283.290070,402.880310 
	C283.509552,403.826508 283.729034,404.772736 284.499634,405.929901 
M731.060852,420.056030 
	C731.069763,417.061371 730.871582,414.048309 731.136292,411.076416 
	C731.531128,406.644257 730.176086,404.613434 725.312012,405.091278 
	C721.027588,405.512146 716.662231,405.034149 712.338013,405.151947 
	C709.469360,405.230072 707.927368,404.250122 707.934814,401.209290 
	C707.950256,394.885345 707.693176,388.550659 708.030212,382.246185 
	C708.097473,380.989075 710.218872,378.969391 711.564087,378.817566 
	C715.506348,378.372650 719.578796,379.036438 723.507263,378.536163 
	C724.991638,378.347137 727.265320,376.249207 727.382385,374.869019 
	C727.830688,369.582581 727.503235,364.233734 727.558533,358.909180 
	C727.587524,356.120209 726.318726,355.071167 723.581604,355.166718 
	C719.758118,355.300171 715.924805,355.127991 712.098816,355.218811 
	C709.193054,355.287750 707.750427,354.086395 707.798706,351.108276 
	C707.877014,346.282257 707.978271,341.453247 707.885498,336.629028 
	C707.821289,333.288116 709.424744,332.319031 712.512878,332.428955 
	C716.668152,332.576782 720.834351,332.383453 724.995300,332.414032 
	C727.634155,332.433411 729.018982,331.408752 728.982544,328.584808 
	C728.909424,322.925751 728.953369,317.264893 728.984375,311.604950 
	C728.998596,309.009308 728.043884,307.590118 725.173096,307.602570 
	C710.192444,307.667542 695.211304,307.680176 680.230713,307.612854 
	C676.995422,307.598297 675.664917,308.953705 675.884216,312.132782 
	C675.986755,313.620056 675.780151,315.125885 675.780396,316.623322 
	C675.786377,352.248657 675.909424,387.874634 675.696228,423.498627 
	C675.664856,428.729492 677.437134,430.033630 682.299438,429.942657 
	C695.612122,429.693573 708.932678,429.904114 722.249756,429.844025 
	C732.350952,429.798431 730.920349,430.449005 731.060852,420.056030 
M667.133179,366.419128 
	C667.271179,353.139038 668.008240,339.829376 667.355225,326.588287 
	C666.844421,316.229706 660.083801,310.121765 650.004395,308.150696 
	C644.813232,307.135529 639.504761,306.369049 634.228210,306.215790 
	C623.749329,305.911407 613.251282,306.288300 602.770386,306.021057 
	C597.854065,305.895721 596.082520,308.146484 596.244690,312.585175 
	C596.402405,316.899963 596.906799,321.208679 596.920166,325.521088 
	C596.990295,348.160736 597.025513,370.801025 596.921143,393.440399 
	C596.872131,404.078644 596.501282,414.715759 596.237915,425.352448 
	C596.132385,429.611694 598.062866,431.745544 602.561035,431.796539 
	C610.384033,431.885284 618.208923,431.852509 626.032715,431.819305 
	C630.924255,431.798553 632.284607,430.004913 632.204956,425.079315 
	C632.000366,412.432892 632.049744,399.782562 631.937744,387.134338 
	C631.903687,383.283081 633.730042,381.756256 637.492371,381.942841 
	C640.312683,382.082764 643.159851,382.035614 645.976868,381.826508 
	C656.921448,381.014069 664.774292,375.525299 667.133179,366.419128 
M390.008179,331.513611 
	C390.008179,358.541443 390.008179,385.569305 390.008179,412.597137 
	C390.055573,412.597076 390.102936,412.597046 390.150330,412.596985 
	C390.150330,381.523621 390.150330,350.450287 390.150330,319.376953 
	C390.079498,319.437012 390.008698,319.497070 390.012909,319.493500 
	C390.012909,323.212280 390.012909,326.867615 390.008179,331.513611 
M460.933289,551.343445 
	C459.029297,548.998352 457.658173,543.487793 454.260712,550.428528 
	C453.876923,551.212524 451.098663,551.326111 449.615387,550.973633 
	C445.581207,550.015015 442.180115,550.305664 439.146057,554.005127 
	C443.503937,554.537903 447.153870,554.994873 450.807220,555.422363 
	C451.609741,555.516235 452.468964,555.690735 453.225769,555.509521 
	C456.039459,554.835815 458.821320,554.029297 461.930145,553.187256 
	C462.044403,553.459045 461.711243,552.666687 460.933289,551.343445 
M535.004883,522.302551 
	C535.171204,524.281860 535.337463,526.261169 535.503784,528.240479 
	C536.166809,528.239319 536.829834,528.238159 537.492859,528.237000 
	C537.771057,522.675842 538.049255,517.114685 538.344360,511.214508 
	C535.004944,512.686646 535.004944,512.686646 535.004883,522.302551 
M480.993225,533.636719 
	C476.833374,535.545776 478.665344,537.814392 481.309753,538.501892 
	C484.329041,539.286865 487.709320,538.683594 491.348297,538.683594 
	C487.622192,536.702271 484.642273,535.117798 480.993225,533.636719 
M419.193542,536.995544 
	C416.833221,536.995544 414.472931,536.995544 412.112610,536.995544 
	C418.187622,540.263855 423.804047,539.388855 427.799347,534.681458 
	C424.542267,535.641907 422.295258,536.304504 419.193542,536.995544 
M398.581543,541.520081 
	C400.091675,541.164185 401.601807,540.808289 404.330444,540.165222 
	C400.088379,538.468079 397.089233,537.268188 394.090057,536.068298 
	C393.803162,536.590515 393.516235,537.112671 393.229340,537.634888 
	C394.777863,538.929138 396.326385,540.223328 398.581543,541.520081 
M509.507111,488.997284 
	C511.089935,489.821136 512.672729,490.644958 514.255554,491.468781 
	C514.592285,490.842041 514.929016,490.215271 515.265686,489.588501 
	C512.382568,487.941467 509.499390,486.294434 506.616211,484.647369 
	C506.272339,485.190857 505.928497,485.734314 505.584625,486.277802 
	C506.676575,487.100403 507.768555,487.923035 509.507111,488.997284 
M474.247437,556.178406 
	C474.084320,554.702454 474.416840,552.771240 473.606842,551.889587 
	C472.914520,551.135986 470.911224,551.586853 469.487793,551.504944 
	C469.271667,552.072510 469.055573,552.640076 468.839447,553.207642 
	C470.428986,554.270752 472.018524,555.333801 474.247437,556.178406 
M297.716614,464.256348 
	C297.550110,465.555237 297.383606,466.854156 297.217072,468.153046 
	C297.673004,468.425598 298.128906,468.698151 298.584808,468.970673 
	C299.921234,467.670319 301.257660,466.369934 302.594086,465.069550 
	C301.139160,464.626617 299.684204,464.183655 297.716614,464.256348 
M421.313232,547.927246 
	C421.245850,547.188416 421.178467,546.449524 421.111084,545.710693 
	C419.351685,546.510132 417.481842,547.153015 415.930664,548.247559 
	C415.638062,548.454041 416.614014,550.458313 417.252808,552.379578 
	C418.957062,550.567932 419.948761,549.513672 421.313232,547.927246 
M478.634857,550.028809 
	C480.425262,550.531738 482.215668,551.034607 484.006104,551.537476 
	C484.141876,550.987000 484.277649,550.436462 484.413422,549.885925 
	C481.997406,548.628784 479.581421,547.371643 477.165436,546.114441 
	C476.938904,546.392883 476.712402,546.671326 476.485870,546.949707 
	C477.031982,547.824707 477.578064,548.699707 478.634857,550.028809 
M379.989990,533.505554 
	C379.351196,531.302063 378.712433,529.098633 378.073639,526.895142 
	C377.219055,529.503601 377.040253,532.071838 377.330566,534.585938 
	C377.415100,535.317871 378.839478,535.895020 379.649689,536.543152 
	C379.879333,535.731445 380.109009,534.919800 379.989990,533.505554 
M539.442139,534.095703 
	C538.056396,534.892517 536.670715,535.689331 535.285034,536.486145 
	C536.027222,537.228516 536.769348,537.970886 537.511475,538.713257 
	C538.309570,537.328552 539.107605,535.943848 539.442139,534.095703 
M450.049622,541.562073 
	C451.616333,544.082458 456.841339,543.804626 459.717194,540.663574 
	C456.443542,540.863464 453.547668,541.040283 450.049622,541.562073 
M570.466125,485.386810 
	C567.541260,480.443298 566.176880,485.854797 563.922302,485.359070 
	C564.020752,485.958466 564.119263,486.557892 564.217712,487.157288 
	C566.169434,486.765106 568.121155,486.372925 570.466125,485.386810 
M377.577454,469.958252 
	C378.190247,471.184601 378.803070,472.410919 379.415833,473.637238 
	C380.144287,472.843109 380.872711,472.048950 381.601196,471.254852 
	C380.434235,470.661865 379.267303,470.068909 377.577454,469.958252 
M425.589020,553.482117 
	C426.961304,551.044983 425.512695,550.548889 423.932098,551.713013 
	C422.682220,552.633545 422.260590,554.678650 421.471161,556.224365 
	C421.919342,556.496887 422.367554,556.769348 422.815765,557.041870 
	C423.630981,556.039124 424.446167,555.036438 425.589020,553.482117 
M485.952026,543.292664 
	C485.111298,544.154785 484.270569,545.016846 483.429810,545.878967 
	C483.799103,546.330566 484.168396,546.782166 484.537689,547.233765 
	C486.159332,546.280090 487.780975,545.326477 489.402618,544.372803 
	C489.253815,544.018616 489.105011,543.664368 488.956207,543.310120 
	C488.176117,543.252502 487.396057,543.194824 485.952026,543.292664 
M469.756317,573.094238 
	C471.075806,574.104675 472.395294,575.115173 473.714783,576.125610 
	C474.016357,575.654602 474.317932,575.183655 474.619537,574.712708 
	C473.735931,572.927185 473.374054,570.247131 469.756317,573.094238 
M561.618286,543.335815 
	C563.495667,539.832092 563.407471,539.650391 558.615845,538.919922 
	C559.594238,540.663147 560.293762,541.909485 561.618286,543.335815 
M359.921204,541.709900 
	C358.001221,541.498840 356.081207,541.287781 354.161194,541.076721 
	C354.153259,541.674011 354.145294,542.271301 354.137360,542.868591 
	C356.056091,542.696167 357.974823,542.523682 359.921204,541.709900 
M341.678864,464.818726 
	C342.745697,465.403503 343.812500,465.988312 344.879364,466.573090 
	C345.051910,465.857208 345.224457,465.141357 345.397003,464.425476 
	C344.266357,464.378418 343.135742,464.331360 341.678864,464.818726 
M293.621155,489.531830 
	C294.103119,488.682861 294.585114,487.833893 295.067078,486.984924 
	C294.198608,486.840607 293.330170,486.696289 292.461700,486.552002 
	C292.648407,487.493622 292.835114,488.435272 293.621155,489.531830 
M361.366547,484.919250 
	C360.145569,484.919250 358.924591,484.919250 357.703613,484.919250 
	C357.815552,485.556702 357.927490,486.194122 358.039429,486.831573 
	C359.199860,486.402771 360.360291,485.973969 361.366547,484.919250 
M557.822876,483.727875 
	C558.402527,484.685608 558.982178,485.643311 559.561829,486.601044 
	C560.053650,486.147186 560.545532,485.693298 561.037415,485.239410 
	C560.120728,484.610046 559.204102,483.980713 557.822876,483.727875 
M590.856812,414.629364 
	C590.340088,414.126129 589.823425,413.622864 589.306702,413.119629 
	C589.099121,414.113800 588.891541,415.108002 588.683899,416.102142 
	C589.381775,415.818878 590.079712,415.535583 590.856812,414.629364 
M352.155121,470.928802 
	C351.738953,470.139435 351.322784,469.350037 350.906586,468.560669 
	C350.392578,469.086029 349.878540,469.611420 349.364532,470.136780 
	C350.143066,470.542572 350.921631,470.948364 352.155121,470.928802 
M385.137329,547.209717 
	C384.460266,548.020020 383.783203,548.830261 383.106140,549.640503 
	C383.666168,549.957703 384.226166,550.274902 384.786194,550.592102 
	C385.103943,549.513184 385.421661,548.434326 385.137329,547.209717 
M546.042969,485.670685 
	C547.280151,486.085602 548.517334,486.500519 549.754517,486.915436 
	C549.838989,486.370850 549.923462,485.826233 550.007935,485.281647 
	C548.836243,485.281647 547.664490,485.281647 546.042969,485.670685 
M537.602905,505.591705 
	C538.542114,505.427399 539.481323,505.263062 540.420471,505.098724 
	C540.271179,504.536102 540.121826,503.973511 539.972473,503.410889 
	C539.241333,503.949677 538.510254,504.488464 537.602905,505.591705 
M418.894470,560.944641 
	C418.737610,559.954224 418.580780,558.963806 418.423920,557.973389 
	C417.938324,558.207703 417.452728,558.442078 416.967163,558.676392 
	C417.550415,559.631836 418.133698,560.587219 418.894470,560.944641 
M289.224091,467.428406 
	C289.826996,467.746216 290.429901,468.064056 291.032806,468.381866 
	C290.978516,467.658020 290.924225,466.934174 290.869934,466.210297 
	C290.309326,466.425659 289.748718,466.641022 289.224091,467.428406 
M463.964020,575.699158 
	C464.877808,576.004639 465.791595,576.310059 466.705383,576.615540 
	C466.801361,576.179199 466.897339,575.742859 466.993317,575.306519 
	C466.123505,575.306519 465.253723,575.306519 463.964020,575.699158 
M505.086578,575.130066 
	C504.333771,575.346008 503.580963,575.561951 502.828156,575.777893 
	C503.064758,576.176331 503.301361,576.574707 503.537994,576.973083 
	C504.237122,576.417786 504.936249,575.862488 505.086578,575.130066 
M281.314331,368.629059 
	C281.802277,367.908295 282.290192,367.187500 282.778137,366.466736 
	C282.368042,366.273438 281.957916,366.080139 281.547791,365.886871 
	C281.410522,366.616028 281.273224,367.345184 281.314331,368.629059 
M373.540985,538.905640 
	C373.190430,538.084961 372.839874,537.264221 372.489288,536.443542 
	C372.042511,536.747009 371.595703,537.050476 371.148926,537.354004 
	C371.808899,538.000977 372.468903,538.647949 373.540985,538.905640 
M387.745697,583.937317 
	C387.577209,584.936768 387.408691,585.936218 387.240204,586.935669 
	C387.734650,586.943176 388.229065,586.950684 388.723511,586.958191 
	C388.583710,585.958130 388.443878,584.958008 387.745697,583.937317 
M443.903870,481.711243 
	C444.446045,482.038940 444.988220,482.366669 445.530396,482.694366 
	C445.687805,482.335602 445.845245,481.976868 446.002686,481.618134 
	C445.423309,481.529419 444.843964,481.440674 443.903870,481.711243 
M279.894287,384.468323 
	C280.377533,384.256378 280.860779,384.044434 281.343994,383.832458 
	C280.956573,383.437744 280.569183,383.043060 280.181763,382.648346 
	C279.962036,383.128357 279.742340,383.608398 279.894287,384.468323 
M512.132996,539.767761 
	C511.404144,539.682678 510.675293,539.597595 509.946411,539.512512 
	C509.941284,539.832947 509.936127,540.153381 509.931000,540.473816 
	C510.659882,540.411621 511.388733,540.349487 512.132996,539.767761 
M525.866760,538.232178 
	C526.595764,538.317383 527.324768,538.402588 528.053772,538.487793 
	C528.076538,538.165527 528.099243,537.843262 528.122009,537.521057 
	C527.375366,537.584839 526.628723,537.648621 525.866760,538.232178 
M605.329590,547.394714 
	C605.661438,546.934631 605.993286,546.474487 606.325134,546.014343 
	C605.949890,545.819336 605.574585,545.624329 605.199341,545.429382 
	C605.070740,546.049072 604.942139,546.668823 605.329590,547.394714 
M464.101471,538.289490 
	C463.557159,537.960449 463.012878,537.631470 462.468597,537.302429 
	C462.310425,537.662781 462.152252,538.023071 461.994019,538.383362 
	C462.575775,538.472351 463.157501,538.561401 464.101471,538.289490 
M561.535400,547.896790 
	C561.746399,548.377930 561.957397,548.859070 562.168335,549.340271 
	C562.561584,548.954468 562.954834,548.568665 563.348083,548.182861 
	C562.869995,547.964172 562.391907,547.745544 561.535400,547.896790 
M395.751312,547.198975 
	C395.550507,547.634888 395.349701,548.070862 395.148895,548.506775 
	C395.729034,548.503906 396.309204,548.501038 396.889343,548.498169 
	C396.682739,548.064514 396.476105,547.630798 395.751312,547.198975 
M648.286560,551.907288 
	C647.961792,552.445557 647.637085,552.983765 647.312317,553.521973 
	C647.666748,553.678650 648.021179,553.835388 648.375549,553.992126 
	C648.463989,553.417053 648.552429,552.841919 648.286560,551.907288 
M561.552124,554.066711 
	C561.183716,554.066528 560.815247,554.066406 560.446838,554.066223 
	C560.554993,554.455078 560.663147,554.843933 560.771362,555.232788 
	C561.068054,554.989624 561.364685,554.746399 561.552124,554.066711 
M468.246307,485.793579 
	C467.756775,485.782898 467.267273,485.772217 466.777740,485.761505 
	C466.870941,486.022858 466.974579,486.510895 467.055573,486.507172 
	C467.451508,486.488983 467.841400,486.339661 468.246307,485.793579 
M474.505646,561.334229 
	C474.261353,561.630554 474.017059,561.926941 473.772766,562.223267 
	C474.161591,562.333618 474.550415,562.443909 474.939270,562.554260 
	C474.939606,562.184692 474.939972,561.815125 474.505646,561.334229 
M438.244019,545.794250 
	C437.756195,545.783569 437.268372,545.772888 436.780548,545.762268 
	C436.873383,546.022705 436.976593,546.509094 437.057251,546.505371 
	C437.451874,546.487244 437.840424,546.338440 438.244019,545.794250 
M431.951721,544.454834 
	C431.561157,544.558960 431.170593,544.663086 430.779999,544.767212 
	C430.874603,545.027832 430.969208,545.288391 431.063812,545.549072 
	C431.458405,545.297058 431.852997,545.045044 431.951721,544.454834 
M556.047668,544.545532 
	C556.438171,544.441406 556.828735,544.337219 557.219238,544.233093 
	C557.124695,543.972595 557.030151,543.712036 556.935669,543.451477 
	C556.541016,543.703369 556.146362,543.955261 556.047668,544.545532 
M488.952789,486.453674 
	C488.561554,486.558014 488.170319,486.662354 487.779114,486.766724 
	C487.873840,487.027740 487.968567,487.288757 488.063263,487.549744 
	C488.458557,487.297394 488.853821,487.045013 488.952789,486.453674 
M303.064392,549.444946 
	C303.064148,549.815247 303.063934,550.185486 303.063690,550.555725 
	C303.454346,550.447021 303.845001,550.338318 304.235687,550.229614 
	C303.991425,549.931458 303.747162,549.633301 303.064392,549.444946 
M503.046722,542.546997 
	C503.438385,542.442688 503.830048,542.338318 504.221710,542.233948 
	C504.126862,541.972778 504.032043,541.711670 503.937225,541.450500 
	C503.541443,541.703003 503.145691,541.955505 503.046722,542.546997 
M513.765564,428.231842 
	C514.031921,427.848511 514.298218,427.465179 514.564514,427.081787 
	C514.308472,426.974243 514.052368,426.866699 513.796326,426.759186 
	C513.677979,427.146179 513.559631,427.533142 513.765564,428.231842 
M511.505005,543.334106 
	C511.260651,543.630493 511.016296,543.926880 510.771973,544.223267 
	C511.160858,544.333679 511.549713,544.444092 511.938599,544.554504 
	C511.938965,544.184875 511.939362,543.815186 511.505005,543.334106 
M512.340576,533.902649 
	C511.959869,533.855408 511.579132,533.808228 511.198425,533.760986 
	C511.179382,534.020508 511.160309,534.280029 511.141266,534.539551 
	C511.529144,534.462158 511.916992,534.384766 512.340576,533.902649 
M402.116852,534.386780 
	C402.136780,534.152588 402.156677,533.918396 402.176575,533.684204 
	C402.043915,533.680359 401.911285,533.676575 401.778625,533.672791 
	C401.787109,533.907959 401.795563,534.143066 402.116852,534.386780 
M512.196472,481.649048 
	C512.014832,481.798248 511.833252,481.947479 511.651672,482.096710 
	C511.741425,482.194122 511.831146,482.291534 511.920898,482.389008 
	C512.082825,482.218018 512.244751,482.047028 512.196472,481.649048 
M479.196167,481.650208 
	C479.015106,481.798950 478.834045,481.947693 478.652985,482.096466 
	C478.742432,482.193604 478.831879,482.290741 478.921326,482.387878 
	C479.082764,482.217407 479.244171,482.046967 479.196167,481.650208 
M479.384430,541.883911 
	C479.151672,541.864075 478.918915,541.844177 478.686188,541.824341 
	C478.682312,541.956177 478.678467,542.087952 478.674591,542.219849 
	C478.908356,542.211548 479.142090,542.203247 479.384430,541.883911 
M424.196503,541.649414 
	C424.014954,541.798523 423.833435,541.947632 423.651886,542.096680 
	C423.741577,542.194092 423.831268,542.291443 423.920929,542.388855 
	C424.082794,542.217957 424.244659,542.047058 424.196503,541.649414 
M370.350006,466.196075 
	C370.201172,466.015015 370.052338,465.833954 369.903534,465.652863 
	C369.806366,465.742340 369.709198,465.831818 369.612061,465.921295 
	C369.782562,466.082733 369.953094,466.244171 370.350006,466.196075 
M467.115692,546.384460 
	C467.135559,546.151611 467.155426,545.918823 467.175262,545.685974 
	C467.043396,545.682129 466.911530,545.678345 466.779663,545.674500 
	C466.787994,545.908325 466.796356,546.142212 467.115692,546.384460 
M430.804657,540.348755 
	C430.985229,540.200378 431.165802,540.052063 431.346375,539.903687 
	C431.257202,539.806824 431.167999,539.709961 431.078827,539.613098 
	C430.917816,539.783081 430.756836,539.953064 430.804657,540.348755 
M308.195343,495.650970 
	C308.014709,495.799377 307.834076,495.947784 307.653442,496.096222 
	C307.742676,496.193176 307.831909,496.290100 307.921143,496.387054 
	C308.082214,496.216980 308.243256,496.046906 308.195343,495.650970 
M601.350220,549.196167 
	C601.201294,549.015015 601.052368,548.833862 600.903503,548.652710 
	C600.806335,548.742249 600.709167,548.831787 600.612000,548.921326 
	C600.782593,549.082764 600.953247,549.244263 601.350220,549.196167 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M499.680237,820.705933 
	C495.247864,821.492065 490.485291,822.160400 485.373840,822.655884 
	C489.799957,821.851379 494.574982,821.219727 499.680237,820.705933 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M521.679443,817.186890 
	C519.237366,817.953430 516.399597,818.667542 513.283936,819.194214 
	C515.765259,818.382629 518.524475,817.758484 521.679443,817.186890 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M366.647339,818.888794 
	C363.792938,818.660645 360.602295,818.248657 357.187805,817.553467 
	C360.079651,817.748474 363.195374,818.226685 366.647339,818.888794 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M393.128113,822.380005 
	C390.450653,822.369019 387.459076,822.119690 384.220093,821.589233 
	C386.919769,821.585999 389.866882,821.863831 393.128113,822.380005 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M510.620331,819.097168 
	C508.165649,819.751099 505.302948,820.321594 502.008087,820.759644 
	C504.454712,820.089355 507.333527,819.551514 510.620331,819.097168 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M876.166443,452.375214 
	C876.168762,454.838440 875.990967,457.671082 875.571350,460.766785 
	C875.548462,458.268127 875.767395,455.506378 876.166443,452.375214 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M874.224854,468.346771 
	C874.228394,470.250580 874.043152,472.507294 873.544556,474.913574 
	C873.499451,472.941956 873.767761,470.820831 874.224854,468.346771 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M875.218750,366.304810 
	C875.737732,368.061859 876.173340,370.217407 876.419250,372.680420 
	C875.864807,370.893036 875.500122,368.798157 875.218750,366.304810 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M377.045532,820.464966 
	C374.725098,820.420959 372.108612,820.132812 369.236511,819.574524 
	C371.570404,819.609802 374.159912,819.915283 377.045532,820.464966 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M400.736908,822.910034 
	C399.796387,823.147400 398.608856,823.214233 397.194092,823.054199 
	C398.141235,822.798035 399.315552,822.768799 400.736908,822.910034 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M481.878052,822.613037 
	C481.528656,822.933777 480.920593,823.131287 480.160278,823.171997 
	C480.545166,822.840088 481.082306,822.664917 481.878052,822.613037 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M876.780762,380.174988 
	C877.063232,380.542328 877.219482,381.088959 877.212769,381.807068 
	C876.918030,381.437134 876.786255,380.895721 876.780762,380.174988 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M876.308960,375.281006 
	C876.598083,375.560272 876.766663,376.131317 876.837524,376.996521 
	C876.555969,376.718079 876.372192,376.145416 876.308960,375.281006 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M876.762451,447.017609 
	C877.076843,447.075348 877.130676,447.319977 876.950317,447.757263 
	C876.606140,447.721344 876.534668,447.472839 876.762451,447.017609 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M877.197632,440.216400 
	C877.426392,440.139862 877.572266,440.319733 877.655640,440.525024 
	C877.687256,440.602966 877.575134,440.739288 877.377441,440.945251 
	C877.180908,440.855286 877.135376,440.669128 877.197632,440.216400 
z"/>
<path fill="#FBCDC6" opacity="1.000000" stroke="none" 
	d="
M874.691772,464.982727 
	C875.016479,465.146484 874.997253,465.438782 874.672363,465.876709 
	C874.268433,465.732819 874.262207,465.429138 874.691772,464.982727 
z"/>
<path fill="#DA3A43" opacity="1.000000" stroke="none" 
	d="
M90.031952,446.774933 
	C97.374733,446.851624 104.717506,446.928314 112.912872,447.033752 
	C119.033279,447.857086 117.850555,443.972076 117.852280,441.217102 
	C117.892601,376.906189 117.868073,312.595215 117.980537,248.284454 
	C117.984032,246.286942 119.319229,244.291748 120.033585,242.295471 
	C123.032745,243.411545 127.273743,240.287292 129.223389,244.986862 
	C129.146164,246.387558 129.068924,247.788254 128.967880,249.919830 
	C129.030167,251.097900 129.116272,251.545074 129.202377,251.992249 
	C129.113922,252.982071 128.948105,253.971909 128.948074,254.961731 
	C128.946747,315.339447 128.959015,375.717194 128.962189,436.881592 
	C129.037231,438.124176 129.120117,438.580078 129.095856,439.351593 
	C129.056381,440.100769 129.124054,440.534393 129.091827,441.227386 
	C129.066055,441.656677 129.140137,441.826538 129.214203,441.996399 
	C129.153061,442.447662 129.091949,442.898926 128.993927,443.985413 
	C129.325592,445.295044 129.694168,445.969452 130.062729,446.643860 
	C131.538300,446.794495 133.013855,447.077057 134.489426,447.077148 
	C351.681366,447.088562 568.873291,447.079498 786.941284,447.115234 
	C788.482849,447.183228 789.149902,447.249603 789.813599,447.224487 
	C793.629883,447.080109 796.947327,446.749084 797.117798,452.541901 
	C797.293823,458.523499 794.041382,458.575104 789.902832,458.342316 
	C789.072815,458.295593 788.237427,458.351105 787.404480,458.351044 
	C570.837952,458.339966 354.271454,458.328186 137.704926,458.316864 
	C136.039047,458.316772 134.371094,458.378082 132.707718,458.315002 
	C129.249298,458.183868 128.184616,459.789398 129.197510,462.986755 
	C129.130417,480.022736 129.063324,497.058746 128.958984,514.953003 
	C129.007935,517.203674 129.094116,518.596130 129.180298,519.988586 
	C129.125626,521.714233 129.070938,523.439941 128.918488,525.841797 
	C128.217163,527.358398 127.613625,528.198669 127.010071,529.038940 
	C124.641449,528.975037 122.272835,528.911194 119.311386,528.503418 
	C118.432602,526.210327 117.903526,524.261780 117.898331,522.311768 
	C117.846390,502.815125 117.798729,483.317535 117.957626,463.821930 
	C117.990662,459.768982 116.779182,458.135712 112.539055,458.258362 
	C104.048271,458.503937 95.544868,458.286621 87.047028,458.329498 
	C84.084831,458.344421 82.456009,457.543732 82.497009,454.052856 
	C82.569069,447.919128 82.365479,447.916748 90.031952,446.774933 
z"/>
<path fill="#E5353C" opacity="1.000000" stroke="none" 
	d="
M548.964661,304.878876 
	C550.051636,304.953430 551.138550,305.027985 552.946289,305.202332 
	C555.298767,305.640167 556.926270,306.000336 558.562927,306.312805 
	C576.758789,309.786957 585.900574,320.033600 586.235413,338.552917 
	C586.602905,358.876373 586.667175,379.221313 586.100708,399.535614 
	C585.935852,405.446716 584.370911,412.020020 581.344849,417.016052 
	C572.906860,430.947113 558.985962,432.849030 544.161499,431.637421 
	C527.173462,430.248993 514.349121,419.198120 514.268494,401.008057 
	C514.165161,377.704041 514.102234,354.376678 515.051819,331.101410 
	C515.491028,320.336945 522.356689,312.644470 532.561157,308.889679 
	C537.810242,306.958191 543.483215,306.178833 548.964661,304.878876 
M545.020752,346.503784 
	C545.116516,365.954315 545.174500,385.405212 545.347290,404.855072 
	C545.374268,407.891357 545.763733,411.304413 549.903870,411.284668 
	C553.803101,411.266113 554.549133,408.138306 554.843811,404.985138 
	C554.967407,403.662781 555.005066,402.328308 555.006897,400.999268 
	C555.032837,382.044800 555.063538,363.090363 555.048096,344.135895 
	C555.044678,339.983093 554.945007,335.826447 554.739624,331.679016 
	C554.599304,328.845001 553.828369,326.257141 550.248718,326.268433 
	C546.811584,326.279297 545.655151,328.735321 545.460266,331.563721 
	C545.140747,336.199371 545.150085,340.857635 545.020752,346.503784 
z"/>
<path fill="#E3363C" opacity="1.000000" stroke="none" 
	d="
M731.059875,420.485779 
	C730.920349,430.449005 732.350952,429.798431 722.249756,429.844025 
	C708.932678,429.904114 695.612122,429.693573 682.299438,429.942657 
	C677.437134,430.033630 675.664856,428.729492 675.696228,423.498627 
	C675.909424,387.874634 675.786377,352.248657 675.780396,316.623322 
	C675.780151,315.125885 675.986755,313.620056 675.884216,312.132782 
	C675.664917,308.953705 676.995422,307.598297 680.230713,307.612854 
	C695.211304,307.680176 710.192444,307.667542 725.173096,307.602570 
	C728.043884,307.590118 728.998596,309.009308 728.984375,311.604950 
	C728.953369,317.264893 728.909424,322.925751 728.982544,328.584808 
	C729.018982,331.408752 727.634155,332.433411 724.995300,332.414032 
	C720.834351,332.383453 716.668152,332.576782 712.512878,332.428955 
	C709.424744,332.319031 707.821289,333.288116 707.885498,336.629028 
	C707.978271,341.453247 707.877014,346.282257 707.798706,351.108276 
	C707.750427,354.086395 709.193054,355.287750 712.098816,355.218811 
	C715.924805,355.127991 719.758118,355.300171 723.581604,355.166718 
	C726.318726,355.071167 727.587524,356.120209 727.558533,358.909180 
	C727.503235,364.233734 727.830688,369.582581 727.382385,374.869019 
	C727.265320,376.249207 724.991638,378.347137 723.507263,378.536163 
	C719.578796,379.036438 715.506348,378.372650 711.564087,378.817566 
	C710.218872,378.969391 708.097473,380.989075 708.030212,382.246185 
	C707.693176,388.550659 707.950256,394.885345 707.934814,401.209290 
	C707.927368,404.250122 709.469360,405.230072 712.338013,405.151947 
	C716.662231,405.034149 721.027588,405.512146 725.312012,405.091278 
	C730.176086,404.613434 731.531128,406.644257 731.136292,411.076416 
	C730.871582,414.048309 731.069763,417.061371 731.059875,420.485779 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M436.292938,310.997162 
	C436.817352,307.004791 438.962189,306.439972 442.891815,306.502472 
	C463.041016,306.822937 483.200958,306.770538 503.350922,306.452820 
	C506.828857,306.397949 508.086334,307.635773 508.173492,310.410919 
	C508.372040,316.734009 508.279022,323.068146 508.210846,329.396820 
	C508.179932,332.265259 506.567657,333.572205 503.702179,333.467712 
	C501.206146,333.376678 498.705139,333.331848 496.208130,333.373688 
	C490.074249,333.476440 489.390533,334.124329 489.392181,340.403015 
	C489.399597,368.388763 489.448364,396.374542 489.501892,424.360260 
	C489.514099,430.753967 489.143555,431.144226 482.790344,431.142700 
	C475.140594,431.140839 467.490570,431.147888 459.841217,431.213226 
	C456.458893,431.242157 454.964844,429.442627 454.961792,426.295563 
	C454.933807,397.657562 454.952301,369.019531 454.933777,340.381531 
	C454.929260,333.423950 454.782837,333.245544 448.031525,333.323120 
	C434.899567,333.473969 436.636230,334.427490 436.614624,321.903412 
	C436.613464,321.237885 436.556458,320.572388 436.490753,319.013367 
	C436.401672,315.745636 436.347321,313.371399 436.292938,310.997162 
z"/>
<path fill="#E5353C" opacity="1.000000" stroke="none" 
	d="
M276.918030,310.008057 
	C276.921478,315.708466 276.924957,321.408844 276.716431,327.896301 
	C274.899567,329.552979 273.348816,331.023163 271.679199,331.173615 
	C267.557434,331.544952 263.381073,331.330566 259.226807,331.314941 
	C255.061371,331.299286 253.890900,333.207520 255.523544,337.429382 
	C255.604523,342.918945 255.675171,347.949890 255.482330,353.047516 
	C254.440369,356.254456 256.571594,356.240356 258.642944,356.249817 
	C262.456482,356.267242 266.270874,356.305328 270.083618,356.248566 
	C273.088348,356.203827 274.766968,357.300140 274.681030,360.597168 
	C274.568756,364.905060 274.989532,369.256744 274.489044,373.508148 
	C274.317200,374.967773 272.203186,377.107452 270.735107,377.327667 
	C267.003448,377.887512 263.134125,377.606476 259.322296,377.534454 
	C255.171143,377.456024 253.994171,379.289459 255.528763,383.496796 
	C255.531952,389.612640 255.522766,395.285431 255.269501,401.098633 
	C254.166382,405.084137 256.026672,406.354614 259.601410,406.298279 
	C264.259644,406.224854 268.941528,406.087952 273.570496,406.481171 
	C275.454315,406.641174 277.225891,408.122101 279.048553,409.002045 
	C279.032196,409.751953 279.015839,410.501892 278.799866,411.773254 
	C278.743469,412.863403 278.886688,413.432098 279.026123,414.417358 
	C279.022583,416.222595 279.022919,417.611176 278.805145,419.327026 
	C278.409027,423.942047 278.686920,428.477081 273.075104,429.818085 
	C257.672913,429.799042 242.270706,429.779999 226.336380,429.337463 
	C225.460495,427.316467 224.819305,425.719452 224.816635,424.121429 
	C224.755447,387.496826 224.818695,350.871918 224.689224,314.247650 
	C224.673203,309.717560 226.551361,308.603119 230.686157,308.658295 
	C243.835510,308.833832 256.989197,308.664978 270.139801,308.783752 
	C272.402710,308.804199 274.658783,309.580780 276.918030,310.008057 
z"/>
<path fill="#E4353C" opacity="1.000000" stroke="none" 
	d="
M217.136002,321.016418 
	C217.086624,323.373535 217.037231,325.730652 216.862381,328.859619 
	C213.751389,330.422546 210.786285,331.793762 207.776627,331.899750 
	C197.255890,332.270203 197.699661,330.148651 197.660217,342.297302 
	C197.572601,369.281708 197.504120,396.267365 197.706833,423.250458 
	C197.743805,428.172180 196.078278,429.667175 191.376343,429.423035 
	C185.563843,429.121124 179.720871,429.452332 173.891754,429.411560 
	C166.669525,429.361084 166.665863,429.317505 166.663376,422.235107 
	C166.653488,394.084473 166.573715,365.933441 166.716034,337.783569 
	C166.737518,333.535675 165.630997,331.517944 161.101913,331.923248 
	C158.129776,332.189178 155.111282,331.890472 152.116394,331.962250 
	C149.202209,332.032074 147.830795,330.798157 147.885544,327.802948 
	C147.970734,323.141968 148.084595,318.467712 147.830978,313.818420 
	C147.600525,309.593658 149.002838,307.948120 153.477127,307.995819 
	C172.130341,308.194733 190.787048,308.042358 209.442352,308.078308 
	C216.605301,308.092133 216.581497,308.164948 216.714386,315.578430 
	C216.746918,317.393036 216.989807,319.203857 217.136002,321.016418 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M283.921570,402.297028 
	C284.864166,400.287750 286.614899,398.283478 286.627380,396.268433 
	C286.798492,368.621094 286.739899,340.972229 286.712433,313.323761 
	C286.706329,307.162628 287.108215,306.660400 293.093292,306.650116 
	C303.919556,306.631470 314.747223,306.755768 325.571503,306.611938 
	C329.414551,306.560883 330.833954,308.228485 331.262878,311.871887 
	C332.566162,322.941437 334.102722,333.983826 335.591614,345.030975 
	C335.870880,347.103210 336.349640,349.148560 337.947571,351.232788 
	C338.523590,347.152008 339.184113,343.081055 339.658539,338.988525 
	C340.731537,329.732697 341.797211,320.474335 342.687103,311.199829 
	C343.015503,307.777771 344.887299,306.571716 347.959442,306.573639 
	C359.284119,306.580750 370.610992,306.755890 381.932281,306.572266 
	C386.147644,306.503876 387.429871,308.153290 387.416412,312.175232 
	C387.300934,346.652954 387.316223,381.131104 387.299530,415.609131 
	C387.297760,419.273285 387.296783,422.938385 387.377441,426.601257 
	C387.445740,429.702606 386.108063,431.174255 382.912292,431.188843 
	C375.443024,431.222900 367.955505,431.708252 360.522827,431.246582 
	C359.174988,431.162872 357.050934,427.472229 356.992279,425.398407 
	C356.658081,413.582306 356.852570,401.751495 356.836304,389.925781 
	C356.833893,388.161926 356.667542,386.398285 356.577057,384.634552 
	C356.069153,384.510956 355.561279,384.387360 355.053406,384.263763 
	C354.563751,385.861664 353.824066,387.428986 353.624268,389.062317 
	C352.091736,401.590393 350.804688,414.151276 349.033447,426.644104 
	C348.801239,428.281830 346.406372,430.606842 344.750275,430.854370 
	C340.516785,431.487061 336.130615,431.020874 331.813538,431.194153 
	C327.734344,431.357910 325.999969,429.854034 325.514648,425.509125 
	C324.005554,411.998199 321.986481,398.543762 320.105988,385.075653 
	C319.934692,383.848938 319.338654,382.681580 317.785187,381.427826 
	C317.569336,383.009277 317.169006,384.590515 317.166870,386.172241 
	C317.149506,398.997192 317.217896,411.822205 317.236572,424.647247 
	C317.245636,430.901367 316.892487,431.045502 310.629547,430.908234 
	C304.810333,430.780640 298.982391,431.123291 293.157654,431.157104 
	C287.370483,431.190704 286.686920,430.433960 286.689911,424.449036 
	C286.692230,419.786102 286.902771,415.112427 286.631561,410.466248 
	C286.541199,408.917908 285.244720,407.440002 284.565643,405.839203 
	C285.154694,405.046844 285.677734,404.345184 286.200745,403.643494 
	C285.441040,403.194702 284.681305,402.745880 283.921570,402.297028 
M306.820221,428.716980 
	C314.969177,428.290375 314.993866,428.290436 315.013519,420.095795 
	C315.062256,399.773376 315.078217,379.450897 315.134460,359.128510 
	C315.139252,357.399200 315.346497,355.670471 315.459656,353.941437 
	C316.891510,354.804108 317.491852,355.739746 317.652100,356.745514 
	C318.934204,364.791809 320.163483,372.846832 321.350800,380.907776 
	C323.484680,395.395294 325.477325,409.905243 327.833130,424.356232 
	C328.089203,425.927002 330.182800,428.116760 331.705597,428.377930 
	C335.261658,428.987793 338.989166,428.586212 342.647064,428.617859 
	C345.523804,428.642731 346.718506,427.159088 347.076172,424.370392 
	C349.700592,403.906830 352.390350,383.451477 355.135010,363.003662 
	C355.586548,359.639862 356.408386,356.325775 357.059113,352.988708 
	C357.738556,353.012146 358.418030,353.035614 359.097504,353.059082 
	C359.097504,357.892151 359.080780,362.725281 359.100464,367.558289 
	C359.175842,386.046906 359.355316,404.535889 359.273895,423.023773 
	C359.255829,427.123047 360.681000,428.732239 364.753387,428.660339 
	C369.909698,428.569214 375.078064,428.756012 380.231049,429.020477 
	C383.792297,429.203247 385.301331,427.980713 385.291321,424.190155 
	C385.194183,387.374756 385.201508,350.558929 385.270660,313.743439 
	C385.277405,310.145416 384.011810,308.624939 380.322388,308.678009 
	C370.495850,308.819397 360.664764,308.841461 350.838501,308.700867 
	C347.071838,308.647003 345.480988,310.205353 345.048950,313.868317 
	C343.957947,323.118256 342.632050,332.340637 341.384033,341.571869 
	C340.397003,348.872742 339.385925,356.170349 338.385559,363.469421 
	C337.955597,363.480133 337.525635,363.490814 337.095703,363.501526 
	C336.260559,359.298981 335.276154,355.119598 334.618439,350.889435 
	C332.700043,338.550995 330.840424,326.202240 329.117371,313.835266 
	C328.608337,310.181732 326.903961,308.659454 323.207184,308.702972 
	C313.380066,308.818756 303.548950,308.831055 293.722870,308.670654 
	C289.939575,308.608917 288.876251,310.253662 288.881805,313.785278 
	C288.939575,350.599884 288.913574,387.414673 288.845215,424.229279 
	C288.839142,427.503845 289.822296,429.231873 293.424164,429.020630 
	C297.576233,428.777100 301.745941,428.834381 306.820221,428.716980 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M129.427261,462.703186 
	C128.184616,459.789398 129.249298,458.183868 132.707718,458.315002 
	C134.371094,458.378082 136.039047,458.316772 137.704926,458.316864 
	C354.271454,458.328186 570.837952,458.339966 787.404480,458.351044 
	C788.237427,458.351105 789.072815,458.295593 789.902832,458.342316 
	C794.041382,458.575104 797.293823,458.523499 797.117798,452.541901 
	C796.947327,446.749084 793.629883,447.080109 789.813599,447.224487 
	C789.149902,447.249603 788.482849,447.183228 787.399048,447.013672 
	C788.178894,442.036682 791.661377,444.621185 793.811951,445.272461 
	C795.774963,445.866943 798.563293,447.960449 798.689026,449.572571 
	C799.011963,453.714233 798.058960,457.955322 797.658997,461.748627 
	C793.795166,461.122772 791.331421,460.626801 788.843323,460.350098 
	C786.698364,460.111511 784.525696,460.035797 782.365234,460.035645 
	C567.260254,460.022491 352.155212,460.015411 137.050247,460.122742 
	C134.585449,460.123962 132.121399,461.620880 129.427261,462.703186 
z"/>
<path fill="#01B2F6" opacity="1.000000" stroke="none" 
	d="
M129.191711,440.967987 
	C129.124054,440.534393 129.056381,440.100769 129.339951,439.370117 
	C129.691177,439.073059 130.178299,439.023895 130.178299,439.023895 
	C349.471313,439.023895 568.764343,439.023895 788.057312,439.023895 
	C788.057312,439.678741 788.057251,440.333557 788.057251,440.988403 
	C569.069702,440.988403 350.082214,440.988403 130.389694,440.959503 
	C129.684662,440.930603 129.191711,440.967987 129.191711,440.967987 
z"/>
<path fill="#3E96C8" opacity="1.000000" stroke="none" 
	d="
M217.377533,320.692261 
	C216.989807,319.203857 216.746918,317.393036 216.714386,315.578430 
	C216.581497,308.164948 216.605301,308.092133 209.442352,308.078308 
	C190.787048,308.042358 172.130341,308.194733 153.477127,307.995819 
	C149.002838,307.948120 147.600525,309.593658 147.830978,313.818420 
	C148.084595,318.467712 147.970734,323.141968 147.885544,327.802948 
	C147.830795,330.798157 149.202209,332.032074 152.116394,331.962250 
	C155.111282,331.890472 158.129776,332.189178 161.101913,331.923248 
	C165.630997,331.517944 166.737518,333.535675 166.716034,337.783569 
	C166.573715,365.933441 166.653488,394.084473 166.663376,422.235107 
	C166.665863,429.317505 166.669525,429.361084 173.891754,429.411560 
	C179.720871,429.452332 185.563843,429.121124 191.376343,429.423035 
	C196.078278,429.667175 197.743805,428.172180 197.706833,423.250458 
	C197.504120,396.267365 197.572601,369.281708 197.660217,342.297302 
	C197.699661,330.148651 197.255890,332.270203 207.776627,331.899750 
	C210.786285,331.793762 213.751389,330.422546 216.945633,329.294006 
	C218.353897,329.973602 219.553436,330.990631 220.516083,332.374573 
	C219.903152,332.710175 219.527100,332.678925 219.151047,332.647644 
	C216.283493,335.053253 212.990509,334.241058 209.046021,333.892334 
	C207.890488,333.901184 207.447388,333.907135 207.004272,333.913116 
	C206.555725,333.896606 206.107193,333.880096 204.992554,333.884216 
	C203.885849,333.922485 203.445267,333.940155 202.670959,333.941223 
	C201.892166,333.938019 201.447113,333.951385 200.719376,334.278931 
	C200.237808,336.063293 199.866608,337.533386 199.865616,339.003754 
	C199.848068,364.997894 199.875626,390.992065 199.893417,416.986237 
	C199.907822,419.689636 199.922241,422.393036 199.940430,425.930115 
	C199.837677,427.778442 199.731171,428.793091 199.624664,429.807709 
	C197.392227,430.519897 195.178955,431.763977 192.923798,431.845245 
	C185.980621,432.095490 179.021378,431.873077 172.068481,431.887482 
	C165.280090,431.901520 164.212997,430.904297 164.195297,424.270844 
	C164.186905,421.124390 164.202972,417.977905 164.199799,413.904663 
	C164.172684,388.688782 164.153717,364.399689 164.160416,339.694153 
	C163.789520,337.510223 163.392944,335.742737 162.597107,333.955078 
	C161.131958,333.937500 160.066071,333.940094 159.000183,333.942688 
	C156.169022,333.937103 153.336975,333.884033 150.506943,333.938110 
	C147.070618,334.003754 145.740662,332.223511 145.803162,328.965790 
	C145.918106,322.974213 146.010880,316.981232 146.013260,310.988800 
	C146.014816,307.046509 147.307312,305.771912 151.730347,305.864410 
	C171.210831,306.271729 190.704437,306.007629 210.191895,306.163391 
	C213.680832,306.191254 217.163101,307.052307 220.007797,307.441101 
	C219.101395,312.346283 218.360229,316.357208 217.377533,320.692261 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M431.642029,318.995728 
	C431.562256,340.464508 431.427887,361.933228 431.421753,383.402039 
	C431.417603,397.877777 431.584778,412.353668 431.697845,426.829315 
	C431.722168,429.942932 430.329865,431.294250 427.142426,431.245758 
	C419.154633,431.124298 411.160309,431.040314 403.176086,431.240326 
	C399.068695,431.343262 397.541718,429.787781 397.550232,425.699615 
	C397.629364,387.745819 397.619751,349.791809 397.555328,311.837952 
	C397.548737,307.954254 399.149109,306.519379 402.968903,306.585663 
	C411.121216,306.727112 419.279114,306.652283 427.432983,306.540527 
	C430.135315,306.503540 431.521606,307.548340 431.525696,311.070740 
	C431.536499,314.246063 431.589264,316.620911 431.642029,318.995728 
M399.539856,422.426941 
	C398.869720,427.152863 400.764526,429.147675 405.657990,428.817413 
	C410.636353,428.481415 415.650696,428.633881 420.649048,428.647125 
	C429.317505,428.670074 429.393494,428.628265 429.393799,420.190247 
	C429.394684,394.027466 429.390503,367.864685 429.385132,341.701904 
	C429.383301,332.703278 429.209259,323.700623 429.432831,314.707733 
	C429.547180,310.108307 427.731628,308.495453 423.248871,308.677307 
	C417.093353,308.927032 410.918182,308.827789 404.755035,308.701752 
	C401.006317,308.625092 399.484070,309.917725 399.497955,313.960175 
	C399.620911,349.787384 399.552277,385.615204 399.539856,422.426941 
z"/>
<path fill="#C74250" opacity="1.000000" stroke="none" 
	d="
M374.004395,523.999451 
	C373.540558,524.552490 373.076691,525.105530 372.314270,526.030029 
	C371.017273,527.601685 370.018890,528.801758 369.020508,530.001831 
	C369.020477,530.001831 368.782349,530.057678 368.405945,530.244263 
	C359.418945,534.702637 351.164093,533.815002 343.199493,528.718567 
	C343.199493,528.718567 343.183777,528.805969 343.189758,528.506470 
	C342.746399,527.851624 342.297089,527.496338 341.847778,527.140991 
	C341.858612,527.329773 341.869476,527.518616 341.844238,527.081665 
	C337.036652,519.567566 338.093903,511.845337 338.623718,504.198547 
	C338.869110,503.687744 339.114563,503.176941 339.568817,502.182678 
	C341.790741,498.914673 343.803802,496.130096 345.816864,493.345520 
	C346.467804,493.186462 347.118713,493.027405 348.326843,492.653381 
	C354.506653,492.552551 360.129272,492.666687 365.751862,492.780823 
	C366.447205,493.252319 367.142548,493.723816 368.166138,494.470703 
	C369.101898,495.379822 369.709473,496.013550 370.317017,496.647278 
	C370.317017,496.647278 370.359741,496.727356 370.535828,497.008850 
	C371.994690,499.231079 373.277496,501.171753 374.560303,503.112457 
	C374.688629,504.810883 374.816986,506.509277 374.987000,508.964111 
	C375.207703,512.866455 373.808105,514.159912 370.585510,514.102173 
	C363.634521,513.977539 356.667755,514.362366 349.729187,514.052063 
	C345.436066,513.859985 345.199890,515.943176 346.228210,518.922424 
	C347.659149,523.068237 350.386078,526.145874 354.925934,526.866882 
	C359.340698,527.568054 362.906616,526.235962 365.751038,522.412781 
	C366.889008,520.883301 369.370575,519.907776 371.352356,519.687988 
	C372.068176,519.608582 373.110870,522.476440 374.004395,523.999451 
M363.125336,499.260468 
	C356.909332,494.930481 349.436676,496.949890 346.714081,503.652496 
	C345.533173,506.559723 346.254669,508.150604 349.558716,508.163818 
	C354.194366,508.182343 358.830048,508.182617 363.465607,508.209137 
	C366.739258,508.227905 367.677490,506.619202 366.378571,503.774109 
	C365.717041,502.325134 364.635132,501.068085 363.125336,499.260468 
z"/>
<path fill="#BC495B" opacity="1.000000" stroke="none" 
	d="
M536.802490,499.585388 
	C536.260071,499.842224 535.717651,500.099091 534.704956,500.782227 
	C533.514648,503.189667 532.237122,505.152985 532.172791,507.155273 
	C531.917542,515.098206 532.041565,523.053284 532.025085,531.003906 
	C531.655029,531.335205 531.284973,531.666504 530.500122,532.222534 
	C525.808289,532.933044 524.191406,531.104309 524.301392,526.755676 
	C524.526672,517.852417 524.256714,508.937653 524.374023,500.030060 
	C524.412537,497.111847 525.018250,494.201141 525.364563,491.286987 
	C525.364563,491.286987 525.263245,491.285736 525.631470,491.403809 
	C528.213684,492.306885 530.427734,493.091919 533.078003,494.031677 
	C541.460632,490.044525 542.884766,489.973358 551.764465,493.341797 
	C551.764465,493.341797 551.771667,493.260895 551.819824,493.609375 
	C553.090088,495.882202 554.422852,496.966095 556.582214,494.993500 
	C558.010986,493.688324 559.633240,492.594879 561.168823,491.406616 
	C561.897400,491.321747 562.625977,491.236877 564.099121,491.073059 
	C567.886597,491.445618 570.929565,491.897095 573.972534,492.348602 
	C573.972534,492.348602 574.054443,492.424591 574.249268,492.720215 
	C575.241333,493.527527 576.038452,494.039215 576.835571,494.550903 
	C576.897888,494.919525 576.960205,495.288147 577.185669,496.059021 
	C577.453796,496.762695 577.663025,496.883270 577.976501,496.822998 
	C577.976501,496.822998 578.049805,497.137207 578.219604,497.542725 
	C578.714233,501.636505 579.039124,505.324768 579.253906,509.418823 
	C579.213135,511.216003 579.282288,512.607422 579.232544,514.453003 
	C579.087463,518.671509 579.292175,522.457031 578.949097,526.192322 
	C578.740967,528.458069 577.685791,530.646057 576.678894,532.740784 
	C575.893677,532.717163 575.440674,532.821716 574.747192,532.738770 
	C573.441223,531.988037 572.375732,531.424866 571.389709,530.408203 
	C571.285095,520.968506 571.100952,511.982178 570.993652,502.622925 
	C568.158813,496.867065 564.696655,495.931274 559.921631,499.265564 
	C554.911621,502.763947 555.130615,507.884857 555.672913,513.097412 
	C555.600586,518.108154 555.528259,523.118896 555.474609,528.858521 
	C553.843140,530.499023 552.192932,531.410645 550.542725,532.322266 
	C549.741943,530.707886 548.358459,529.134644 548.241150,527.471985 
	C547.734253,520.289734 547.598083,513.081238 547.327026,505.882324 
	C547.304626,504.486725 547.282227,503.091125 546.925354,500.997375 
	C543.563171,496.838562 540.357117,495.821991 536.802490,499.585388 
M530.248413,498.540314 
	C529.510437,497.238037 528.772461,495.935760 527.916077,494.424591 
	C524.493835,500.996948 525.320862,527.174683 529.329651,529.924988 
	C529.977112,519.495300 530.604919,509.381561 531.604614,499.124756 
	C531.747498,498.903748 531.890381,498.682709 532.033325,498.461700 
	C531.634094,498.572723 531.234924,498.683746 530.248413,498.540314 
M551.072754,497.270874 
	C546.488342,491.943604 541.419922,491.542236 535.331787,496.466583 
	C536.589966,496.348755 537.336121,496.295685 538.077942,496.206757 
	C547.330750,495.097504 549.974792,497.412079 549.962219,506.612732 
	C549.953430,513.095459 549.868958,519.579773 550.001099,526.059631 
	C550.031982,527.571899 550.804382,529.069092 551.235596,530.573181 
	C551.968811,530.512024 552.702026,530.450806 553.435242,530.389587 
	C553.787720,519.907959 554.140198,509.426270 554.500732,498.706360 
	C554.093689,498.597015 552.746887,498.235229 551.072754,497.270874 
z"/>
<path fill="#3A95CA" opacity="1.000000" stroke="none" 
	d="
M667.078979,366.831665 
	C664.774292,375.525299 656.921448,381.014069 645.976868,381.826508 
	C643.159851,382.035614 640.312683,382.082764 637.492371,381.942841 
	C633.730042,381.756256 631.903687,383.283081 631.937744,387.134338 
	C632.049744,399.782562 632.000366,412.432892 632.204956,425.079315 
	C632.284607,430.004913 630.924255,431.798553 626.032715,431.819305 
	C618.208923,431.852509 610.384033,431.885284 602.561035,431.796539 
	C598.062866,431.745544 596.132385,429.611694 596.237915,425.352448 
	C596.501282,414.715759 596.872131,404.078644 596.921143,393.440399 
	C597.025513,370.801025 596.990295,348.160736 596.920166,325.521088 
	C596.906799,321.208679 596.402405,316.899963 596.244690,312.585175 
	C596.082520,308.146484 597.854065,305.895721 602.770386,306.021057 
	C613.251282,306.288300 623.749329,305.911407 634.228210,306.215790 
	C639.504761,306.369049 644.813232,307.135529 650.004395,308.150696 
	C660.083801,310.121765 666.844421,316.229706 667.355225,326.588287 
	C668.008240,339.829376 667.271179,353.139038 667.078979,366.831665 
M663.866455,369.941040 
	C664.117737,369.509369 664.509888,369.105133 664.599976,368.642151 
	C667.590637,353.273895 667.911316,337.819336 664.439270,322.568756 
	C663.265991,317.415375 659.283875,313.218628 653.791626,311.468262 
	C636.722168,306.028137 619.137207,308.497101 601.736389,308.446045 
	C600.767700,308.443237 598.947021,311.164734 598.941223,312.623840 
	C598.793518,349.923157 598.868469,387.223297 598.793335,424.523071 
	C598.785645,428.332825 600.494873,429.475098 603.948975,429.422333 
	C610.940613,429.315491 617.939941,429.195007 624.925415,429.417328 
	C628.638550,429.535492 629.943176,428.237976 629.900085,424.539307 
	C629.772095,413.551819 629.893555,402.561127 629.946045,391.571838 
	C630.007141,378.801880 628.728088,380.412872 641.001831,380.148438 
	C649.633057,379.962463 658.009705,378.559723 663.866455,369.941040 
z"/>
<path fill="#1DA3DB" opacity="1.000000" stroke="none" 
	d="
M548.635376,304.646393 
	C543.483215,306.178833 537.810242,306.958191 532.561157,308.889679 
	C522.356689,312.644470 515.491028,320.336945 515.051819,331.101410 
	C514.102234,354.376678 514.165161,377.704041 514.268494,401.008057 
	C514.349121,419.198120 527.173462,430.248993 544.161499,431.637421 
	C558.985962,432.849030 572.906860,430.947113 581.344849,417.016052 
	C584.370911,412.020020 585.935852,405.446716 586.100708,399.535614 
	C586.667175,379.221313 586.602905,358.876373 586.235413,338.552917 
	C585.900574,320.033600 576.758789,309.786957 558.562927,306.312805 
	C556.926270,306.000336 555.298767,305.640167 553.345947,305.104523 
	C561.019165,302.198212 567.655151,306.017609 574.088135,309.703644 
	C584.298340,315.554047 587.962646,325.016296 588.053162,336.411255 
	C588.156189,349.376648 588.955322,362.341797 588.847168,375.302002 
	C588.755859,386.247345 588.467773,397.272308 587.057678,408.104614 
	C585.325256,421.412994 576.650635,428.759460 563.866943,432.240540 
	C549.970032,436.024750 537.048584,434.369507 524.190552,426.840881 
	C522.483337,425.268829 521.261108,424.122772 520.038940,422.976685 
	C514.063782,416.160950 511.893036,408.133362 511.950562,399.187775 
	C512.084229,378.410614 511.971924,357.631927 512.003540,336.853943 
	C512.007629,334.174805 511.692627,331.299164 512.521667,328.852051 
	C515.722351,319.404724 520.414185,310.925323 530.445923,307.107727 
	C536.162292,304.932404 541.982910,302.842010 548.635376,304.646393 
z"/>
<path fill="#CB404D" opacity="1.000000" stroke="none" 
	d="
M430.984985,497.197906 
	C430.984985,497.197906 430.986664,497.311066 431.076294,497.708801 
	C432.305023,500.653351 433.814301,503.103333 434.510956,505.765900 
	C436.304810,512.622253 435.043121,513.983093 428.048370,514.059326 
	C422.989868,514.114380 417.934479,514.455139 412.531128,514.529541 
	C411.456635,514.479004 410.728729,514.567444 409.774902,514.493225 
	C409.367615,514.369690 409.186218,514.408875 408.754059,514.307861 
	C408.326630,514.241455 408.149933,514.315308 407.555847,514.391479 
	C405.164764,518.429138 406.756958,522.817993 411.156494,525.469299 
	C415.865631,528.307068 421.014496,527.462341 424.145599,523.202026 
	C424.022614,523.086060 424.295624,523.282837 424.645813,523.211060 
	C426.786774,520.775208 428.655457,517.955139 432.032867,519.828918 
	C433.044952,520.390381 432.892273,523.051331 433.103821,524.825317 
	C432.739410,525.118347 432.724274,525.348450 432.613159,525.727173 
	C432.189911,526.231506 432.042542,526.599121 431.926117,526.964233 
	C431.957062,526.961731 431.912170,526.917969 431.578125,526.993774 
	C427.395477,529.046509 423.729706,532.103821 419.662964,532.792175 
	C408.665985,534.653625 402.326172,529.661743 397.893707,516.673218 
	C397.900116,516.024292 397.787842,515.650879 397.850952,514.899170 
	C397.536743,505.738312 400.151184,498.267883 407.391541,492.789581 
	C407.811707,492.861450 407.954071,492.708282 408.386108,492.454163 
	C417.137390,489.669464 424.584320,490.973846 430.984985,497.197906 
M408.327850,500.876312 
	C407.645355,502.168518 406.962860,503.460724 405.744843,505.082367 
	C404.699463,507.783264 405.541779,508.950867 409.332794,508.338226 
	C414.396057,508.345734 419.459320,508.353241 425.244415,508.656433 
	C428.832184,506.440277 428.825653,504.714172 424.726166,500.438904 
	C422.816101,499.295502 420.906036,498.152100 418.469208,496.582703 
	C416.988617,496.742493 415.508057,496.902283 413.393250,497.294556 
	C412.051331,498.127930 410.709412,498.961304 409.062347,499.801849 
	C408.849091,499.906921 408.761230,500.076782 408.327850,500.876312 
M430.398224,524.166992 
	C430.461426,523.517456 430.524658,522.867981 430.587860,522.218445 
	C429.458923,523.038635 428.329987,523.858765 427.201080,524.678894 
	C427.483643,525.027954 427.766235,525.377075 428.048828,525.726135 
	C428.689850,525.350220 429.330902,524.974365 430.398224,524.166992 
M424.185333,529.331360 
	C423.209320,529.036438 422.233307,528.741577 421.257294,528.446655 
	C421.204163,528.878418 421.151031,529.310242 421.097900,529.742004 
	C421.986725,529.742004 422.875519,529.742004 424.185333,529.331360 
z"/>
<path fill="#C9424F" opacity="1.000000" stroke="none" 
	d="
M512.860046,526.945435 
	C511.590271,527.944824 510.320496,528.944214 508.554077,530.260986 
	C499.664093,534.663940 491.729431,533.666016 484.177734,528.423645 
	C483.993713,527.980042 483.809723,527.536377 483.363281,526.727783 
	C482.550354,525.863037 481.999878,525.363281 481.449371,524.863586 
	C481.369415,524.419861 481.289459,523.976196 481.042053,522.916992 
	C480.202179,520.529419 479.529846,518.757202 478.961090,516.557800 
	C478.937073,514.088135 478.809448,512.045715 478.837219,509.626923 
	C479.844482,502.441071 482.946320,496.918884 488.546082,492.851593 
	C488.916687,492.922882 489.046448,492.784912 489.479553,492.545380 
	C499.520752,489.111328 507.597595,491.431763 514.261108,499.053345 
	C514.261108,499.053345 514.195679,499.140625 514.230835,499.582581 
	C518.233154,509.229828 517.824402,518.206604 512.860046,526.945435 
M488.466064,521.636963 
	C491.582520,526.424316 496.063995,528.073242 501.399536,526.538940 
	C506.733856,525.005005 508.808655,520.595825 509.195679,515.499207 
	C509.443970,512.229858 509.495667,508.766998 508.694794,505.631134 
	C506.541687,497.200470 496.210785,493.808502 490.451050,500.340607 
	C484.782867,506.768890 486.250519,513.774109 488.466064,521.636963 
z"/>
<path fill="#C74352" opacity="1.000000" stroke="none" 
	d="
M471.693695,528.915466 
	C471.111298,529.254883 470.528900,529.594299 469.543823,530.238403 
	C467.756531,531.344971 466.371918,532.146851 464.987305,532.948730 
	C464.533783,532.955444 464.080292,532.962158 462.880310,532.998108 
	C447.045685,534.388367 440.377960,523.790161 441.075073,512.302429 
	C441.360352,507.601593 442.852448,502.973969 443.795532,498.313049 
	C444.099274,498.329926 444.274902,498.188080 444.584106,497.597351 
	C447.241119,495.457550 449.636475,493.607941 452.031799,491.758331 
	C452.860321,491.629791 453.688812,491.501282 455.200256,491.260681 
	C463.553253,490.221832 469.794342,492.727112 474.507050,498.903046 
	C474.507050,498.903046 474.464508,499.009308 474.502136,499.440552 
	C473.838989,501.452972 473.138184,503.034149 472.437347,504.615326 
	C470.792511,503.857239 468.456482,503.542145 467.633209,502.257568 
	C465.219055,498.490631 462.189850,496.268921 457.752380,497.222900 
	C453.221924,498.196838 450.230499,501.117340 449.115662,506.213867 
	C449.049713,510.387299 449.169617,514.188660 449.202759,518.343994 
	C450.526154,523.398682 453.346039,526.530823 458.496704,526.927795 
	C462.540802,527.239441 466.197601,525.799805 467.413086,521.971680 
	C468.755310,517.744629 471.310913,517.089050 474.956787,517.449463 
	C474.973907,517.428955 474.935913,517.426086 474.950317,517.802856 
	C474.212341,521.387878 473.459991,524.596130 472.707642,527.804443 
	C472.707672,527.804443 472.856018,527.843384 472.567139,527.849243 
	C472.083435,528.208557 471.888550,528.562012 471.693695,528.915466 
z"/>
<path fill="#07AFF0" opacity="1.000000" stroke="none" 
	d="
M219.128372,333.067139 
	C219.527100,332.678925 219.903152,332.710175 220.559082,332.701721 
	C221.097580,333.281860 221.356186,333.901672 221.861862,335.230011 
	C222.139313,363.857178 222.157593,391.775879 222.228302,419.694458 
	C222.232834,421.485535 222.642792,423.275574 222.859344,425.377197 
	C222.862656,426.102966 222.870743,426.517639 222.719269,427.138916 
	C222.458679,427.847473 222.357651,428.349457 222.256638,428.851471 
	C220.798584,429.739441 218.535217,431.704254 218.026031,431.323456 
	C216.123413,429.900574 213.890991,427.887451 213.472717,425.762878 
	C212.905258,422.880676 213.871292,419.690125 214.219513,416.636383 
	C214.442474,414.681244 214.961411,412.734924 214.966080,410.783691 
	C215.019852,388.312592 214.995377,365.841248 215.010803,343.369995 
	C215.011993,341.627289 215.156937,339.884705 215.235077,338.142059 
	C215.793289,338.141693 216.351486,338.141296 216.909698,338.140900 
	C216.909698,364.044922 216.909698,389.948975 216.909698,415.852997 
	C217.515015,415.862457 218.120316,415.871918 218.725632,415.881378 
	C218.816620,414.190613 218.985458,412.499908 218.986816,410.809082 
	C219.004623,388.670715 218.993973,366.532379 219.003632,344.394012 
	C219.005219,340.758209 219.070068,337.122406 219.128372,333.067139 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M255.513596,400.958252 
	C255.522766,395.285431 255.531952,389.612640 255.828522,383.321106 
	C256.444092,381.833893 256.772278,380.965332 257.310516,380.012512 
	C257.792419,379.785522 258.021759,379.592285 258.657715,379.332397 
	C263.331360,379.317902 267.555817,379.319580 271.939392,379.557800 
	C272.933716,380.120331 273.768890,380.446320 274.604095,380.772308 
	C274.604095,382.262146 274.604095,383.751953 274.604095,385.263824 
	C279.955658,388.941162 279.583099,398.017365 273.946960,401.674133 
	C273.485504,401.973511 273.358887,402.788940 272.613129,403.824707 
	C269.361694,404.385315 266.569000,404.415344 263.787109,404.604218 
	C260.359192,404.836884 257.249664,404.496429 255.513596,400.958252 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M255.745819,352.980835 
	C255.675171,347.949890 255.604523,342.918945 255.768967,337.285217 
	C256.345978,335.800720 256.687866,334.918976 257.247040,333.940125 
	C257.740143,333.675568 257.969543,333.458954 258.607971,333.171783 
	C263.680206,333.145996 268.296936,333.141541 273.150330,333.323273 
	C273.936493,333.762238 274.486053,334.014984 275.035583,334.267761 
	C280.162842,338.109406 279.115997,343.689911 278.871674,348.880219 
	C278.793427,350.542603 277.276337,352.137238 275.840027,353.822876 
	C274.420013,354.101166 273.577515,354.319397 272.287476,354.555420 
	C266.475220,354.042419 261.110504,353.511627 255.745819,352.980835 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M449.301544,505.841766 
	C450.230499,501.117340 453.221924,498.196838 457.752380,497.222900 
	C462.189850,496.268921 465.219055,498.490631 467.633209,502.257568 
	C468.456482,503.542145 470.792511,503.857239 472.437347,504.615326 
	C473.138184,503.034149 473.838989,501.452972 474.546814,499.476318 
	C476.468903,497.776306 478.383972,496.471802 481.014587,495.005432 
	C483.962830,494.148529 486.195526,493.453461 488.428192,492.758423 
	C482.946320,496.918884 479.844482,502.441071 478.551971,509.496704 
	C477.487122,508.534210 476.862915,507.325531 475.886414,505.434753 
	C475.275391,507.781586 474.849945,509.415558 474.478912,510.840668 
	C472.854736,509.487396 471.317688,508.206696 469.780609,506.925995 
	C469.398834,507.341644 469.017029,507.757294 468.635223,508.172943 
	C470.736694,511.271942 472.838165,514.370972 474.939636,517.469971 
	C471.310913,517.089050 468.755310,517.744629 467.413086,521.971680 
	C466.197601,525.799805 462.540802,527.239441 458.496704,526.927795 
	C453.346039,526.530823 450.526154,523.398682 449.599609,518.340332 
	C452.720398,517.658386 455.357544,517.334045 458.173279,517.301270 
	C460.392853,518.785461 462.356384,520.191406 464.542328,520.984436 
	C464.962250,521.136719 466.255707,518.881287 466.289429,518.837891 
	C464.794556,516.063599 463.236420,514.434998 463.141266,512.725037 
	C462.958801,509.445007 461.475647,509.239075 458.663147,510.075317 
	C456.646698,511.106384 454.967163,512.063354 453.287598,513.020325 
	C454.326111,508.681030 453.931244,506.084412 449.301544,505.841766 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M129.423920,244.690796 
	C127.273743,240.287292 123.032745,243.411545 120.033585,242.295471 
	C119.319229,244.291748 117.984032,246.286942 117.980537,248.284454 
	C117.868073,312.595215 117.892601,376.906189 117.852280,441.217102 
	C117.850555,443.972076 119.033279,447.857086 113.361862,446.900085 
	C118.251144,437.528839 115.843231,427.449615 115.881439,417.766937 
	C116.099609,362.477570 115.998817,307.186859 115.940353,251.896667 
	C115.937569,249.265030 115.308182,246.634048 114.848724,243.052826 
	C117.269081,240.131332 121.647430,239.518173 126.422043,240.053986 
	C129.095901,240.354034 130.390244,241.594864 129.423920,244.690796 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M443.364380,498.241150 
	C442.852448,502.973969 441.360352,507.601593 441.075073,512.302429 
	C440.377960,523.790161 447.045685,534.388367 462.570770,533.149048 
	C461.583008,533.849304 460.169006,534.456177 458.730835,534.999084 
	C457.077850,535.623047 455.416016,536.229370 453.734650,536.770752 
	C452.188904,537.268433 450.612091,537.669678 449.048950,538.113403 
	C449.555237,536.678772 450.061523,535.244263 450.674438,533.507629 
	C445.878540,534.699463 443.927582,531.333069 442.029449,526.957764 
	C441.197052,523.745911 440.734039,521.156128 439.792877,518.753662 
	C439.287476,517.463501 437.960876,516.494995 437.004822,515.381348 
	C436.827209,516.703125 436.284180,518.102295 436.544556,519.331299 
	C437.177216,522.317444 437.240662,524.666565 433.273224,524.750427 
	C432.892273,523.051331 433.044952,520.390381 432.032867,519.828918 
	C428.655457,517.955139 426.786774,520.775208 424.565002,522.825562 
	C423.667847,521.433228 423.201721,520.354675 422.735596,519.276062 
	C422.161194,519.732910 421.586761,520.189758 421.012329,520.646606 
	C422.097748,521.537109 423.183167,522.427551 424.268585,523.318054 
	C421.014496,527.462341 415.865631,528.307068 411.156494,525.469299 
	C406.756958,522.817993 405.164764,518.429138 407.610565,514.807007 
	C408.901489,518.205261 409.205139,521.751770 414.381989,520.560669 
	C413.864899,518.535156 413.371307,516.601807 412.877686,514.668457 
	C417.934479,514.455139 422.989868,514.114380 428.048370,514.059326 
	C435.043121,513.983093 436.304810,512.622253 434.510956,505.765900 
	C433.814301,503.103333 432.305023,500.653351 431.130981,497.723267 
	C434.843109,500.452484 439.100006,496.286804 443.364380,498.241150 
M427.899658,516.143982 
	C424.817322,516.143982 421.735016,516.143982 418.652679,516.143982 
	C418.659698,516.459290 418.666748,516.774597 418.673798,517.089905 
	C421.878418,516.919861 425.083038,516.749817 427.899658,516.143982 
z"/>
<path fill="#08B0EF" opacity="1.000000" stroke="none" 
	d="
M200.273254,417.026337 
	C199.875626,390.992065 199.848068,364.997894 199.865616,339.003754 
	C199.866608,337.533386 200.237808,336.063293 200.737427,334.587769 
	C201.305832,335.102051 201.573502,335.621704 201.841156,336.141327 
	C202.228989,335.413483 202.616821,334.685638 203.004669,333.957794 
	C203.445267,333.940155 203.885849,333.922485 204.656891,333.913391 
	C204.987350,363.221252 204.987350,392.520569 204.987350,422.725555 
	C203.096848,420.257172 201.874954,418.661804 200.273254,417.026337 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M222.878845,426.932343 
	C222.870743,426.517639 222.862656,426.102966 222.952408,424.911072 
	C223.093689,396.702240 223.166107,369.270630 223.164886,341.839020 
	C223.164444,332.201721 222.983261,322.564423 222.884613,312.927124 
	C222.174896,307.978821 224.057327,306.367035 229.235550,306.507843 
	C244.212555,306.915039 259.206299,306.705627 274.840942,306.839294 
	C275.873413,307.037720 276.258362,307.135101 276.643341,307.232483 
	C276.867584,308.042511 277.091827,308.852570 277.117065,309.835327 
	C274.658783,309.580780 272.402710,308.804199 270.139801,308.783752 
	C256.989197,308.664978 243.835510,308.833832 230.686157,308.658295 
	C226.551361,308.603119 224.673203,309.717560 224.689224,314.247650 
	C224.818695,350.871918 224.755447,387.496826 224.816635,424.121429 
	C224.819305,425.719452 225.460495,427.316467 225.916473,429.300018 
	C225.137726,429.541321 224.246780,429.396545 223.275177,428.684265 
	C223.089310,427.721985 222.984085,427.327148 222.878845,426.932343 
z"/>
<path fill="#04AFF1" opacity="1.000000" stroke="none" 
	d="
M207.003189,334.365295 
	C207.447388,333.907135 207.890488,333.901184 208.665558,333.912537 
	C209.125076,336.404572 208.083847,339.401001 212.149490,340.016632 
	C212.602859,340.085297 212.959702,342.354431 212.962387,343.603943 
	C213.010208,365.882874 212.939224,388.162231 213.050903,410.440704 
	C213.069885,414.227234 211.404663,415.492676 207.002106,415.296844 
	C207.002106,388.444702 207.002106,361.631073 207.003189,334.365295 
z"/>
<path fill="#5386B1" opacity="1.000000" stroke="none" 
	d="
M302.990234,485.066986 
	C299.886108,485.130951 297.287689,484.467499 296.809570,480.019775 
	C297.676422,475.479279 299.601562,474.187927 302.740417,476.771790 
	C301.308228,478.260956 300.238220,479.578064 299.168213,480.895142 
	C300.473724,481.621277 301.721710,482.801880 303.097137,482.976135 
	C305.716400,483.307953 308.439392,483.321930 311.063751,483.005981 
	C316.125580,482.396576 317.282318,484.728516 317.173401,489.362640 
	C316.899719,501.005310 316.984955,512.659058 317.108429,524.306519 
	C317.136902,526.991272 315.693481,531.217285 320.381744,531.232361 
	C325.003906,531.247192 323.147858,527.083374 323.201447,524.675964 
	C323.408722,515.361267 323.313904,506.039429 323.302490,496.720276 
	C323.286194,483.430450 323.278503,483.508575 336.453705,482.962616 
	C338.107300,482.894104 339.705231,481.481873 341.328979,480.692200 
	C340.185028,479.339844 339.041077,477.987488 338.024841,476.342499 
	C339.371399,475.851013 340.590271,475.652130 341.809113,475.453247 
	C342.264282,475.929840 342.719482,476.406372 343.431671,477.429382 
	C343.717407,479.630493 343.746155,481.285095 343.774841,482.939728 
	C343.225891,483.395599 342.676910,483.851501 341.623657,484.555603 
	C340.410828,484.881866 339.702301,484.959961 338.993744,485.038025 
	C338.547852,485.013733 338.101990,484.989410 336.868134,484.959900 
	C334.928070,484.929138 333.776093,484.888153 332.623871,484.880615 
	C325.120178,484.831390 325.025391,484.834503 325.084961,492.131073 
	C325.146729,499.695526 325.417450,507.258331 325.596802,514.821838 
	C325.563477,519.313904 325.530182,523.805969 325.613098,528.972107 
	C325.697174,530.038452 325.665009,530.430725 325.632843,530.822998 
	C324.831421,531.642700 324.029999,532.462341 322.669098,533.511780 
	C321.089172,533.774109 320.068726,533.806641 318.714661,533.779602 
	C317.326904,533.348755 316.272705,532.977539 315.218506,532.606384 
	C315.218506,532.606384 315.252106,532.284851 315.187469,531.796021 
	C315.013733,530.872925 314.904633,530.438721 314.823792,529.651855 
	C314.937866,520.836243 315.181519,512.370117 315.011963,503.912323 
	C314.961945,501.418335 313.743622,498.947815 313.230469,496.253265 
	C313.288116,495.539154 313.175995,495.038269 313.387939,494.311005 
	C314.796265,490.999207 316.702545,487.849060 312.670319,485.097595 
	C311.880707,485.075439 311.428467,485.095673 310.653442,485.053314 
	C309.894775,485.019684 309.458893,485.048615 308.594849,485.017975 
	C306.441193,484.994598 304.715698,485.030792 302.990234,485.066986 
z"/>
<path fill="#D93A44" opacity="1.000000" stroke="none" 
	d="
M382.843506,530.015076 
	C382.837006,523.651184 382.830505,517.287292 382.821869,510.139709 
	C382.734222,508.896881 382.648712,508.437714 382.563232,507.978577 
	C382.658905,498.629364 382.754578,489.280121 383.146484,479.304810 
	C383.971069,478.014618 384.499481,477.350525 385.400574,476.720032 
	C386.521332,476.768402 387.269409,476.783173 388.163666,477.204285 
	C388.602081,479.389221 389.139160,481.166626 389.147888,482.946625 
	C389.218842,497.384125 389.219543,511.822205 389.177765,526.259888 
	C389.162354,531.594482 387.982910,532.245667 382.843506,530.015076 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M341.848389,475.032776 
	C340.590271,475.652130 339.371399,475.851013 337.565002,476.389313 
	C325.685822,476.685730 314.394196,476.642700 303.102600,476.599670 
	C299.601562,474.187927 297.676422,475.479279 296.721436,479.645355 
	C294.380554,479.495697 292.135193,478.975311 289.926300,478.463409 
	C289.330231,472.507935 290.407288,471.108337 296.042389,471.028870 
	C310.348358,470.827057 324.656464,470.650269 338.961273,470.765289 
	C339.946991,470.773224 340.912689,473.271942 341.848389,475.032776 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M513.243164,526.944153 
	C517.824402,518.206604 518.233154,509.229828 514.259705,499.628845 
	C515.299377,498.663849 517.316284,497.904449 517.247559,497.553284 
	C515.951538,490.932159 520.674683,491.555420 524.995911,491.255737 
	C525.018250,494.201141 524.412537,497.111847 524.374023,500.030060 
	C524.256714,508.937653 524.526672,517.852417 524.301392,526.755676 
	C524.191406,531.104309 525.808289,532.933044 530.469604,532.479004 
	C528.563965,537.574829 525.172607,534.670044 523.592285,532.656128 
	C520.967224,529.310791 519.450500,525.095703 517.466980,521.247009 
	C517.982300,521.161011 518.497620,521.075012 519.012939,520.989014 
	C517.217346,522.973633 515.421814,524.958252 513.243164,526.944153 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M374.324738,523.917236 
	C373.110870,522.476440 372.068176,519.608582 371.352356,519.687988 
	C369.370575,519.907776 366.889008,520.883301 365.751038,522.412781 
	C362.906616,526.235962 359.340698,527.568054 354.925934,526.866882 
	C350.386078,526.145874 347.659149,523.068237 346.228210,518.922424 
	C345.199890,515.943176 345.436066,513.859985 349.729187,514.052063 
	C356.667755,514.362366 363.634521,513.977539 370.585510,514.102173 
	C373.808105,514.159912 375.207703,512.866455 375.153809,509.358643 
	C376.341187,510.549835 377.403412,512.102905 378.585175,513.830811 
	C376.987000,514.867554 375.715546,515.692261 373.837006,516.910828 
	C375.634979,518.169556 376.971954,519.105530 378.828339,520.405090 
	C377.293579,521.663452 375.969330,522.749207 374.324738,523.917236 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M89.910782,446.462250 
	C82.365479,447.916748 82.569069,447.919128 82.497009,454.052856 
	C82.456009,457.543732 84.084831,458.344421 87.047028,458.329498 
	C95.544868,458.286621 104.048271,458.503937 112.539055,458.258362 
	C116.779182,458.135712 117.990662,459.768982 117.957626,463.821930 
	C117.798729,483.317535 117.846390,502.815125 117.898331,522.311768 
	C117.903526,524.261780 118.432602,526.210327 118.868927,528.548035 
	C115.691841,529.848755 115.004883,527.559509 115.060928,525.222351 
	C115.175354,520.451172 115.903671,515.690857 115.942963,510.922455 
	C116.066360,495.943359 115.876762,480.961304 116.056442,465.983368 
	C116.108543,461.639862 114.423447,459.919006 110.214836,460.006927 
	C102.065651,460.177216 93.909996,460.089325 85.764000,460.337433 
	C82.020515,460.451447 80.001305,459.014709 80.059418,455.268280 
	C80.113625,451.774323 78.900635,447.901428 83.092033,445.471893 
	C83.909752,444.997925 83.743523,442.826385 84.031120,441.437836 
	C84.562691,440.967621 85.094261,440.497375 85.625832,440.027161 
	C87.013763,442.067963 88.401695,444.108765 89.910782,446.462250 
z"/>
<path fill="#04AFF1" opacity="1.000000" stroke="none" 
	d="
M164.134750,340.110596 
	C164.153717,364.399689 164.172684,388.688782 164.167313,413.431488 
	C163.421921,415.462799 162.700882,417.040466 161.595764,419.458527 
	C160.897629,417.859955 160.258453,417.082947 160.264740,416.311188 
	C160.465698,391.672394 160.788727,367.034393 160.893478,342.395447 
	C160.904266,339.853851 159.669434,337.306915 159.006378,334.352600 
	C160.066071,333.940094 161.131958,333.937500 162.598389,334.361145 
	C163.377548,336.561798 163.756149,338.336212 164.134750,340.110596 
z"/>
<path fill="#9D5971" opacity="1.000000" stroke="none" 
	d="
M642.106812,554.025574 
	C641.826782,554.201416 641.638000,554.447388 641.260376,555.171753 
	C640.578003,556.324585 640.175659,557.069031 639.773376,557.813538 
	C639.773376,557.813538 639.774475,557.939331 639.485352,558.042114 
	C637.680908,561.382812 636.165527,564.620728 634.038635,569.165405 
	C637.500305,568.250977 639.169067,567.810181 640.837769,567.369385 
	C640.779785,567.707581 640.721741,568.045776 640.433228,568.773315 
	C639.938721,569.684387 639.674622,570.206177 639.410522,570.727905 
	C639.117065,571.062927 638.823547,571.397888 638.335754,572.010498 
	C638.141418,572.288025 637.976318,572.606079 637.976318,572.606079 
	C637.416016,572.840942 636.855713,573.075806 635.701965,573.573303 
	C633.463440,572.699890 631.818359,571.563782 629.833435,570.192993 
	C629.271118,570.876404 628.471008,571.848816 627.670898,572.821167 
	C627.299072,572.924622 626.927246,573.028137 625.968262,573.111755 
	C624.697998,572.837524 624.014893,572.583191 623.331787,572.328857 
	C622.243530,571.928955 621.155334,571.529053 619.461792,570.973999 
	C618.228088,570.015625 617.599731,569.212402 617.279419,568.373047 
	C618.171082,568.177246 618.754639,568.017517 619.674927,567.733765 
	C622.358948,565.084229 624.706299,562.558716 627.053589,560.033142 
	C627.369812,559.978882 627.638000,559.835083 628.351501,559.337341 
	C632.448120,558.224609 636.494690,557.996521 637.398315,552.874695 
	C637.625732,552.455444 637.658508,552.260376 637.969727,551.975220 
	C638.711548,551.038635 639.174866,550.192078 639.638184,549.345520 
	C640.052368,549.106628 640.466553,548.867798 641.279785,548.458374 
	C642.078064,548.253967 642.477295,548.219971 642.876465,548.185974 
	C642.876465,548.185974 642.901550,548.113586 642.888916,548.496094 
	C642.619812,550.594238 642.363342,552.309937 642.106812,554.025574 
M626.800354,565.366638 
	C627.318970,566.122559 627.837585,566.878418 628.356262,567.634277 
	C629.911377,566.293823 631.466553,564.953430 633.021667,563.612976 
	C632.675232,563.152771 632.328735,562.692505 631.982300,562.232300 
	C630.425964,563.116150 628.869629,564.000061 626.800354,565.366638 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M555.947693,512.828857 
	C555.130615,507.884857 554.911621,502.763947 559.921631,499.265564 
	C564.696655,495.931274 568.158813,496.867065 570.641785,502.561829 
	C568.112976,502.406097 566.012817,501.938507 564.711121,501.648682 
	C566.059265,505.420654 568.035339,508.496429 568.093628,511.608124 
	C568.226929,518.729431 567.423218,525.868286 567.000000,533.000000 
	C562.918945,533.051453 560.950684,531.345520 560.913086,526.957214 
	C560.871460,522.117859 560.069153,517.287659 559.745483,512.443604 
	C559.716919,512.016052 560.777527,511.515717 561.895996,511.368164 
	C562.783203,517.093018 563.106628,522.498962 563.430115,527.904846 
	C563.913635,527.888123 564.397217,527.871399 564.880737,527.854614 
	C564.880737,521.628540 564.979187,515.398865 564.753601,509.180969 
	C564.726013,508.420624 562.960266,507.723328 562.000000,506.996826 
	C557.568787,506.249603 557.192322,509.713074 555.947693,512.828857 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M579.363953,509.013031 
	C579.039124,505.324768 578.714233,501.636505 578.376831,497.578674 
	C580.392639,497.581879 582.420959,497.954620 584.995789,498.427826 
	C584.995789,503.147522 584.995789,508.106171 584.995483,514.000000 
	C584.996765,520.290100 584.998413,525.645081 585.000000,531.000000 
	C584.545166,532.935486 584.090332,534.870911 583.300415,536.545776 
	C582.263245,535.390625 581.561096,534.496094 580.858887,533.601624 
	C580.261475,534.439514 579.458313,535.204285 579.126892,536.136719 
	C578.820862,536.997620 579.020569,538.038452 578.997559,539.000000 
	C578.149841,544.114563 575.258179,538.533691 573.357544,540.004761 
	C573.000000,540.000000 573.002441,540.000000 573.002441,540.000000 
	C572.485657,539.136108 571.968933,538.272278 571.225830,536.829468 
	C571.103088,534.454285 571.206665,532.657959 571.310181,530.861633 
	C572.375732,531.424866 573.441223,531.988037 574.761841,533.082275 
	C575.286743,534.457092 575.556580,535.300781 575.826477,536.144470 
	C576.088989,536.094177 576.351440,536.043945 576.613953,535.993652 
	C576.746338,534.952087 576.878723,533.910522 577.011108,532.868958 
	C577.685791,530.646057 578.740967,528.458069 578.949097,526.192322 
	C579.292175,522.457031 579.087463,518.671509 579.553589,514.643921 
	C580.538025,518.885254 581.082397,523.389893 581.626770,527.894470 
	C582.029175,527.882141 582.431519,527.869812 582.833923,527.857483 
	C582.833923,522.239136 582.957886,516.614624 582.680298,511.010040 
	C582.644714,510.291382 580.520630,509.676178 579.363953,509.013031 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M326.010986,514.847168 
	C325.417450,507.258331 325.146729,499.695526 325.084961,492.131073 
	C325.025391,484.834503 325.120178,484.831390 332.623871,484.880615 
	C333.776093,484.888153 334.928070,484.929138 336.535583,485.004333 
	C335.633087,487.067413 334.154724,489.012360 332.958160,491.117462 
	C332.109955,492.609711 331.194519,494.290558 331.092194,495.935913 
	C330.824554,500.241333 331.097321,504.577545 330.938202,508.893494 
	C330.830597,511.812408 330.291168,514.668457 326.010986,514.847168 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M390.010559,331.018280 
	C390.012909,326.867615 390.012909,323.212280 390.012909,319.493500 
	C390.008698,319.497070 390.079498,319.437012 390.150330,319.376953 
	C390.150330,350.450287 390.150330,381.523621 390.150330,412.596985 
	C390.102936,412.597046 390.055573,412.597076 390.008179,412.597137 
	C390.008179,385.569305 390.008179,358.541443 390.010559,331.018280 
z"/>
<path fill="#6380A4" opacity="1.000000" stroke="none" 
	d="
M332.025055,557.909912 
	C332.325867,558.400574 332.626678,558.891235 332.995087,560.028503 
	C333.058441,561.476318 333.054199,562.277588 333.035889,563.046997 
	C333.021820,563.015198 333.088440,563.035217 332.843872,563.047974 
	C332.264893,563.333801 331.930481,563.606812 331.596069,563.879883 
	C332.145325,564.060181 332.694550,564.240479 333.216949,564.778076 
	C333.085938,567.136597 332.981750,569.137817 332.877563,571.138977 
	C331.702057,571.583191 330.522949,572.018188 329.352051,572.474243 
	C328.315430,572.877991 326.702911,573.947632 326.346619,573.623230 
	C322.635559,570.243408 317.963928,573.601807 313.737091,571.194214 
	C313.256439,569.445435 313.164856,568.197876 313.430969,566.984741 
	C314.743439,566.968506 315.698212,566.917908 316.700745,567.249451 
	C317.743317,568.670959 318.738129,569.710327 319.732941,570.749695 
	C320.660431,569.310303 321.587921,567.870972 322.515442,566.431580 
	C320.382568,565.302246 325.443237,561.049683 320.230957,560.986816 
	C320.179626,560.558838 320.160278,560.387512 320.279022,559.890930 
	C321.689178,559.173462 322.954681,558.758179 324.234985,558.394958 
	C325.810486,557.947937 327.398315,557.544250 329.250854,557.289551 
	C330.355621,557.608154 331.190338,557.759033 332.025055,557.909912 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M585.361145,531.019653 
	C584.998413,525.645081 584.996765,520.290100 584.995117,514.467590 
	C585.831787,513.803528 586.668457,513.607056 588.128174,513.201477 
	C589.499146,512.990784 590.247131,512.989258 590.995117,512.987793 
	C591.996765,515.634705 593.220886,518.223328 593.935120,520.945679 
	C594.598694,523.474670 594.877686,526.137634 595.019226,528.758240 
	C595.106934,530.381775 594.622314,532.036255 594.394592,533.676819 
	C592.667480,533.362183 590.904480,533.172729 589.225220,532.691284 
	C588.000366,532.340027 586.885925,531.603882 585.361145,531.019653 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M484.183624,528.811646 
	C491.729431,533.666016 499.664093,534.663940 508.448578,530.549500 
	C509.551239,535.525208 507.448120,538.293518 502.410675,539.151306 
	C501.006470,539.390503 499.776520,540.653259 499.584839,540.769348 
	C500.773499,544.499084 501.674133,547.325134 502.574768,550.151123 
	C500.328583,550.768372 498.082336,551.385620 495.061371,552.002136 
	C493.832916,552.022339 493.379181,552.043274 492.964539,552.037537 
	C493.003632,552.010803 492.933075,552.075989 492.969910,551.730225 
	C493.010742,550.921021 493.014740,550.457642 493.018738,549.994202 
	C493.958099,547.856995 494.872406,545.708252 495.843079,543.585388 
	C497.092407,540.852844 498.386810,538.140930 499.745605,535.241333 
	C495.168274,534.112549 487.890747,536.115540 484.183624,528.811646 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M542.956543,572.043884 
	C541.427612,572.199158 539.898682,572.354431 537.936340,572.856567 
	C537.262146,573.444458 537.021362,573.685425 536.780518,573.926392 
	C535.429321,573.392395 534.078125,572.858398 532.319580,571.847595 
	C531.209412,571.546509 530.506714,571.722229 529.803955,571.897949 
	C529.516052,571.997803 529.289307,572.175598 528.791870,572.683960 
	C528.460144,572.936646 528.029419,573.174255 528.029419,573.174255 
	C527.416931,572.979614 526.804382,572.785034 525.656128,572.461670 
	C524.586182,572.490173 524.052002,572.647339 523.517883,572.804565 
	C522.909790,572.906067 522.301636,573.007568 521.360352,572.774048 
	C520.846619,570.956177 520.666016,569.473328 520.861694,568.047485 
	C522.868591,567.333130 524.499268,566.561768 526.513550,565.789307 
	C529.421326,563.842896 531.945557,561.897583 534.616028,560.353149 
	C534.842163,561.026917 534.922119,561.299866 534.978638,561.877808 
	C534.479370,563.215942 534.003479,564.249146 533.132690,566.139648 
	C535.542419,565.610413 537.016357,565.286682 538.755371,565.158203 
	C540.227417,565.138794 541.434387,564.924194 542.689331,565.084717 
	C542.810425,567.654541 542.883484,569.849243 542.956543,572.043884 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M397.774994,516.948792 
	C402.326172,529.661743 408.665985,534.653625 419.662964,532.792175 
	C423.729706,532.103821 427.395477,529.046509 431.593811,527.349915 
	C430.959778,529.419373 429.976074,531.208435 428.992371,532.997559 
	C423.131439,533.448914 417.259186,533.797791 411.419403,534.441040 
	C410.294952,534.564880 409.297699,535.843262 410.621582,534.166138 
	C406.059357,531.854919 401.497162,529.543701 396.934937,527.232483 
	C397.380341,526.720764 397.825745,526.209106 398.271179,525.697449 
	C396.144470,527.394409 394.017792,529.091370 391.525208,530.506714 
	C391.138397,529.150146 391.117523,528.075256 391.438782,526.837280 
	C392.444611,523.397827 393.108398,520.121582 393.807983,516.668518 
	C395.170563,516.764771 396.472778,516.856750 397.774994,516.948792 
z"/>
<path fill="#935E7C" opacity="1.000000" stroke="none" 
	d="
M403.059570,556.864502 
	C403.490967,556.909485 403.922363,556.954468 404.785522,557.286743 
	C406.082397,558.450745 406.947479,559.327576 407.812561,560.204407 
	C407.973602,560.486633 408.200531,560.698059 408.682373,561.308044 
	C408.426361,564.739563 407.981354,567.701782 407.536346,570.663940 
	C406.818512,571.314392 406.100677,571.964844 404.915222,572.937134 
	C403.971863,573.645630 403.496124,574.032227 403.001129,573.999634 
	C402.769287,572.143921 402.556671,570.707397 402.158844,568.019287 
	C400.145447,570.181702 399.093872,571.311096 397.721863,572.530212 
	C396.285950,572.026672 395.170441,571.433411 393.974060,570.490479 
	C393.509399,569.009033 393.125610,567.877197 392.855469,566.453735 
	C393.720337,564.789673 394.471558,563.417236 395.222748,562.044800 
	C396.690674,560.901306 398.158630,559.757874 400.056885,558.325317 
	C400.782440,557.921936 401.017059,557.732483 401.521667,557.434631 
	C402.254730,557.222412 402.657135,557.043457 403.059570,556.864502 
M397.073792,567.087463 
	C400.675171,569.133484 403.863068,566.663208 404.270416,559.927673 
	C401.342072,562.579224 399.223358,564.497681 397.073792,567.087463 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M546.986389,506.022980 
	C547.598083,513.081238 547.734253,520.289734 548.241150,527.471985 
	C548.358459,529.134644 549.741943,530.707886 550.542725,532.322266 
	C552.192932,531.410645 553.843140,530.499023 555.659607,529.294617 
	C559.295654,532.602722 554.038086,535.385986 554.683167,538.866638 
	C554.749817,539.226135 550.914978,540.308655 548.890503,541.055664 
	C548.664795,540.581543 548.439087,540.107422 548.213379,539.633362 
	C549.046753,538.170776 549.880188,536.708130 550.869385,534.972107 
	C546.059265,533.022217 545.916931,537.743164 542.986633,539.311340 
	C546.294861,529.078491 548.148315,518.909180 543.624390,507.901245 
	C544.298645,507.513458 545.472229,506.838531 546.986389,506.022980 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M255.482330,353.047516 
	C261.110504,353.511627 266.475220,354.042419 272.646149,354.671265 
	C274.185638,354.923126 274.918915,355.076965 275.652161,355.230804 
	C275.652161,355.230804 275.925354,355.530457 276.014282,356.172241 
	C276.183044,363.616119 276.262848,370.418182 276.342682,377.220276 
	C276.042267,377.697021 275.741852,378.173767 274.827515,378.830688 
	C273.402466,379.114319 272.591370,379.217804 271.780273,379.321259 
	C267.555817,379.319580 263.331360,379.317902 258.416382,379.357239 
	C257.398682,379.524811 257.190216,379.757660 257.100494,380.096802 
	C256.772278,380.965332 256.444092,381.833893 255.816147,382.878113 
	C253.994171,379.289459 255.171143,377.456024 259.322296,377.534454 
	C263.134125,377.606476 267.003448,377.887512 270.735107,377.327667 
	C272.203186,377.107452 274.317200,374.967773 274.489044,373.508148 
	C274.989532,369.256744 274.568756,364.905060 274.681030,360.597168 
	C274.766968,357.300140 273.088348,356.203827 270.083618,356.248566 
	C266.270874,356.305328 262.456482,356.267242 258.642944,356.249817 
	C256.571594,356.240356 254.440369,356.254456 255.482330,353.047516 
z"/>
<path fill="#A2546A" opacity="1.000000" stroke="none" 
	d="
M428.519104,581.843994 
	C428.241699,581.813599 428.035126,581.920410 427.559570,582.370728 
	C426.235138,582.338928 425.250580,582.100769 424.265991,581.862610 
	C424.445282,580.746643 424.624573,579.630737 425.049805,577.984497 
	C426.077667,576.343750 426.859589,575.233276 427.897461,573.976624 
	C428.121552,573.013550 428.089691,572.196594 428.057861,571.379639 
	C427.347290,571.841919 426.636719,572.304138 425.567261,572.865479 
	C424.467743,573.021545 423.727112,573.078430 422.986481,573.135376 
	C422.528473,572.243103 422.070465,571.350891 421.500793,569.844971 
	C421.344543,568.139832 421.299957,567.048279 421.255341,565.956787 
	C421.359680,565.646423 421.464050,565.335999 421.735107,564.743042 
	C422.154572,563.998840 422.407288,563.537231 422.660034,563.075562 
	C423.376221,562.510010 424.092438,561.944397 425.173248,561.010620 
	C426.111816,559.827148 426.685791,559.011902 427.259766,558.196655 
	C427.259796,558.196716 427.177795,558.199463 427.548035,558.263550 
	C428.881348,558.407959 429.844391,558.488220 430.807434,558.568542 
	C430.807434,558.568542 430.808380,558.854858 430.883209,559.285278 
	C430.434082,560.770752 429.910095,561.825684 429.094391,562.993713 
	C427.684113,564.685791 426.565521,566.264832 425.446960,567.843872 
	C426.579224,568.211609 427.711517,568.579407 429.136780,569.005615 
	C429.941223,569.352417 430.452698,569.640747 430.973297,570.251953 
	C431.048096,572.450806 431.113770,574.326660 431.154480,576.503540 
	C431.110870,577.204834 431.092163,577.605164 431.073456,578.005493 
	C430.763672,578.130310 430.565491,578.353943 430.205750,579.057129 
	C429.461487,580.239807 428.990295,581.041931 428.519104,581.843994 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M407.252716,492.694153 
	C400.151184,498.267883 397.536743,505.738312 397.513641,514.686401 
	C394.628906,513.184143 392.213043,511.541962 393.013428,508.022003 
	C396.130341,506.622406 399.563873,499.419556 399.335297,495.280609 
	C399.147675,491.883240 399.297028,488.467285 399.297028,485.967804 
	C401.437317,485.511047 402.960632,485.230316 404.459717,484.853271 
	C405.900085,484.491028 407.315338,484.028961 408.741638,483.610748 
	C409.199554,485.178619 409.657471,486.746460 410.057678,488.657166 
	C410.000000,489.000000 409.998779,489.001221 409.589966,488.998352 
	C405.928040,488.941956 402.729401,488.979797 401.540619,493.714081 
	C402.875854,493.199036 403.590302,492.772827 404.348877,492.668854 
	C405.297852,492.538788 406.283112,492.673676 407.252716,492.694153 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M551.741699,492.971619 
	C542.884766,489.973358 541.460632,490.044525 533.078003,494.031677 
	C530.427734,493.091919 528.213684,492.306885 525.640808,491.453583 
	C524.475647,489.633850 523.669373,487.882355 522.863098,486.130890 
	C522.987061,485.779938 523.111023,485.429016 523.234985,485.078064 
	C524.462463,485.777466 525.995605,486.217468 526.854553,487.229614 
	C528.714172,489.420746 530.120667,489.531372 532.075989,487.324768 
	C533.339417,485.898956 535.161133,484.979706 536.673401,483.759949 
	C539.457397,481.514404 540.873718,482.353790 542.128357,485.536469 
	C542.720581,487.038727 544.572205,488.144623 546.030884,489.179688 
	C547.830200,490.456543 549.814514,491.472656 551.741699,492.971619 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M379.000671,550.992920 
	C379.640198,552.701599 380.279724,554.410217 380.570801,556.586670 
	C378.251495,558.444153 376.293945,559.836548 379.158447,562.569275 
	C377.429749,564.968933 375.700989,567.021057 373.630005,568.952271 
	C373.319000,567.421936 373.052673,565.919434 373.457092,564.626831 
	C373.956390,563.030945 375.013306,561.609497 375.829285,560.112610 
	C375.120483,559.658325 374.411652,559.204041 373.702850,558.749756 
	C371.748810,561.456177 369.794769,564.162598 367.513916,566.959717 
	C366.054474,567.834656 364.921875,568.618835 363.514465,569.325806 
	C362.886902,569.106201 362.534180,568.963806 362.144470,568.583801 
	C362.107513,568.346191 361.851837,567.938904 361.977051,567.618896 
	C362.543182,565.988770 362.984070,564.678650 363.672180,563.214722 
	C364.285889,562.396851 364.652435,561.732727 365.038330,561.065613 
	C365.057648,561.062622 365.067139,561.024597 365.310913,560.981689 
	C365.880859,560.821106 366.128876,560.611145 366.592957,560.240601 
	C367.548706,559.741943 368.210205,559.311462 369.089294,558.799316 
	C369.687988,558.459961 370.069061,558.202332 370.852234,557.927124 
	C372.167755,557.145142 373.105438,556.407593 373.989349,555.610413 
	C375.675995,554.089233 377.331970,552.533997 379.000671,550.992920 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M586.004761,544.997559 
	C586.972412,542.786194 587.940063,540.574890 588.907715,538.363525 
	C589.368896,538.388000 589.830139,538.412476 590.291382,538.436890 
	C590.426636,540.163147 591.317200,542.381287 590.567993,543.531494 
	C587.109131,548.842285 590.700378,552.165771 593.740417,556.289429 
	C594.006165,557.460266 593.977051,558.164734 593.633301,558.949951 
	C592.533875,559.360291 591.749084,559.690002 590.723755,560.032959 
	C590.151978,560.187988 589.976196,560.438660 589.782227,560.994995 
	C589.440308,561.494019 589.272095,561.796570 588.842773,561.893677 
	C587.078735,560.607727 585.575806,559.527283 584.072876,558.446838 
	C583.104553,556.405212 581.657104,554.440918 581.311401,552.298828 
	C581.005371,550.402222 581.925110,548.307861 582.649963,546.544922 
	C583.698730,548.675476 584.401245,550.561890 585.103760,552.448303 
	C585.762939,552.266296 586.422180,552.084351 587.081421,551.902344 
	C586.722534,549.600769 586.363647,547.299133 586.004761,544.997559 
z"/>
<path fill="#5F7FA9" opacity="1.000000" stroke="none" 
	d="
M391.096680,527.000366 
	C391.117523,528.075256 391.138397,529.150146 391.222443,530.625366 
	C390.698975,531.642090 390.112335,532.258423 388.838928,533.012878 
	C386.440308,533.085327 384.728485,533.019653 383.016663,532.953979 
	C382.830383,532.095459 382.644104,531.236877 382.650696,530.196716 
	C387.982910,532.245667 389.162354,531.594482 389.177765,526.259888 
	C389.219543,511.822205 389.218842,497.384125 389.147888,482.946625 
	C389.139160,481.166626 388.602081,479.389221 388.256775,476.960938 
	C388.479218,476.097931 388.754730,475.884583 389.030243,475.671234 
	C389.482391,476.040710 389.934570,476.410187 390.680664,477.370087 
	C391.039856,486.308655 391.105072,494.656769 391.170288,503.004883 
	C391.146820,503.447693 391.123352,503.890472 391.079559,505.127441 
	C391.071716,512.947876 391.084198,519.974121 391.096680,527.000366 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M343.165039,529.112183 
	C351.164093,533.815002 359.418945,534.702637 368.422974,530.365356 
	C366.056763,536.090088 362.260590,540.073547 355.046387,539.061951 
	C352.151154,538.655945 349.151489,538.995117 345.936462,538.059509 
	C347.154938,537.355408 348.373444,536.651245 349.681183,535.895569 
	C347.376007,533.646973 345.253296,531.576416 343.165039,529.112183 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M554.927246,559.024048 
	C556.836731,559.913208 559.832642,560.334534 560.454346,561.778320 
	C562.411072,566.321838 565.394653,571.270874 560.062378,576.284363 
	C558.785095,575.686890 557.266235,574.976440 555.747375,574.265991 
	C555.280945,573.627380 554.814453,572.988831 554.347961,572.350220 
	C556.820129,571.301636 559.292358,570.253052 561.764526,569.204468 
	C561.139893,568.489319 560.515198,567.774109 559.890503,567.058960 
	C557.903503,568.214905 555.916504,569.370911 553.646606,570.244629 
	C552.653076,569.248169 551.989441,568.009399 551.221130,567.940674 
	C550.029419,567.834106 548.766357,568.524963 547.366333,568.925598 
	C550.148071,565.282715 552.537659,562.153381 554.927246,559.024048 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M453.440308,561.186890 
	C454.742554,560.302673 456.044800,559.418335 457.843628,558.632019 
	C459.574829,559.859558 460.809418,560.989075 461.924164,562.444946 
	C459.192993,565.657898 458.737396,568.957397 459.927063,572.571960 
	C459.975830,572.752502 460.024597,572.933044 459.875214,573.117310 
	C456.287079,570.242493 451.529114,572.625793 448.092957,569.733276 
	C448.803192,567.516479 449.468445,565.664246 450.463837,563.825073 
	C451.676056,562.954407 452.558167,562.070679 453.440308,561.186890 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M430.999725,496.817810 
	C424.584320,490.973846 417.137390,489.669464 408.501160,492.136902 
	C408.791351,490.861023 409.395050,489.931122 409.998779,489.001221 
	C409.998779,489.001221 410.000000,489.000000 410.001770,488.998199 
	C413.090637,488.666443 416.935455,490.502197 418.417847,485.556458 
	C418.616333,484.894287 421.993042,485.184723 424.182831,485.011475 
	C424.429413,487.031921 424.616150,488.561829 424.786133,489.954651 
	C429.749542,492.165894 431.722748,489.164337 433.122772,485.097046 
	C433.528137,485.212067 433.933502,485.327087 434.338837,485.442108 
	C434.602203,487.795685 434.865540,490.149231 435.128906,492.502777 
	C434.559845,492.364014 433.990784,492.225220 433.421722,492.086426 
	C432.619293,493.536865 431.816864,494.987305 430.999725,496.817810 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M222.696381,313.295593 
	C222.983261,322.564423 223.164444,332.201721 223.164886,341.839020 
	C223.166107,369.270630 223.093689,396.702240 222.957199,424.599976 
	C222.642792,423.275574 222.232834,421.485535 222.228302,419.694458 
	C222.157593,391.775879 222.139313,363.857178 222.093155,335.470276 
	C222.220963,327.889404 222.364548,320.776733 222.696381,313.295593 
z"/>
<path fill="#7C6B8D" opacity="1.000000" stroke="none" 
	d="
M554.711853,558.916016 
	C552.537659,562.153381 550.148071,565.282715 547.366333,568.925598 
	C548.766357,568.524963 550.029419,567.834106 551.221130,567.940674 
	C551.989441,568.009399 552.653076,569.248169 553.279419,570.283203 
	C552.991699,570.695190 552.858032,570.847778 552.522583,571.361267 
	C552.108032,572.072021 551.965088,572.483154 551.822083,572.894348 
	C551.822083,572.894348 551.888428,572.631897 551.576538,572.584229 
	C551.264587,572.536560 550.959106,572.746460 550.959106,572.746460 
	C550.200439,572.843506 549.441772,572.940491 548.215454,572.637878 
	C546.997314,570.266846 546.392578,568.220642 545.435608,566.355225 
	C544.945862,565.400574 543.825500,564.769531 542.985718,563.664490 
	C543.698364,562.243530 544.415466,561.150330 545.132629,560.057129 
	C545.963928,559.555603 546.795227,559.054016 548.188782,558.339111 
	C550.418884,557.954651 552.086792,557.783569 553.754639,557.612549 
	C554.001953,558.010986 554.249207,558.409424 554.711853,558.916016 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M392.741852,566.745422 
	C393.125610,567.877197 393.509399,569.009033 393.614685,570.482666 
	C392.206360,571.516907 391.076538,572.209290 389.946716,572.901611 
	C389.148834,573.104126 388.350922,573.306519 386.976196,573.638428 
	C385.970032,573.736328 385.540680,573.704773 385.111298,573.673218 
	C384.978638,572.902405 384.846008,572.131653 384.555115,570.705933 
	C384.266418,569.558289 384.135925,569.065552 384.002197,568.240112 
	C384.248291,565.291809 384.497620,562.676208 384.746979,560.060608 
	C386.624481,559.390381 388.502014,558.720154 390.972656,557.984619 
	C391.974182,557.986267 392.382538,558.053162 392.869751,558.511353 
	C392.299866,560.614380 391.843048,562.433899 390.957092,564.012451 
	C389.652405,566.337036 390.284424,567.049194 392.741852,566.745422 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M396.672791,471.103943 
	C396.996979,478.291290 397.255219,485.597382 396.842987,492.865479 
	C396.706940,495.264465 394.840149,497.565247 393.386047,499.478027 
	C393.007385,491.698761 393.007355,484.352448 393.007324,477.006104 
	C394.120087,475.073395 395.232819,473.140656 396.672791,471.103943 
z"/>
<path fill="#B54A5C" opacity="1.000000" stroke="none" 
	d="
M450.133728,563.811951 
	C449.468445,565.664246 448.803192,567.516479 447.852844,570.047852 
	C445.723053,570.888367 443.878326,571.049866 442.174255,570.971680 
	C442.485168,570.193542 442.879120,569.222900 442.793671,569.178650 
	C438.882812,567.154602 439.750244,564.130188 441.197937,560.788391 
	C441.587189,560.238586 441.785675,559.910461 441.984131,559.582275 
	C442.576080,559.279785 443.168030,558.977234 444.264526,558.484619 
	C449.086517,557.674500 449.799408,560.559204 450.133728,563.811951 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M571.389771,530.408203 
	C571.206665,532.657959 571.103088,534.454285 571.004639,536.624084 
	C569.829102,535.815857 568.648376,534.634216 567.233887,533.226257 
	C567.423218,525.868286 568.226929,518.729431 568.093628,511.608124 
	C568.035339,508.496429 566.059265,505.420654 564.711121,501.648682 
	C566.012817,501.938507 568.112976,502.406097 570.564941,502.934784 
	C571.100952,511.982178 571.285095,520.968506 571.389771,530.408203 
z"/>
<path fill="#A55268" opacity="1.000000" stroke="none" 
	d="
M520.485474,567.990479 
	C520.666016,569.473328 520.846619,570.956177 521.050293,572.778687 
	C519.766541,573.399780 518.459717,573.681274 516.950806,573.213745 
	C516.770325,569.891235 516.791992,567.317749 516.953857,564.440918 
	C517.585876,563.394470 518.077698,562.651428 518.711853,561.553711 
	C518.474182,560.281982 518.094116,559.364868 517.744263,558.415771 
	C517.774353,558.383728 517.846313,558.434387 518.119507,557.986084 
	C518.489990,557.201660 518.587280,556.865540 518.684570,556.529358 
	C519.300720,556.038940 519.916870,555.548523 521.048950,554.942993 
	C521.966370,554.815613 522.367920,554.803284 523.013794,554.790283 
	C523.603088,554.701843 523.832397,554.489441 523.982056,554.090942 
	C524.018005,554.029663 524.156494,553.997620 524.453247,553.990601 
	C530.240417,553.528015 526.075256,557.428894 526.875488,559.256714 
	C526.255920,559.752319 525.784485,560.013855 525.063232,560.234863 
	C524.529053,560.356201 524.244568,560.518066 523.725220,560.879578 
	C522.963074,561.709412 522.435791,562.339539 521.690552,563.114990 
	C521.372131,563.586060 521.271667,563.911743 521.086548,564.610229 
	C520.829712,565.985535 520.657593,566.987976 520.485474,567.990479 
z"/>
<path fill="#A2546A" opacity="1.000000" stroke="none" 
	d="
M534.469788,559.952271 
	C531.945557,561.897583 529.421326,563.842896 526.622192,565.416260 
	C529.498596,560.026794 532.649963,555.009338 535.801270,549.991821 
	C536.051880,549.749512 536.302429,549.507141 536.859741,548.840576 
	C538.180725,547.307007 539.195007,546.197693 540.209290,545.088379 
	C540.855469,545.111450 541.501709,545.134521 542.505493,545.449707 
	C543.003113,546.164062 543.143127,546.586426 543.283203,547.008728 
	C542.529480,548.350830 541.775696,549.692871 540.632812,551.468567 
	C539.868591,552.617554 539.493469,553.332825 539.118347,554.048096 
	C539.118347,554.048096 539.047302,554.044006 538.733276,554.193481 
	C537.102722,556.212708 535.786255,558.082520 534.469788,559.952271 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M610.511108,572.725952 
	C607.985229,573.234680 605.125000,574.903015 603.952026,570.122559 
	C604.192444,568.235901 604.402649,567.052368 604.742737,565.137573 
	C602.255493,566.976257 600.451416,568.309875 598.478699,569.475830 
	C598.310059,569.308105 597.972839,568.972717 598.030762,568.579285 
	C598.356506,565.830627 598.624268,563.475342 599.207886,561.090454 
	C600.147217,560.966064 600.770691,560.871216 601.421997,561.104370 
	C602.169678,561.990173 602.823486,562.879150 603.622803,563.038513 
	C605.397766,563.392517 607.245667,563.380737 609.082336,563.862793 
	C608.475708,567.398010 609.580994,568.349609 612.648865,567.277710 
	C612.842468,568.759521 612.945190,569.842834 612.744263,571.040039 
	C611.797424,571.677856 611.154297,572.201904 610.511108,572.725952 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M461.155701,551.608887 
	C461.711243,552.666687 462.044403,553.459045 461.930145,553.187256 
	C458.821320,554.029297 456.039459,554.835815 453.225769,555.509521 
	C452.468964,555.690735 451.609741,555.516235 450.807220,555.422363 
	C447.153870,554.994873 443.503937,554.537903 439.146057,554.005127 
	C442.180115,550.305664 445.581207,550.015015 449.615387,550.973633 
	C451.098663,551.326111 453.876923,551.212524 454.260712,550.428528 
	C457.658173,543.487793 459.029297,548.998352 461.155701,551.608887 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M328.980804,557.121826 
	C327.398315,557.544250 325.810486,557.947937 324.234985,558.394958 
	C322.954681,558.758179 321.689178,559.173462 319.940552,559.830933 
	C318.461456,559.595215 317.458893,559.094177 316.351227,558.361877 
	C316.133514,557.810181 316.020874,557.489807 315.947449,556.776245 
	C317.322815,553.587830 318.658966,550.792725 319.995117,547.997559 
	C320.786621,548.298889 321.578094,548.600281 322.685181,549.343506 
	C323.151764,551.307617 323.302765,552.829834 323.528931,555.109558 
	C325.553833,553.539429 326.783630,552.585815 328.367096,551.357971 
	C328.645447,552.493896 328.828156,553.239624 329.010864,553.985352 
	C329.008575,554.433044 329.006287,554.880798 328.996399,555.944702 
	C328.986145,556.747803 328.983459,556.934814 328.980804,557.121826 
z"/>
<path fill="#955B76" opacity="1.000000" stroke="none" 
	d="
M275.055969,569.987915 
	C275.054260,569.247559 275.052551,568.507141 275.230621,567.176697 
	C275.536591,566.028687 275.662781,565.470825 275.788940,564.912903 
	C276.158508,564.509155 276.528046,564.105408 277.320709,563.358276 
	C278.202667,562.371643 278.661530,561.728394 279.120361,561.085205 
	C279.120392,561.085205 279.528290,560.911255 279.946228,560.779053 
	C280.686890,560.521545 280.867706,560.286865 280.906616,559.942871 
	C281.195618,559.788086 281.452118,559.591064 282.167114,559.117004 
	C283.986389,558.726624 285.314667,558.570984 286.642975,558.415405 
	C286.642975,558.415405 286.835144,558.713562 286.947876,559.222107 
	C285.641418,561.469482 284.263000,563.243835 282.790009,564.935852 
	C281.620911,566.278748 280.335968,567.520874 279.102478,568.807800 
	C280.601654,569.377197 282.109589,569.925110 283.593964,570.530823 
	C283.974396,570.686096 284.280365,571.023804 285.829895,572.180237 
	C282.485657,573.097351 280.165100,573.733765 277.844543,574.370117 
	C277.286407,573.768921 276.728271,573.167664 276.008911,572.049744 
	C275.583801,571.018005 275.319885,570.502930 275.055969,569.987915 
z"/>
<path fill="#AE4C60" opacity="1.000000" stroke="none" 
	d="
M362.181427,568.821411 
	C362.534180,568.963806 362.886902,569.106201 363.521820,569.649658 
	C363.807495,570.916504 363.811005,571.782227 363.814514,572.647949 
	C363.814514,572.647949 363.495880,572.911682 362.979034,573.030334 
	C356.852936,574.029907 358.518219,569.431824 357.796875,566.493774 
	C358.442413,565.397095 358.932526,564.555115 359.727936,563.514648 
	C360.423737,559.458313 359.819336,554.956238 365.942322,554.728149 
	C367.107300,556.179749 367.989502,557.530396 368.871704,558.881042 
	C368.210205,559.311462 367.548706,559.741943 366.337158,560.257080 
	C365.547119,560.569397 365.307129,560.796997 365.067139,561.024597 
	C365.067139,561.024597 365.057648,561.062622 364.718140,561.131226 
	C364.060730,561.922729 363.742859,562.645569 363.424988,563.368469 
	C362.984070,564.678650 362.543182,565.988770 361.857056,567.743774 
	C361.801697,568.399475 361.991577,568.610474 362.181427,568.821411 
z"/>
<path fill="#8B6482" opacity="1.000000" stroke="none" 
	d="
M296.800079,571.405518 
	C294.567963,571.918274 292.335846,572.430969 289.594299,572.483154 
	C285.559113,567.881409 288.123932,564.918823 291.252686,562.278748 
	C293.122406,562.536987 294.760101,562.536987 295.626099,562.536987 
	C298.490967,564.366150 293.327118,568.905396 298.792572,569.164551 
	C298.032990,570.070984 297.416534,570.738281 296.800079,571.405518 
z"/>
<path fill="#5788B9" opacity="1.000000" stroke="none" 
	d="
M583.787598,558.487915 
	C585.575806,559.527283 587.078735,560.607727 588.870789,562.243896 
	C587.724548,564.533997 586.289246,566.268372 584.853882,568.002808 
	C583.629578,568.520813 582.405212,569.038818 580.645447,569.846069 
	C577.078613,569.838074 577.246277,568.223877 578.120850,565.920715 
	C578.927063,563.797668 579.269775,561.498718 580.152344,559.209106 
	C581.115540,558.891174 581.743103,558.641357 582.370605,558.391602 
	C582.747864,558.437378 583.125122,558.483154 583.787598,558.487915 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M493.441711,554.076599 
	C493.233063,555.108398 493.024414,556.140259 492.521454,557.744202 
	C489.787903,560.541748 487.348663,562.767212 484.626984,564.923279 
	C483.902740,564.282654 483.460907,563.711426 482.953430,562.802002 
	C482.281769,561.274658 481.675720,560.085449 481.069672,558.896301 
	C481.069672,558.896301 481.112762,558.467041 481.285828,558.059570 
	C481.720337,557.137146 481.981750,556.622192 482.243164,556.107300 
	C482.975830,555.777039 483.708496,555.446838 485.026611,555.006348 
	C486.637299,554.231323 487.662567,553.566467 488.687836,552.901611 
	C488.687836,552.901611 488.994659,552.678772 489.460388,552.472534 
	C490.928467,552.202942 491.930756,552.139465 492.933075,552.075989 
	C492.933075,552.075989 493.003632,552.010803 492.964447,552.390503 
	C493.097412,553.205688 493.269562,553.641113 493.441711,554.076599 
z"/>
<path fill="#8C637F" opacity="1.000000" stroke="none" 
	d="
M384.596802,559.783081 
	C384.497620,562.676208 384.248291,565.291809 383.753510,568.487061 
	C383.243988,569.701965 382.979919,570.337097 382.715881,570.972290 
	C380.026581,571.507080 377.337280,572.041809 374.293823,572.261230 
	C373.950531,570.988403 373.961395,570.030823 373.972229,569.073242 
	C375.700989,567.021057 377.429749,564.968933 379.164764,562.537598 
	C379.171021,562.158508 379.125580,562.114136 379.336273,561.989502 
	C379.788757,561.627563 379.936981,561.341736 380.232483,560.853394 
	C380.988800,560.143188 381.504211,559.587036 382.019653,559.030884 
	C382.828674,559.189148 383.637634,559.347412 384.596802,559.783081 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M255.269501,401.098633 
	C257.249664,404.496429 260.359192,404.836884 263.787109,404.604218 
	C266.569000,404.415344 269.361694,404.385315 272.576294,404.207550 
	C274.873566,403.970856 276.744171,403.811401 279.284546,403.594879 
	C279.372101,405.195587 279.470093,406.987274 279.308289,408.890503 
	C277.225891,408.122101 275.454315,406.641174 273.570496,406.481171 
	C268.941528,406.087952 264.259644,406.224854 259.601410,406.298279 
	C256.026672,406.354614 254.166382,405.084137 255.269501,401.098633 
z"/>
<path fill="#3999C9" opacity="1.000000" stroke="none" 
	d="
M291.020630,562.020508 
	C288.123932,564.918823 285.559113,567.881409 289.206177,572.347656 
	C287.259949,573.471436 285.245575,574.502563 283.107239,574.991638 
	C281.542908,575.349487 279.815643,574.994690 278.002777,574.663818 
	C280.165100,573.733765 282.485657,573.097351 285.829895,572.180237 
	C284.280365,571.023804 283.974396,570.686096 283.593964,570.530823 
	C282.109589,569.925110 280.601654,569.377197 279.102478,568.807800 
	C280.335968,567.520874 281.620911,566.278748 282.790009,564.935852 
	C284.263000,563.243835 285.641418,561.469482 287.076355,559.344360 
	C288.260376,559.549500 289.428650,560.140930 290.835632,561.174072 
	C291.074310,561.615845 291.020630,562.020508 291.020630,562.020508 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M441.007202,561.010010 
	C439.750244,564.130188 438.882812,567.154602 442.793671,569.178650 
	C442.879120,569.222900 442.485168,570.193542 442.208740,571.295471 
	C441.301819,572.244995 440.102356,573.178162 439.763153,572.930908 
	C436.983063,570.904663 435.555267,572.258911 434.436554,574.760132 
	C434.014832,574.979675 433.593109,575.199219 432.750366,575.648071 
	C431.946045,575.985779 431.562714,576.094177 431.179413,576.202576 
	C431.113770,574.326660 431.048096,572.450806 431.261963,570.179993 
	C432.675995,569.036377 433.810455,568.287659 435.259674,567.337585 
	C436.229889,566.402039 436.885315,565.667847 437.292053,564.738342 
	C436.612000,563.178955 436.180573,561.814941 435.855713,560.186279 
	C436.641846,559.594482 437.321381,559.267395 438.000916,558.940308 
	C438.000916,558.940308 438.275360,559.136719 438.561829,559.448914 
	C439.567902,560.177429 440.287537,560.593689 441.007202,561.010010 
z"/>
<path fill="#B54A5C" opacity="1.000000" stroke="none" 
	d="
M584.984009,568.311707 
	C586.289246,566.268372 587.724548,564.533997 589.131836,562.449341 
	C589.272095,561.796570 589.440308,561.494019 589.988525,560.924072 
	C590.609314,560.483826 590.807922,560.271545 590.964294,560.019653 
	C591.749084,559.690002 592.533875,559.360291 594.034668,558.995056 
	C595.827026,558.978516 596.903381,558.997559 597.989868,559.008301 
	C598.000000,559.000000 597.982971,559.019714 598.001953,559.319702 
	C598.311218,560.119812 598.601624,560.619934 598.891968,561.120117 
	C598.624268,563.475342 598.356506,565.830627 597.669800,568.586426 
	C596.160034,567.467468 595.069153,565.947937 593.598022,564.351074 
	C592.356079,565.137085 591.494385,566.000366 590.445312,567.113403 
	C589.839722,568.525024 589.421509,569.686890 589.003235,570.848694 
	C587.706848,570.106018 586.410522,569.363281 584.984009,568.311707 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M374.944733,503.140259 
	C373.277496,501.171753 371.994690,499.231079 370.581116,497.011719 
	C372.069702,495.900665 373.689117,495.068237 375.308502,494.235840 
	C375.051117,493.825439 374.793762,493.415009 374.536377,493.004608 
	C374.536377,494.784698 374.536377,496.564789 374.536377,498.344879 
	C375.151672,498.409912 375.766937,498.474915 376.382233,498.539948 
	C377.916840,492.241974 376.273376,485.477783 377.309662,479.106689 
	C377.733582,479.090515 378.157501,479.074310 378.581421,479.058105 
	C378.719940,481.175659 378.953674,483.292114 378.977814,485.410980 
	C379.034393,490.375153 378.913239,495.342041 379.017426,500.304626 
	C379.076294,503.109009 377.795410,503.807068 374.944733,503.140259 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M313.063873,494.537415 
	C313.175995,495.038269 313.288116,495.539154 313.221313,496.762329 
	C313.026642,507.988953 313.010864,518.493225 312.995117,528.997559 
	C312.330994,527.296509 311.118927,525.604187 311.091736,523.893005 
	C310.917236,512.910522 311.002563,501.923889 311.252930,490.348206 
	C312.001831,489.506805 312.500305,489.255859 312.998810,489.463318 
	C313.020508,491.460327 313.042206,492.998871 313.063873,494.537415 
z"/>
<path fill="#A2546A" opacity="1.000000" stroke="none" 
	d="
M570.905273,572.855469 
	C567.084290,574.649353 564.875366,572.976074 565.319153,569.235291 
	C565.661621,566.348450 567.574768,563.647888 569.182739,560.429688 
	C569.772217,559.692322 569.968567,559.389832 570.164917,559.087341 
	C570.507202,559.040405 570.766785,558.868591 571.337402,558.289917 
	C572.513672,558.274841 573.296326,558.541809 574.011108,559.166016 
	C573.689575,560.458923 573.435791,561.394592 573.059082,562.629517 
	C571.731323,564.481384 570.526550,566.033997 570.033752,566.669067 
	C570.421570,569.421997 570.663452,571.138733 570.905273,572.855469 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M514.429138,498.622406 
	C507.597595,491.431763 499.520752,489.111328 489.455322,492.123199 
	C489.003662,491.124756 489.002441,490.562988 489.001221,490.001221 
	C490.467163,489.484680 493.073639,489.182892 493.217773,488.417450 
	C494.322418,482.550873 496.195343,485.635254 498.944977,487.294617 
	C503.112640,489.809784 507.932495,491.225861 512.208618,493.591003 
	C513.467773,494.287476 513.826050,496.612671 514.429138,498.622406 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M434.803589,574.875854 
	C435.555267,572.258911 436.983063,570.904663 439.763153,572.930908 
	C440.102356,573.178162 441.301819,572.244995 442.068115,571.535156 
	C443.878326,571.049866 445.723053,570.888367 447.807892,570.412354 
	C451.529114,572.625793 456.287079,570.242493 459.882263,573.397827 
	C459.070129,574.038940 457.798035,574.988953 457.075775,574.674866 
	C451.500824,572.249817 446.262756,574.528381 440.887665,575.396545 
	C439.051666,575.693115 437.080170,575.150696 434.803589,574.875854 
z"/>
<path fill="#9F5670" opacity="1.000000" stroke="none" 
	d="
M349.565857,560.431091 
	C349.982971,560.147278 350.400085,559.863464 351.280823,559.317932 
	C354.116425,558.075684 356.105133,558.135681 356.859314,561.306885 
	C355.377808,562.692871 354.093170,563.784607 352.808594,564.876404 
	C351.282104,566.173828 349.755646,567.471191 348.082520,568.877625 
	C348.234436,569.360291 348.532959,569.733887 348.859314,570.116455 
	C348.887085,570.125427 348.905426,570.180908 348.774109,570.473022 
	C347.722595,571.222656 346.802368,571.680237 345.882172,572.137817 
	C345.882172,572.137817 345.977661,572.064209 345.946533,571.744080 
	C345.221985,570.244080 344.528595,569.064270 344.037109,567.613159 
	C346.014648,565.038391 347.790253,562.734741 349.565857,560.431091 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M535.480347,549.960754 
	C532.649963,555.009338 529.498596,560.026794 526.238647,565.417358 
	C524.499268,566.561768 522.868591,567.333130 520.861694,568.047485 
	C520.657593,566.987976 520.829712,565.985535 521.310181,564.468018 
	C521.715210,563.625244 521.811829,563.297485 521.908447,562.969666 
	C522.435791,562.339539 522.963074,561.709412 523.974304,560.921875 
	C524.743164,560.601501 525.028076,560.438477 525.312988,560.275391 
	C525.784485,560.013855 526.255920,559.752319 527.202148,559.075928 
	C528.039734,556.185547 528.402649,553.709961 528.847961,551.153198 
	C528.930359,551.072083 529.095703,550.910400 529.095703,550.910400 
	C530.106201,550.578613 531.116638,550.246826 532.127136,549.915039 
	C531.979980,549.525085 531.832886,549.135132 531.685791,548.745178 
	C532.843689,549.140015 534.001587,549.534851 535.480347,549.960754 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M606.986938,556.206665 
	C607.014099,554.888733 606.627014,553.362427 607.161438,552.299500 
	C608.061035,550.510254 609.393921,548.834045 610.918274,547.556030 
	C611.207703,547.313416 613.210205,549.170532 614.462891,549.997437 
	C615.130066,550.437927 615.884155,550.746826 616.831421,551.503784 
	C616.464417,553.657898 615.864929,555.423462 615.277161,557.217651 
	C615.288818,557.246399 615.254272,557.297791 614.815674,557.256348 
	C611.913635,556.878784 609.450256,556.542725 606.986938,556.206665 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M429.247681,533.000671 
	C429.976074,531.208435 430.959778,529.419373 431.927826,527.274109 
	C431.912170,526.917969 431.957062,526.961731 432.156464,526.786621 
	C432.533600,526.271179 432.711304,525.930786 432.889038,525.590454 
	C432.724274,525.348450 432.739410,525.118347 433.103821,524.825317 
	C437.240662,524.666565 437.177216,522.317444 436.544556,519.331299 
	C436.284180,518.102295 436.827209,516.703125 437.004822,515.381348 
	C437.960876,516.494995 439.287476,517.463501 439.792877,518.753662 
	C440.734039,521.156128 441.197052,523.745911 441.929688,526.631348 
	C441.160034,527.066711 440.319794,527.128418 438.873535,527.103027 
	C437.176941,527.677368 436.086334,528.338684 434.995728,529.000000 
	C434.332794,530.094604 433.669861,531.189209 432.627563,532.644775 
	C431.503265,533.007019 430.758423,533.008423 430.013550,533.009766 
	C429.843323,533.007751 429.673126,533.005798 429.247681,533.000671 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M338.988342,485.428284 
	C339.702301,484.959961 340.410828,484.881866 341.472107,484.778259 
	C344.717346,487.176636 347.486694,486.638550 349.683594,483.199188 
	C350.378235,482.111664 351.002045,480.978943 351.684265,479.883118 
	C351.723236,479.820557 351.998016,479.904816 352.811493,479.983276 
	C352.419067,483.986267 350.629089,488.085358 347.084717,488.522888 
	C342.164551,489.130219 339.537933,491.003235 338.112366,495.424469 
	C337.926575,496.000854 336.977203,496.331116 335.548279,497.396759 
	C336.891174,492.869965 337.937073,489.344238 338.988342,485.428284 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M370.475677,496.157715 
	C369.709473,496.013550 369.101898,495.379822 368.491394,494.471832 
	C368.597443,493.214478 369.129211,492.006561 368.745728,491.285278 
	C366.496521,487.054596 368.739563,483.585510 370.137512,479.812347 
	C371.107727,477.193665 371.228882,474.260376 371.729401,471.467651 
	C372.323212,471.538269 372.917023,471.608917 373.510834,471.679535 
	C372.552002,479.675751 371.593170,487.671936 370.475677,496.157715 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M553.843811,557.246704 
	C552.086792,557.783569 550.418884,557.954651 548.382446,558.117920 
	C546.821411,556.639832 545.628906,555.169556 543.974731,553.130066 
	C544.876160,552.957825 546.511353,552.085388 547.430115,552.570374 
	C550.740479,554.317566 551.657349,552.305237 553.105652,549.991211 
	C554.016296,548.536194 555.979065,547.739685 557.469788,546.647827 
	C557.649597,547.039062 557.829346,547.430359 558.009155,547.821594 
	C556.650452,550.841370 555.291748,553.861084 553.843811,557.246704 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M491.126404,572.638000 
	C490.572845,572.242737 490.019287,571.847473 488.785950,571.582031 
	C487.089783,572.175476 486.073395,572.639099 485.052856,572.712524 
	C485.014160,571.286438 484.979553,570.250549 485.314087,569.147339 
	C487.915710,567.028381 490.148163,564.976746 492.841583,563.021057 
	C493.610779,563.175781 493.919067,563.234619 494.328857,563.567810 
	C494.169250,565.626160 493.908081,567.410278 493.646912,569.194336 
	C494.988525,569.113037 496.330170,569.031738 497.772095,569.242676 
	C497.934540,569.940186 497.996613,570.345398 497.836884,571.072876 
	C495.452179,571.809509 493.289307,572.223755 491.126404,572.638000 
z"/>
<path fill="#7C6B8D" opacity="1.000000" stroke="none" 
	d="
M313.073273,566.950317 
	C313.164856,568.197876 313.256439,569.445435 313.409668,571.171631 
	C311.999573,572.084045 310.527863,572.517883 308.928528,572.279175 
	C308.113342,570.087036 307.425842,568.567383 306.489594,566.858276 
	C306.196686,565.421082 306.255920,564.157227 306.065796,562.932068 
	C305.967010,562.295227 305.427826,561.726685 305.086121,560.903931 
	C305.084686,560.680542 305.077484,560.233704 305.360504,560.011475 
	C307.466064,559.936707 309.288574,560.084106 311.111511,560.571167 
	C310.605621,562.343079 310.099335,563.775330 309.397858,565.759827 
	C311.035217,565.483643 312.080963,565.307190 313.105713,565.433838 
	C313.080872,566.141357 313.077087,566.545837 313.073273,566.950317 
z"/>
<path fill="#6A779D" opacity="1.000000" stroke="none" 
	d="
M345.946838,572.495300 
	C346.802368,571.680237 347.722595,571.222656 349.173401,570.486328 
	C352.349762,569.054504 354.995575,567.901428 357.641418,566.748352 
	C358.518219,569.431824 356.852936,574.029907 362.805969,573.143555 
	C361.142303,574.671753 348.013092,574.453247 345.946838,572.495300 
z"/>
<path fill="#AE4C60" opacity="1.000000" stroke="none" 
	d="
M373.630005,568.952271 
	C373.961395,570.030823 373.950531,570.988403 373.928131,572.306152 
	C372.881287,572.793335 371.845978,572.920471 370.417847,572.605591 
	C369.748505,571.740723 369.472015,571.317932 369.084137,570.559631 
	C368.595428,569.105774 368.218079,567.987427 367.840729,566.869019 
	C369.794769,564.162598 371.748810,561.456177 373.702850,558.749756 
	C374.411652,559.204041 375.120483,559.658325 375.829285,560.112610 
	C375.013306,561.609497 373.956390,563.030945 373.457092,564.626831 
	C373.052673,565.919434 373.319000,567.421936 373.630005,568.952271 
z"/>
<path fill="#9F5670" opacity="1.000000" stroke="none" 
	d="
M573.182007,562.330200 
	C573.435791,561.394592 573.689575,560.458923 574.232178,559.235352 
	C574.521118,558.947327 574.979736,559.014465 574.979736,559.014465 
	C574.979736,559.014465 574.997986,559.002441 575.093506,559.324463 
	C576.829956,561.727722 578.059998,561.063599 579.089905,559.062500 
	C579.089905,559.062500 579.016907,559.027405 579.228882,559.098206 
	C579.440857,559.169006 579.816650,559.277222 579.816650,559.277222 
	C579.269775,561.498718 578.927063,563.797668 578.120850,565.920715 
	C577.246277,568.223877 577.078613,569.838074 580.463501,570.136475 
	C580.167603,571.135559 579.518188,572.133423 578.180542,573.134094 
	C576.724915,573.054688 575.957520,572.972473 575.190125,572.890259 
	C575.190125,572.890198 575.151978,572.845703 575.142944,572.471069 
	C574.177856,569.744629 572.491394,567.475159 574.729492,564.847656 
	C574.926819,564.615967 573.730835,563.197449 573.182007,562.330200 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M485.057007,573.102722 
	C486.073395,572.639099 487.089783,572.175476 488.440338,571.661865 
	C489.231384,573.144348 489.688263,574.676819 490.036926,575.846313 
	C487.611877,576.014404 485.367615,576.420776 483.172729,576.237061 
	C481.613983,576.106689 479.042725,575.475403 478.791443,574.556702 
	C477.830902,571.045166 476.531464,567.180176 480.500732,564.034668 
	C480.989655,563.998718 481.279236,564.248962 481.149048,564.671692 
	C480.832458,566.738220 480.535980,568.378479 480.482788,570.026550 
	C480.374329,573.386169 481.689392,574.819092 485.057007,573.102722 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M365.096497,552.131836 
	C363.093536,552.068787 361.079193,551.847961 359.091064,551.991028 
	C357.419128,552.111389 355.777649,552.655151 353.338898,553.180298 
	C354.347595,549.973633 355.054474,547.726379 356.041901,544.587341 
	C357.501709,546.368347 358.457550,547.534485 358.613739,547.725037 
	C362.515198,548.258545 365.426636,548.656677 368.574646,549.339722 
	C368.585938,550.182251 368.360657,550.739807 367.956726,551.421875 
	C367.536102,551.706726 367.290283,551.860901 366.715088,552.005188 
	C365.958527,552.044861 365.527496,552.088379 365.096497,552.131836 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M606.654541,556.177551 
	C609.450256,556.542725 611.913635,556.878784 614.820068,557.615723 
	C615.127563,558.968201 614.992065,559.919800 614.656250,561.161377 
	C613.823242,563.260681 613.190613,565.069946 612.557983,566.879272 
	C609.580994,568.349609 608.475708,567.398010 609.402527,563.622314 
	C610.219177,561.966675 610.734131,560.896240 611.462708,559.381775 
	C608.116394,559.122986 605.525635,558.922607 602.925903,558.400879 
	C603.611206,557.379150 604.305481,556.678772 604.999695,555.978333 
	C605.440552,556.035034 605.881348,556.091675 606.654541,556.177551 
z"/>
<path fill="#01B2F6" opacity="1.000000" stroke="none" 
	d="
M312.997070,529.248535 
	C313.010864,518.493225 313.026642,507.988953 313.051575,496.975586 
	C313.743622,498.947815 314.961945,501.418335 315.011963,503.912323 
	C315.181519,512.370117 314.937866,520.836243 314.526184,529.650024 
	C313.800781,530.001038 313.401245,530.001221 313.001709,530.001465 
	C313.000824,529.834167 312.999908,529.666809 312.997070,529.248535 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M257.029785,334.037262 
	C256.687866,334.918976 256.345978,335.800720 255.758636,336.826599 
	C253.890900,333.207520 255.061371,331.299286 259.226807,331.314941 
	C263.381073,331.330566 267.557434,331.544952 271.679199,331.173615 
	C273.348816,331.023163 274.899567,329.552979 276.719177,328.367493 
	C277.142853,329.134216 277.351715,330.216797 277.447540,331.785522 
	C277.334503,332.271667 277.283905,332.598602 277.283905,332.598602 
	C276.773041,332.787201 276.262207,332.975800 275.107361,333.114685 
	C273.946808,333.089020 273.430237,333.113037 272.913696,333.137085 
	C268.296936,333.141541 263.680206,333.145996 258.360718,333.219147 
	C257.448608,333.537659 257.239197,333.787445 257.029785,334.037262 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M311.111115,560.231506 
	C309.288574,560.084106 307.466064,559.936707 305.107849,559.995789 
	C304.403473,560.201355 304.234741,560.200500 304.065979,560.199585 
	C303.698669,558.762146 303.082275,557.329773 303.053192,555.885559 
	C303.034363,554.950806 303.874451,553.998779 304.668243,553.367126 
	C305.233398,554.502625 305.459930,555.325562 305.686462,556.148438 
	C305.944000,556.131042 306.201538,556.113708 306.459045,556.096313 
	C306.639771,555.065002 306.820496,554.033752 307.001221,553.002441 
	C307.002563,552.672913 306.934418,552.324890 307.016663,552.016968 
	C307.500214,550.206726 307.989441,548.395752 308.598694,546.627075 
	C308.635162,546.521240 309.726013,546.778687 310.326263,546.867126 
	C309.934387,548.676270 309.542542,550.485474 309.078491,553.047485 
	C310.045166,555.220642 311.084045,556.640930 312.093781,558.362915 
	C312.020752,559.066528 311.976868,559.468445 311.726318,559.958008 
	C311.519653,560.045593 311.111115,560.231506 311.111115,560.231506 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M604.924255,555.644531 
	C604.305481,556.678772 603.611206,557.379150 602.706421,558.483154 
	C602.495850,558.886780 602.048950,559.028015 601.642334,559.012451 
	C600.151489,559.004517 599.067200,559.012146 597.982971,559.019714 
	C597.982971,559.019714 598.000000,559.000000 597.926392,558.698975 
	C596.898987,557.627197 595.945312,556.856506 594.991577,556.085754 
	C594.434387,554.772583 593.877258,553.459412 593.006348,551.406799 
	C597.954407,551.095215 601.510376,552.831543 604.924255,555.644531 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M532.383972,530.850098 
	C532.041565,523.053284 531.917542,515.098206 532.172791,507.155273 
	C532.237122,505.152985 533.514648,503.189667 534.600708,501.077881 
	C534.636963,509.843109 534.343933,518.740540 533.928406,527.632263 
	C533.879883,528.669800 533.154419,529.675781 532.383972,530.850098 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M329.393738,553.978821 
	C328.828156,553.239624 328.645447,552.493896 328.367096,551.357971 
	C326.783630,552.585815 325.553833,553.539429 323.528931,555.109558 
	C323.302765,552.829834 323.151764,551.307617 322.999146,549.395142 
	C325.742218,547.252136 328.410919,545.008057 331.427368,548.689209 
	C333.659302,551.412964 333.937347,553.336914 329.393738,553.978821 
z"/>
<path fill="#B24C60" opacity="1.000000" stroke="none" 
	d="
M333.097321,571.450073 
	C332.981750,569.137817 333.085938,567.136597 333.306702,564.416321 
	C333.311707,563.476562 333.200073,563.255859 333.088440,563.035217 
	C333.088440,563.035217 333.021820,563.015198 333.363098,563.115112 
	C334.787537,562.459778 335.870667,561.704468 337.140564,561.220337 
	C337.231079,563.338257 337.134796,565.184998 336.959442,567.354004 
	C336.952209,568.107971 337.024048,568.539612 337.124939,569.150146 
	C337.455811,569.420288 337.757660,569.511597 338.405243,569.592041 
	C339.434875,569.483643 340.118835,569.386108 340.752594,569.583496 
	C340.146118,570.936340 339.589844,571.994385 339.033539,573.052429 
	C337.128052,572.622009 335.222565,572.191528 333.097321,571.450073 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M522.769409,554.791016 
	C522.367920,554.803284 521.966370,554.815613 521.275146,554.745605 
	C521.819031,553.149048 522.652710,551.634888 524.329041,548.590149 
	C520.594727,549.617371 518.175171,550.282898 515.377808,550.974243 
	C514.355042,545.930298 519.091492,546.395569 521.266907,546.291382 
	C523.882446,546.166016 528.016479,545.824036 529.041992,550.545959 
	C529.095703,550.910400 528.930359,551.072083 528.508545,551.123169 
	C526.776672,552.115417 525.466553,553.056519 524.156494,553.997559 
	C524.156494,553.997620 524.018005,554.029663 523.742065,554.093201 
	C523.135559,554.260132 522.903320,554.471497 522.769409,554.791016 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M337.038544,567.031738 
	C337.134796,565.184998 337.231079,563.338257 337.140015,560.968262 
	C336.935059,560.277832 336.917419,560.110718 336.899780,559.943604 
	C337.123505,559.624939 337.347168,559.306274 337.654907,558.839233 
	C337.738983,558.690857 338.058563,558.483032 338.058563,558.483032 
	C339.076813,558.249695 340.095093,558.016357 341.847137,557.793335 
	C343.082184,557.787903 343.583405,557.772095 344.084656,557.756287 
	C344.376251,558.004211 344.667847,558.252075 345.132507,559.074402 
	C344.843079,564.677612 340.941559,565.855530 337.038544,567.031738 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M637.203796,553.098816 
	C636.494690,557.996521 632.448120,558.224609 628.469727,559.138367 
	C629.654175,556.696899 631.213806,554.190063 632.773438,551.683228 
	C633.232361,551.911316 633.691284,552.139404 634.150146,552.367493 
	C633.912476,551.188416 633.674744,550.009399 633.278381,548.043396 
	C635.506592,548.467102 637.168274,548.783081 639.234070,549.222290 
	C639.174866,550.192078 638.711548,551.038635 637.794617,552.042236 
	C637.295288,552.499146 637.249512,552.798950 637.203796,553.098816 
z"/>
<path fill="#8C637F" opacity="1.000000" stroke="none" 
	d="
M614.856567,560.871460 
	C614.992065,559.919800 615.127563,558.968201 615.258667,557.657166 
	C615.254272,557.297791 615.288818,557.246399 615.574158,557.089111 
	C617.177979,554.913086 618.496521,552.894348 619.815063,550.875549 
	C620.366089,550.325745 620.917175,549.776001 622.079956,549.029053 
	C623.488281,548.847229 624.284851,548.862488 625.078918,548.906982 
	C625.076355,548.936096 625.119385,548.975647 625.088379,549.362427 
	C624.298218,551.454041 623.575012,553.175964 622.770996,554.859253 
	C621.756104,556.983765 620.675232,559.076721 619.402344,561.241455 
	C619.181824,561.299683 618.732422,561.221619 618.248169,561.170166 
	C616.794800,561.036316 615.825684,560.953857 614.856567,560.871460 
z"/>
<path fill="#0CB0F5" opacity="1.000000" stroke="none" 
	d="
M391.438782,526.837280 
	C391.084198,519.974121 391.071716,512.947876 391.131165,505.456787 
	C391.790802,505.784302 392.378479,506.576660 392.989777,507.695496 
	C392.213043,511.541962 394.628906,513.184143 397.338257,515.064697 
	C397.787842,515.650879 397.900116,516.024292 397.893707,516.673218 
	C396.472778,516.856750 395.170563,516.764771 393.807983,516.668518 
	C393.108398,520.121582 392.444611,523.397827 391.438782,526.837280 
z"/>
<path fill="#6380A4" opacity="1.000000" stroke="none" 
	d="
M303.814636,560.430664 
	C304.234741,560.200500 304.403473,560.201355 304.824829,560.218018 
	C305.077484,560.233704 305.084686,560.680542 304.886414,561.189941 
	C303.534027,564.698486 302.379883,567.697754 301.225708,570.696960 
	C299.999451,570.924072 298.773132,571.151184 297.173462,571.391907 
	C297.416534,570.738281 298.032990,570.070984 298.883514,568.728699 
	C298.626587,564.312561 301.607544,558.599915 293.939301,558.934082 
	C295.708649,556.072876 297.658386,557.199036 299.725098,558.588318 
	C300.926270,559.395813 302.278870,559.978088 303.814636,560.430664 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M383.100800,533.263916 
	C384.728485,533.019653 386.440308,533.085327 388.577789,533.197388 
	C388.723480,536.539673 389.840820,540.569702 385.348145,541.875916 
	C383.827393,542.318054 380.919617,542.188232 380.478485,541.352478 
	C379.192444,538.915649 381.381958,538.996460 383.232483,538.405273 
	C387.414551,537.069214 384.411316,535.203857 383.100800,533.263916 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M535.004883,521.836853 
	C535.004944,512.686646 535.004944,512.686646 538.344360,511.214508 
	C538.049255,517.114685 537.771057,522.675842 537.492859,528.237000 
	C536.829834,528.238159 536.166809,528.239319 535.503784,528.240479 
	C535.337463,526.261169 535.171204,524.281860 535.004883,521.836853 
z"/>
<path fill="#0CB0F5" opacity="1.000000" stroke="none" 
	d="
M392.658936,477.005615 
	C393.007355,484.352448 393.007385,491.698761 393.010193,499.511139 
	C392.595673,500.907501 392.178314,501.837799 391.465637,502.886505 
	C391.105072,494.656769 391.039856,486.308655 390.945496,477.495056 
	C391.381073,477.021393 391.845795,477.013275 392.658936,477.005615 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M492.380646,562.925171 
	C490.148163,564.976746 487.915710,567.028381 485.292480,569.082947 
	C484.901703,569.085938 484.766785,569.078369 484.785217,568.706909 
	C484.694824,567.854065 484.585968,567.372742 484.598785,566.587646 
	C484.783447,565.853516 484.846405,565.423035 484.909393,564.992615 
	C487.348663,562.767212 489.787903,560.541748 492.496124,558.138794 
	C493.640961,558.042542 494.516876,558.123962 495.529144,558.670288 
	C494.570587,560.398499 493.475616,561.661865 492.380646,562.925171 
z"/>
<path fill="#B24C60" opacity="1.000000" stroke="none" 
	d="
M460.252991,572.734009 
	C458.737396,568.957397 459.192993,565.657898 461.920197,562.408081 
	C462.036102,562.044739 462.110077,562.041565 462.325500,561.932983 
	C462.799286,561.604431 462.947571,561.325867 463.108337,560.760315 
	C463.649170,560.241150 464.067474,559.950317 464.936707,559.744629 
	C465.997009,559.862000 466.606384,559.894226 467.278015,560.274048 
	C467.350525,561.089722 467.360809,561.557983 467.371094,562.026184 
	C467.371063,562.026184 466.992432,562.264343 466.631165,562.519653 
	C466.074554,562.837402 465.879242,562.899719 465.505829,563.081177 
	C465.121826,563.422302 465.023163,563.684570 464.915710,564.264526 
	C464.261414,565.571533 463.723145,566.601257 463.227905,567.996460 
	C463.541901,569.301880 463.812897,570.241882 464.083893,571.181824 
	C463.926147,571.452087 463.713074,571.667847 463.021606,571.955139 
	C462.315338,572.189758 462.113037,572.382874 461.991638,572.660706 
	C461.520721,572.739136 461.049835,572.817627 460.252991,572.734009 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M395.950989,584.895325 
	C397.115784,587.172119 398.280609,589.448975 399.510864,591.853760 
	C396.012970,592.013489 392.886780,592.156250 388.782593,592.343689 
	C390.342957,589.780762 391.605377,587.707153 393.305237,585.290527 
	C394.478790,584.930115 395.214874,584.912720 395.950989,584.895325 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M378.989197,550.599731 
	C377.331970,552.533997 375.675995,554.089233 373.989349,555.610413 
	C373.105438,556.407593 372.167755,557.145142 370.956909,557.524170 
	C370.801056,555.765198 370.942657,554.391541 371.284424,552.743103 
	C371.593201,551.361694 371.701843,550.255188 371.810486,549.148621 
	C372.159424,548.636841 372.508331,548.125061 372.928619,547.306641 
	C375.378174,547.349976 378.776062,545.798889 378.989197,550.599731 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M562.000000,507.397217 
	C562.960266,507.723328 564.726013,508.420624 564.753601,509.180969 
	C564.979187,515.398865 564.880737,521.628540 564.880737,527.854614 
	C564.397217,527.871399 563.913635,527.888123 563.430115,527.904846 
	C563.106628,522.498962 562.783203,517.093018 562.229858,511.343689 
	C562.000000,509.932709 562.000000,508.865143 562.000000,507.397217 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M319.973877,547.635925 
	C318.658966,550.792725 317.322815,553.587830 315.593384,556.741699 
	C314.487732,557.117737 313.775391,557.135071 313.050537,556.737549 
	C313.026154,555.215881 313.014313,554.109192 313.002441,553.002441 
	C313.871429,550.887146 314.635742,548.719482 315.657501,546.680786 
	C316.107269,545.783447 317.080750,545.148621 317.816467,544.394653 
	C318.528534,545.354553 319.240570,546.314392 319.973877,547.635925 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M338.210632,504.262146 
	C338.093903,511.845337 337.036652,519.567566 341.845825,526.797852 
	C340.406342,525.673279 338.144897,524.452576 337.594330,522.695618 
	C335.697510,516.642578 334.241364,510.399292 338.210632,504.262146 
z"/>
<path fill="#BC495B" opacity="1.000000" stroke="none" 
	d="
M492.841583,563.021057 
	C493.475616,561.661865 494.570587,560.398499 495.829987,558.804077 
	C496.158173,558.432190 496.321930,558.391296 497.085754,558.308777 
	C498.838989,558.376404 499.992126,558.485657 501.145233,558.594971 
	C501.169647,558.770264 501.194061,558.945557 501.231934,559.755737 
	C501.283905,563.365906 501.322388,566.341125 501.146942,569.545105 
	C499.974915,570.099426 499.016815,570.424988 498.058685,570.750549 
	C497.996613,570.345398 497.934540,569.940186 497.830322,568.865662 
	C498.038788,566.394592 498.289429,564.592896 498.603851,562.332520 
	C496.694092,562.751892 495.460724,563.022705 494.227356,563.293457 
	C493.919067,563.234619 493.610779,563.175781 492.841583,563.021057 
z"/>
<path fill="#C74352" opacity="1.000000" stroke="none" 
	d="
M614.656250,561.161377 
	C615.825684,560.953857 616.794800,561.036316 618.180054,561.419922 
	C618.498962,561.863953 618.401672,562.006775 618.037964,562.228516 
	C617.577087,562.612183 617.382751,562.916870 617.065552,563.416992 
	C616.942749,563.612366 616.656616,563.974487 616.488403,564.103760 
	C616.122559,564.456299 616.025452,564.717102 616.024048,565.372070 
	C616.336609,566.622253 616.653992,567.515686 616.971375,568.409119 
	C617.599731,569.212402 618.228088,570.015625 619.126953,570.962158 
	C617.938049,571.721680 616.478577,572.337952 614.597717,572.622314 
	C613.800171,571.835632 613.424011,571.380920 613.047974,570.926208 
	C612.945190,569.842834 612.842468,568.759521 612.648865,567.277710 
	C613.190613,565.069946 613.823242,563.260681 614.656250,561.161377 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M451.612427,491.589630 
	C449.636475,493.607941 447.241119,495.457550 444.509705,497.427185 
	C443.203125,495.535645 442.232666,493.524078 440.778900,490.510742 
	C441.523438,489.871521 443.072327,488.541687 445.261719,486.661957 
	C445.530518,488.879059 445.677704,490.093140 445.838654,491.420929 
	C447.671265,491.420929 449.432159,491.420929 451.612427,491.589630 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M474.595703,498.505249 
	C469.794342,492.727112 463.553253,490.221832 455.442993,491.044312 
	C458.037140,490.294067 461.071503,489.648132 464.662811,489.107727 
	C465.219757,489.213257 465.648468,489.264587 465.648468,489.264587 
	C465.648468,489.264587 466.014679,489.009125 466.014679,489.009125 
	C466.452148,489.009857 466.889618,489.010620 467.754089,489.328278 
	C470.122589,491.429718 472.064148,493.214264 474.005676,494.998779 
	C474.231903,496.035004 474.458130,497.071228 474.595703,498.505249 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M518.569519,561.908386 
	C518.077698,562.651428 517.585876,563.394470 516.674072,564.516296 
	C515.901611,565.051453 515.549011,565.207886 515.196472,565.364319 
	C513.529297,563.153015 511.862122,560.941650 509.451538,557.744263 
	C512.420349,557.425903 514.545471,557.197998 517.062988,557.231567 
	C517.585632,557.806763 517.716003,558.120605 517.846313,558.434387 
	C517.846313,558.434387 517.774353,558.383728 517.593018,558.639038 
	C517.797668,559.899048 518.183594,560.903687 518.569519,561.908386 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M276.633728,376.986816 
	C276.262848,370.418182 276.183044,363.616119 276.136505,356.334045 
	C276.714020,356.683533 277.258209,357.513062 277.854950,359.001221 
	C277.979523,360.102448 278.051575,360.544983 278.123596,360.987518 
	C277.723999,366.242798 277.324402,371.498108 276.633728,376.986816 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M364.934998,552.440002 
	C365.527496,552.088379 365.958527,552.044861 366.609680,552.284302 
	C366.439758,553.253906 366.049652,553.940552 365.659576,554.627136 
	C359.819336,554.956238 360.423737,559.458313 359.596619,563.152100 
	C358.458710,562.329529 357.757507,561.671143 357.056274,561.012695 
	C356.105133,558.135681 354.116425,558.075684 351.410645,559.098999 
	C351.829712,558.414551 352.582520,557.687500 353.922485,556.944092 
	C354.509613,556.927795 354.992157,556.946838 354.992157,556.946838 
	C358.252625,555.547302 361.513092,554.147766 364.934998,552.440002 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M481.327789,533.584961 
	C484.642273,535.117798 487.622192,536.702271 491.348297,538.683594 
	C487.709320,538.683594 484.329041,539.286865 481.309753,538.501892 
	C478.665344,537.814392 476.833374,535.545776 481.327789,533.584961 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M336.959442,567.354004 
	C340.941559,565.855530 344.843079,564.677612 345.356628,559.253784 
	C346.555481,559.298279 347.703247,559.737732 349.208435,560.304138 
	C347.790253,562.734741 346.014648,565.038391 343.709595,567.684387 
	C342.387726,568.447388 341.595245,568.867981 340.802765,569.288635 
	C340.118835,569.386108 339.434875,569.483643 338.191772,569.392822 
	C337.453705,569.126770 337.274811,569.049011 337.095917,568.971313 
	C337.024048,568.539612 336.952209,568.107971 336.959442,567.354004 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M411.918243,568.962769 
	C414.322784,570.291504 416.727325,571.620300 419.131866,572.949097 
	C418.843964,573.436768 418.556091,573.924438 418.268188,574.412109 
	C416.894836,574.141602 415.521484,573.871094 413.844391,573.540771 
	C413.958221,574.552185 414.056732,575.427429 414.155243,576.302612 
	C413.760590,576.521057 413.365967,576.739502 412.971313,576.957886 
	C412.086639,575.125793 411.201996,573.293762 410.253418,570.948242 
	C410.765778,569.944153 411.342010,569.453430 411.918243,568.962769 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M463.184875,567.630981 
	C463.723145,566.601257 464.261414,565.571533 465.109985,564.158691 
	C465.653137,563.551392 465.740997,563.280212 465.683899,562.961975 
	C465.879242,562.899719 466.074554,562.837402 466.574951,562.736206 
	C468.659485,563.635315 470.438934,564.573303 472.679810,565.754517 
	C472.485535,568.666443 470.000397,569.867249 466.833923,570.369568 
	C468.817749,564.528198 464.013519,568.845581 463.184875,567.630981 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M273.176697,430.041626 
	C278.686920,428.477081 278.409027,423.942047 279.011993,419.317627 
	C279.673340,419.119385 279.920868,419.233582 280.179504,419.323639 
	C279.469818,423.048492 278.760101,426.773346 277.263367,430.501343 
	C275.874329,430.564117 275.272339,430.623779 274.670349,430.683441 
	C274.206329,430.544006 273.742310,430.404572 273.176697,430.041626 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M325.998352,530.920654 
	C325.665009,530.430725 325.697174,530.038452 325.768860,529.354797 
	C327.939117,528.306763 330.578033,525.988220 331.611359,530.011108 
	C332.424164,533.175720 331.647705,536.132385 329.233398,537.609070 
	C328.064117,534.923462 327.213989,532.970886 325.998352,530.920654 
z"/>
<path fill="#C74250" opacity="1.000000" stroke="none" 
	d="
M293.673248,559.101501 
	C301.607544,558.599915 298.626587,564.312561 299.026642,568.489563 
	C293.327118,568.905396 298.490967,564.366150 295.626099,562.536987 
	C294.760101,562.536987 293.122406,562.536987 291.252686,562.278748 
	C291.020630,562.020508 291.074310,561.615845 291.064850,561.411865 
	C291.839325,560.561584 292.623260,559.915283 293.673248,559.101501 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M419.620911,536.981323 
	C422.295258,536.304504 424.542267,535.641907 427.799347,534.681458 
	C423.804047,539.388855 418.187622,540.263855 412.112610,536.995544 
	C414.472931,536.995544 416.833221,536.995544 419.620911,536.981323 
z"/>
<path fill="#5D87AC" opacity="1.000000" stroke="none" 
	d="
M573.059082,562.629517 
	C573.730835,563.197449 574.926819,564.615967 574.729492,564.847656 
	C572.491394,567.475159 574.177856,569.744629 575.134277,572.443115 
	C574.007324,572.828064 572.879944,572.866333 571.328979,572.880005 
	C570.663452,571.138733 570.421570,569.421997 570.033752,566.669067 
	C570.526550,566.033997 571.731323,564.481384 573.059082,562.629517 
z"/>
<path fill="#9D5971" opacity="1.000000" stroke="none" 
	d="
M394.182495,579.080994 
	C394.983673,578.143127 395.784821,577.205322 396.978302,575.942627 
	C397.673676,575.446838 397.976715,575.276001 398.586029,575.042786 
	C400.040985,575.038513 401.189667,575.096619 402.159790,575.418274 
	C401.682770,576.465515 401.384369,577.249207 401.085999,578.032837 
	C399.586212,579.628174 398.086456,581.223450 396.012268,582.846252 
	C395.019409,581.609497 394.600952,580.345215 394.182495,579.080994 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M497.836884,571.072876 
	C499.016815,570.424988 499.974915,570.099426 501.454681,569.412476 
	C502.363251,568.021729 502.750153,566.992249 503.137085,565.962830 
	C503.137085,565.962830 503.593262,565.966431 503.946442,566.182007 
	C504.494049,566.642029 504.726715,566.842041 504.997589,566.997559 
	C504.997589,566.997559 505.484680,567.011658 505.673645,567.329346 
	C505.223358,568.745605 504.584137,569.844177 503.944916,570.942749 
	C501.366364,572.075684 498.867981,573.517090 496.176788,574.216553 
	C494.747437,574.588135 492.983643,573.673035 491.248596,572.988403 
	C493.289307,572.223755 495.452179,571.809509 497.836884,571.072876 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M618.304443,562.149597 
	C618.401672,562.006775 618.498962,561.863953 618.664307,561.471375 
	C618.732422,561.221619 619.181824,561.299683 619.694092,561.178711 
	C621.419739,560.007385 622.633240,558.957092 623.846680,557.906738 
	C624.681702,558.586182 625.516785,559.265564 626.702698,559.989075 
	C624.706299,562.558716 622.358948,565.084229 619.488159,567.440186 
	C618.811157,565.849609 618.657593,564.428711 618.501770,562.782104 
	C618.499512,562.556519 618.304443,562.149597 618.304443,562.149597 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M560.939453,491.108246 
	C559.633240,492.594879 558.010986,493.688324 556.582214,494.993500 
	C554.422852,496.966095 553.090088,495.882202 551.779541,493.614685 
	C554.442200,492.826416 556.025879,491.795258 554.136719,488.164520 
	C556.327820,489.046295 558.518982,489.928070 560.939453,491.108246 
z"/>
<path fill="#07B0F6" opacity="1.000000" stroke="none" 
	d="
M312.998779,489.004883 
	C312.500305,489.255859 312.001831,489.506805 311.252289,489.879639 
	C309.906281,489.669708 308.811310,489.337921 307.124023,488.826721 
	C305.354828,488.765259 304.178040,488.883209 303.001221,489.001221 
	C302.999237,487.951416 302.997253,486.901611 302.992737,485.459412 
	C304.715698,485.030792 306.441193,484.994598 308.660950,485.256836 
	C309.762238,485.408783 310.369232,485.262360 310.976227,485.115906 
	C311.428467,485.095673 311.880707,485.075439 312.669189,485.481995 
	C313.003204,486.940796 313.001007,487.972839 312.998779,489.004883 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M593.978333,564.428345 
	C595.069153,565.947937 596.160034,567.467468 597.611816,568.979858 
	C597.972839,568.972717 598.310059,569.308105 598.266968,569.709839 
	C597.093811,571.037476 595.963806,571.963440 594.833801,572.889282 
	C594.412781,572.942261 593.991699,572.995239 593.195862,572.692627 
	C592.246643,571.957764 591.672180,571.578369 591.097778,571.198975 
	C591.064697,571.788696 591.031616,572.378479 590.998535,572.968201 
	C590.369507,572.465271 589.740417,571.962341 589.057312,571.154053 
	C589.421509,569.686890 589.839722,568.525024 590.585327,567.444641 
	C594.894958,569.423157 593.752502,566.248718 593.978333,564.428345 
z"/>
<path fill="#B54A5C" opacity="1.000000" stroke="none" 
	d="
M301.379150,570.826477 
	C302.379883,567.697754 303.534027,564.698486 304.887878,561.413330 
	C305.427826,561.726685 305.967010,562.295227 306.065796,562.932068 
	C306.255920,564.157227 306.196686,565.421082 306.430359,567.185242 
	C305.036102,568.859680 303.452362,570.017700 301.868591,571.175781 
	C301.868591,571.175781 301.532593,570.955994 301.379150,570.826477 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M344.972504,550.618042 
	C339.049683,550.776917 337.398651,549.887451 335.025787,544.553162 
	C338.417328,548.113953 343.668488,544.852051 344.972504,550.618042 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M365.672607,492.385010 
	C360.129272,492.666687 354.506653,492.552551 348.461792,492.426331 
	C351.324646,487.680145 361.194305,487.441193 365.672607,492.385010 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M398.228241,541.518799 
	C396.326385,540.223328 394.777863,538.929138 393.229340,537.634888 
	C393.516235,537.112671 393.803162,536.590515 394.090057,536.068298 
	C397.089233,537.268188 400.088379,538.468079 404.330444,540.165222 
	C401.601807,540.808289 400.091675,541.164185 398.228241,541.518799 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M570.987793,547.010437 
	C572.583618,551.926880 570.239624,554.644958 564.554871,557.101074 
	C566.400391,553.188293 567.682190,550.470642 569.314941,547.380981 
	C570.106506,547.009460 570.547180,547.009949 570.987793,547.010437 
z"/>
<path fill="#A2546A" opacity="1.000000" stroke="none" 
	d="
M538.490295,564.962952 
	C537.016357,565.286682 535.542419,565.610413 533.132690,566.139648 
	C534.003479,564.249146 534.479370,563.215942 535.270691,561.680786 
	C536.727661,560.359009 537.869202,559.539246 539.010803,558.719421 
	C539.565857,559.024292 540.120972,559.329102 540.823853,560.153320 
	C540.144531,562.102722 539.317383,563.532837 538.490295,564.962952 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M595.157654,572.910645 
	C595.963806,571.963440 597.093811,571.037476 598.435547,569.877502 
	C600.451416,568.309875 602.255493,566.976257 604.742737,565.137573 
	C604.402649,567.052368 604.192444,568.235901 603.852112,569.736145 
	C602.354492,571.288269 600.987061,572.523682 599.309814,573.879517 
	C599.000000,574.000000 599.005493,574.003540 599.005493,574.003540 
	C597.830811,573.646301 596.656189,573.289124 595.157654,572.910645 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M432.110992,318.997803 
	C431.589264,316.620911 431.536499,314.246063 431.585541,311.436920 
	C432.914429,311.001099 434.141479,310.999603 435.830750,310.997620 
	C436.347321,313.371399 436.401672,315.745636 436.390778,318.560059 
	C435.076996,319.000122 433.828461,319.000000 432.110992,318.997803 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M473.071228,527.793335 
	C473.459991,524.596130 474.212341,521.387878 475.437317,517.702026 
	C476.892426,517.144653 477.874969,517.064880 478.857483,516.985046 
	C479.529846,518.757202 480.202179,520.529419 480.824280,522.650879 
	C479.259949,523.607056 477.627869,524.025818 476.268738,524.879211 
	C475.155426,525.578247 474.367188,526.794983 473.071228,527.793335 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M640.818970,567.024414 
	C639.169067,567.810181 637.500305,568.250977 634.038635,569.165405 
	C636.165527,564.620728 637.680908,561.382812 639.498962,557.981567 
	C640.583801,560.721436 637.064026,564.109253 640.818970,567.024414 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M509.183807,488.871460 
	C507.768555,487.923035 506.676575,487.100403 505.584625,486.277802 
	C505.928497,485.734314 506.272339,485.190857 506.616211,484.647369 
	C509.499390,486.294434 512.382568,487.941467 515.265686,489.588501 
	C514.929016,490.215271 514.592285,490.842041 514.255554,491.468781 
	C512.672729,490.644958 511.089935,489.821136 509.183807,488.871460 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M474.358521,494.979492 
	C472.064148,493.214264 470.122589,491.429718 468.082153,489.327484 
	C470.430695,489.699860 472.925964,490.261749 475.300018,491.148254 
	C476.153503,491.466919 476.715118,492.567078 477.409943,493.310577 
	C476.510406,493.860474 475.610870,494.410370 474.358521,494.979492 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M385.010986,467.000000 
	C385.932983,465.977997 386.681244,464.448334 387.819672,464.058685 
	C389.346039,463.536255 391.177063,463.850464 392.868225,463.953735 
	C393.020538,463.963043 393.291168,465.535980 393.195984,465.572540 
	C391.753693,466.125854 390.259369,466.543671 388.010956,466.995544 
	C386.500122,466.998596 385.755554,466.999329 385.010986,467.000000 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M464.485748,559.659485 
	C464.067474,559.950317 463.649170,560.241150 462.872192,560.847900 
	C462.379028,561.456360 462.244568,561.748962 462.110077,562.041565 
	C462.110077,562.041565 462.036102,562.044739 462.040070,562.081665 
	C460.809418,560.989075 459.574829,559.859558 458.107513,558.493835 
	C460.842133,557.930420 463.809509,557.603149 466.583435,557.676270 
	C465.755280,558.604248 465.120514,559.131897 464.485748,559.659485 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M571.333618,546.864380 
	C570.547180,547.009949 570.106506,547.009460 569.335388,547.010620 
	C569.636353,544.482971 567.172791,540.535950 572.604370,540.011597 
	C573.002441,540.000000 573.000000,540.000000 573.000000,539.998779 
	C572.559753,542.237793 572.119568,544.478088 571.333618,546.864380 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M401.191040,557.467896 
	C401.017059,557.732483 400.782440,557.921936 400.258423,558.046875 
	C400.157318,555.596008 400.108856,553.100037 400.577972,550.705444 
	C400.660492,550.284180 402.909790,550.287415 404.158417,550.094604 
	C403.756195,551.063843 403.276428,552.009827 402.976746,553.009827 
	C402.743164,553.789001 402.733704,554.635315 402.534241,555.780701 
	C402.026245,556.562378 401.608643,557.015137 401.191040,557.467896 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M623.932678,557.565125 
	C622.633240,558.957092 621.419739,560.007385 619.914551,561.120483 
	C620.675232,559.076721 621.756104,556.983765 622.770996,554.859253 
	C623.575012,553.175964 624.298218,551.454041 625.291504,549.614136 
	C625.709229,550.321716 625.892639,551.164551 626.076050,552.007324 
	C625.390259,553.746033 624.704407,555.484741 623.932678,557.565125 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M473.927734,556.287659 
	C472.018524,555.333801 470.428986,554.270752 468.839447,553.207642 
	C469.055573,552.640076 469.271667,552.072510 469.487793,551.504944 
	C470.911224,551.586853 472.914520,551.135986 473.606842,551.889587 
	C474.416840,552.771240 474.084320,554.702454 473.927734,556.287659 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M406.995697,556.008850 
	C408.339203,555.079529 409.635834,554.057922 411.079071,553.325012 
	C411.284607,553.220703 412.819824,554.880005 412.687592,555.105103 
	C411.878052,556.483276 410.765259,557.683289 409.303040,558.679932 
	C408.233612,557.614441 407.614655,556.811646 406.995697,556.008850 
z"/>
<path fill="#C74250" opacity="1.000000" stroke="none" 
	d="
M542.990112,563.992249 
	C543.825500,564.769531 544.945862,565.400574 545.435608,566.355225 
	C546.392578,568.220642 546.997314,570.266846 547.841064,572.558838 
	C547.208496,573.004395 546.482666,573.129272 545.207153,572.970703 
	C544.089966,572.472168 543.522461,572.257080 542.955017,572.041992 
	C542.955017,572.041992 542.971924,572.017639 542.964233,572.030762 
	C542.883484,569.849243 542.810425,567.654541 542.759766,564.897217 
	C542.782043,564.334595 542.990112,563.992249 542.990112,563.992249 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M297.972961,463.998535 
	C299.684204,464.183655 301.139160,464.626617 302.594086,465.069550 
	C301.257660,466.369934 299.921234,467.670319 298.584808,468.970673 
	C298.128906,468.698151 297.673004,468.425598 297.217072,468.153046 
	C297.383606,466.854156 297.550110,465.555237 297.972961,463.998535 
z"/>
<path fill="#01B2F6" opacity="1.000000" stroke="none" 
	d="
M488.651611,489.848785 
	C489.002441,490.562988 489.003662,491.124756 489.029083,492.108704 
	C489.046448,492.784912 488.916687,492.922882 488.546082,492.851624 
	C486.195526,493.453461 483.962830,494.148529 481.361389,494.924072 
	C480.995331,493.937286 480.997986,492.869995 481.294861,491.318909 
	C482.060211,490.223785 482.531311,489.612488 483.002441,489.001221 
	C484.768951,489.232941 486.535492,489.464630 488.651611,489.848785 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M437.983398,558.622803 
	C437.321381,559.267395 436.641846,559.594482 435.554565,560.326782 
	C434.105255,561.841064 433.063751,562.950134 431.659180,563.986938 
	C430.659454,563.570068 430.022797,563.225342 429.386108,562.880676 
	C429.910095,561.825684 430.434082,560.770752 431.007690,559.355957 
	C432.490540,558.402466 433.881989,557.590698 435.374176,557.304810 
	C436.145844,557.157043 437.096222,557.941895 437.983398,558.622803 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M421.126831,548.193359 
	C419.948761,549.513672 418.957062,550.567932 417.252808,552.379578 
	C416.614014,550.458313 415.638062,548.454041 415.930664,548.247559 
	C417.481842,547.153015 419.351685,546.510132 421.111084,545.710693 
	C421.178467,546.449524 421.245850,547.188416 421.126831,548.193359 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M585.675903,545.012573 
	C586.363647,547.299133 586.722534,549.600769 587.081421,551.902344 
	C586.422180,552.084351 585.762939,552.266296 585.103760,552.448303 
	C584.401245,550.561890 583.698730,548.675476 582.993408,546.398315 
	C583.776123,545.680908 584.561584,545.354248 585.675903,545.012573 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M478.379517,549.801758 
	C477.578064,548.699707 477.031982,547.824707 476.485870,546.949707 
	C476.712402,546.671326 476.938904,546.392883 477.165436,546.114441 
	C479.581421,547.371643 481.997406,548.628784 484.413422,549.885925 
	C484.277649,550.436462 484.141876,550.987000 484.006104,551.537476 
	C482.215668,551.034607 480.425262,550.531738 478.379517,549.801758 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M380.164307,533.806824 
	C380.109009,534.919800 379.879333,535.731445 379.649689,536.543152 
	C378.839478,535.895020 377.415100,535.317871 377.330566,534.585938 
	C377.040253,532.071838 377.219055,529.503601 378.073639,526.895142 
	C378.712433,529.098633 379.351196,531.302063 380.164307,533.806824 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M539.673889,534.327393 
	C539.107605,535.943848 538.309570,537.328552 537.511475,538.713257 
	C536.769348,537.970886 536.027222,537.228516 535.285034,536.486145 
	C536.670715,535.689331 538.056396,534.892517 539.673889,534.327393 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M537.037598,499.864532 
	C540.357117,495.821991 543.563171,496.838562 546.660034,500.659851 
	C543.576965,500.728149 540.424805,500.435913 537.037598,499.864532 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M519.815857,423.266449 
	C521.261108,424.122772 522.483337,425.268829 523.878296,426.678101 
	C523.433411,428.056763 522.815735,429.172272 522.043945,430.565979 
	C519.219727,428.787262 518.486206,426.518768 519.815857,423.266449 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M450.350708,541.389587 
	C453.547668,541.040283 456.443542,540.863464 459.717194,540.663574 
	C456.841339,543.804626 451.616333,544.082458 450.350708,541.389587 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M450.463806,563.825073 
	C449.799408,560.559204 449.086517,557.674500 444.384705,558.254883 
	C445.649323,554.734131 447.689972,555.706665 449.761505,557.747986 
	C450.795807,558.767151 451.733307,559.884521 453.077454,561.072144 
	C452.558167,562.070679 451.676056,562.954407 450.463806,563.825073 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M430.865479,558.165161 
	C429.844391,558.488220 428.881348,558.407959 427.556152,558.303772 
	C428.594025,555.532715 428.890961,551.744019 433.848053,551.357239 
	C432.693512,553.885620 431.808533,555.823669 430.865479,558.165161 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M628.012939,572.942261 
	C628.471008,571.848816 629.271118,570.876404 629.833435,570.192993 
	C631.818359,571.563782 633.463440,572.699890 635.483398,573.787109 
	C635.596985,576.532654 634.358704,577.240784 631.982483,575.566223 
	C630.781677,574.720032 629.564636,573.896851 628.012939,572.942261 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M574.998291,558.648315 
	C574.979736,559.014465 574.521118,558.947327 574.300049,558.878052 
	C573.296326,558.541809 572.513672,558.274841 571.394470,558.056641 
	C571.625916,554.798218 572.978821,552.509766 577.034912,553.311279 
	C576.288086,555.150696 575.652405,556.716370 574.998291,558.648315 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M345.012085,551.390320 
	C345.488556,551.003784 346.036560,550.864136 346.454803,551.037476 
	C347.835388,551.609741 349.169067,552.294983 350.520752,552.936890 
	C349.961548,553.581421 348.998596,554.840393 348.903809,554.778137 
	C347.542053,553.883362 346.297180,552.810608 345.012085,551.390320 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M570.269531,485.683777 
	C568.121155,486.372925 566.169434,486.765106 564.217712,487.157288 
	C564.119263,486.557892 564.020752,485.958466 563.922302,485.359070 
	C566.176880,485.854797 567.541260,480.443298 570.269531,485.683777 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M512.971924,550.658264 
	C511.059082,551.952820 509.118134,552.905640 507.177216,553.858398 
	C506.855072,553.469116 506.532928,553.079773 506.210785,552.690430 
	C507.709412,551.269897 509.157318,549.783447 510.784698,548.530029 
	C510.948547,548.403809 512.198120,549.687256 512.971924,550.658264 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M280.847748,419.200012 
	C279.920868,419.233582 279.673340,419.119385 279.230103,418.990417 
	C279.022919,417.611176 279.022583,416.222595 279.246490,414.310883 
	C279.664886,413.525024 279.858795,413.262054 280.052429,412.998871 
	C281.037781,412.997162 282.023132,412.995453 283.494507,413.414917 
	C284.046021,415.412048 284.111511,416.988068 284.177002,418.564056 
	C283.289978,418.734833 282.402985,418.905579 280.847748,419.200012 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M367.513916,566.959717 
	C368.218079,567.987427 368.595428,569.105774 369.050293,570.810547 
	C369.098877,571.562866 369.069946,571.728882 369.041046,571.894897 
	C367.554779,572.148987 366.068512,572.403076 364.198364,572.652588 
	C363.811005,571.782227 363.807495,570.916504 363.796631,569.726929 
	C364.921875,568.618835 366.054474,567.834656 367.513916,566.959717 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M398.279785,575.105103 
	C397.976715,575.276001 397.673676,575.446838 397.114136,575.665039 
	C394.698853,574.935974 392.540070,574.159729 390.164001,573.142517 
	C391.076538,572.209290 392.206360,571.516907 393.695557,570.832336 
	C395.170441,571.433411 396.285950,572.026672 397.798187,572.971008 
	C398.223206,573.916443 398.251495,574.510742 398.279785,575.105103 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M411.899536,568.599243 
	C411.342010,569.453430 410.765778,569.944153 410.017944,570.664795 
	C409.331360,570.867126 408.816406,570.839478 407.918884,570.737915 
	C407.981354,567.701782 408.426361,564.739563 408.897095,561.404663 
	C409.908844,563.433228 410.894867,565.834473 411.899536,568.599243 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M319.048279,533.839233 
	C320.068726,533.806641 321.089172,533.774109 322.490356,533.735840 
	C322.160370,539.059265 321.365540,539.615234 317.448792,537.219727 
	C318.111969,535.799622 318.580139,534.819397 319.048279,533.839233 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M277.641785,332.805847 
	C277.283905,332.598602 277.334503,332.271667 277.429504,332.138855 
	C278.638214,331.127197 279.751953,330.248383 281.725647,328.690979 
	C282.073090,330.138947 282.551300,332.131775 283.029480,334.124634 
	C282.603149,334.376770 282.176819,334.628906 281.750458,334.881042 
	C280.500183,334.258392 279.249908,333.635742 277.641785,332.805847 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M345.418213,493.299957 
	C343.803802,496.130096 341.790741,498.914673 339.509888,501.898132 
	C338.167145,498.159729 340.075653,495.238678 345.418213,493.299957 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M377.838898,469.717102 
	C379.267303,470.068909 380.434235,470.661865 381.601196,471.254822 
	C380.872711,472.048950 380.144287,472.843109 379.415833,473.637268 
	C378.803070,472.410919 378.190247,471.184601 377.838898,469.717102 
z"/>
<path fill="#6380A4" opacity="1.000000" stroke="none" 
	d="
M301.963898,571.498230 
	C303.452362,570.017700 305.036102,568.859680 306.679077,567.374634 
	C307.425842,568.567383 308.113342,570.087036 308.740204,572.034058 
	C306.472748,572.247803 304.265991,572.034241 301.963898,571.498230 
z"/>
<path fill="#08B0EF" opacity="1.000000" stroke="none" 
	d="
M199.975250,429.932648 
	C199.731171,428.793091 199.837677,427.778442 199.973053,426.379395 
	C201.875336,427.782501 205.751709,428.247925 204.188553,433.024200 
	C202.668472,431.856781 201.497147,430.957184 199.975250,429.932648 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M573.912964,491.948639 
	C570.929565,491.897095 567.886597,491.445618 564.420532,490.888550 
	C567.689392,488.006866 571.035583,488.266815 573.912964,491.948639 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M392.855469,566.453735 
	C390.284424,567.049194 389.652405,566.337036 390.957092,564.012451 
	C391.843048,562.433899 392.299866,560.614380 393.159729,558.580505 
	C393.895233,558.532349 394.419586,558.806335 394.943909,559.080383 
	C395.006256,559.737183 395.068604,560.393982 395.176880,561.547791 
	C394.471558,563.417236 393.720337,564.789673 392.855469,566.453735 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M425.425201,553.757935 
	C424.446167,555.036438 423.630981,556.039124 422.815765,557.041870 
	C422.367554,556.769348 421.919342,556.496887 421.471161,556.224365 
	C422.260590,554.678650 422.682220,552.633545 423.932098,551.713013 
	C425.512695,550.548889 426.961304,551.044983 425.425201,553.757935 
z"/>
<path fill="#6A779D" opacity="1.000000" stroke="none" 
	d="
M340.752594,569.583496 
	C341.595245,568.867981 342.387726,568.447388 343.507690,567.955566 
	C344.528595,569.064270 345.221985,570.244080 345.890686,571.766235 
	C343.852173,572.439453 341.838318,572.770386 339.429016,573.076904 
	C339.589844,571.994385 340.146118,570.936340 340.752594,569.583496 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M486.283997,543.214905 
	C487.396057,543.194824 488.176117,543.252502 488.956207,543.310120 
	C489.105011,543.664368 489.253815,544.018616 489.402618,544.372803 
	C487.780975,545.326477 486.159332,546.280090 484.537689,547.233765 
	C484.168396,546.782166 483.799103,546.330566 483.429810,545.878967 
	C484.270569,545.016846 485.111298,544.154785 486.283997,543.214905 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M312.602478,553.001709 
	C313.014313,554.109192 313.026154,555.215881 312.917694,556.911743 
	C312.600739,557.721619 312.375946,557.908386 312.122925,558.061218 
	C311.084045,556.640930 310.045166,555.220642 309.004974,553.401367 
	C310.069916,553.001953 311.136200,553.001526 312.602478,553.001709 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M469.927887,572.827698 
	C473.374054,570.247131 473.735931,572.927185 474.619537,574.712708 
	C474.317932,575.183655 474.016357,575.654602 473.714783,576.125610 
	C472.395294,575.115173 471.075806,574.104675 469.927887,572.827698 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M561.305786,543.245850 
	C560.293762,541.909485 559.594238,540.663147 558.615845,538.919922 
	C563.407471,539.650391 563.495667,539.832092 561.305786,543.245850 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M542.985718,563.664490 
	C542.990112,563.992249 542.782043,564.334595 542.711670,564.522095 
	C541.434387,564.924194 540.227417,565.138794 538.755371,565.158203 
	C539.317383,563.532837 540.144531,562.102722 541.085632,560.332031 
	C542.247803,560.001343 543.296082,560.011292 544.738464,560.039185 
	C544.415466,561.150330 543.698364,562.243530 542.985718,563.664490 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M579.315735,538.941284 
	C579.020569,538.038452 578.820862,536.997620 579.126892,536.136719 
	C579.458313,535.204285 580.261475,534.439514 580.858887,533.601624 
	C581.561096,534.496094 582.263245,535.390625 582.985840,536.639771 
	C581.882141,537.623779 580.758057,538.253174 579.315735,538.941284 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M481.043823,524.892639 
	C481.999878,525.363281 482.550354,525.863037 483.118164,526.654175 
	C482.544952,527.203186 481.961151,527.657593 481.362457,527.678101 
	C480.041840,527.723511 478.713715,527.550232 477.388672,527.466064 
	C478.471863,526.617981 479.555054,525.769836 481.043823,524.892639 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M394.953369,558.704468 
	C394.419586,558.806335 393.895233,558.532349 393.080902,558.189209 
	C392.382538,558.053162 391.974182,557.986267 391.270020,557.820740 
	C390.839722,556.468567 390.705200,555.214966 390.570679,553.961426 
	C390.990906,553.697998 391.411133,553.434570 391.831329,553.171143 
	C392.875183,554.890259 393.919037,556.609375 394.953369,558.704468 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M284.095276,418.491150 
	C284.111511,416.988068 284.046021,415.412048 283.987183,413.412720 
	C284.000427,414.798981 284.006989,416.608643 284.095276,418.491150 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M504.356201,570.957397 
	C504.584137,569.844177 505.223358,568.745605 505.917236,567.332886 
	C507.282867,567.592834 508.593811,568.166870 509.904755,568.740906 
	C509.832733,569.245178 509.760712,569.749512 509.688690,570.253845 
	C508.048279,570.493286 506.407898,570.732666 504.356201,570.957397 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M539.012939,558.336060 
	C537.869202,559.539246 536.727661,560.359009 535.294067,561.375854 
	C534.922119,561.299866 534.842163,561.026917 534.616028,560.353088 
	C535.786255,558.082520 537.102722,556.212708 538.736206,554.228882 
	C539.040588,555.394226 539.027832,556.673462 539.012939,558.336060 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M503.067657,565.567871 
	C502.750153,566.992249 502.363251,568.021729 501.668610,569.183716 
	C501.322388,566.341125 501.283905,563.365906 501.308838,560.009827 
	C501.914276,561.476990 502.456268,563.324951 503.067657,565.567871 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M389.046997,475.350067 
	C388.754730,475.884583 388.479218,476.097931 388.110596,476.554626 
	C387.269409,476.783173 386.521332,476.768402 385.451904,476.413055 
	C385.094543,475.868164 385.058533,475.663818 385.022522,475.459473 
	C386.097626,474.119110 387.172791,472.778778 389.063782,470.421387 
	C389.063782,472.620819 389.063782,473.824860 389.046997,475.350067 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M318.714661,533.779541 
	C318.580139,534.819397 318.111969,535.799622 317.321899,536.891113 
	C314.267578,536.915894 313.778564,535.558899 315.058350,532.948669 
	C316.272705,532.977539 317.326904,533.348755 318.714661,533.779541 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M372.902618,546.676453 
	C369.891113,547.210876 368.510559,545.795227 369.591400,542.123413 
	C370.662659,543.533264 371.733948,544.943054 372.902618,546.676453 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M515.186279,565.763184 
	C515.549011,565.207886 515.901611,565.051453 516.533936,564.819702 
	C516.791992,567.317749 516.770325,569.891235 516.698120,572.949341 
	C516.157104,571.010010 515.666626,568.585999 515.186279,565.763184 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M359.907379,542.030518 
	C357.974823,542.523682 356.056091,542.696167 354.137360,542.868591 
	C354.145294,542.271301 354.153259,541.674011 354.161194,541.076721 
	C356.081207,541.287781 358.001221,541.498840 359.907379,542.030518 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M341.841980,464.551514 
	C343.135742,464.331360 344.266357,464.378418 345.397034,464.425476 
	C345.224457,465.141357 345.051910,465.857208 344.879333,466.573090 
	C343.812500,465.988312 342.745697,465.403503 341.841980,464.551514 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M492.969910,551.730225 
	C491.930756,552.139465 490.928467,552.202942 489.549927,552.305420 
	C490.002258,550.797241 490.830841,549.250000 491.659454,547.702759 
	C492.087799,548.256104 492.516144,548.809509 492.981628,549.678528 
	C493.014740,550.457642 493.010742,550.921021 492.969910,551.730225 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M626.413818,551.918823 
	C625.892639,551.164551 625.709229,550.321716 625.322571,549.227295 
	C625.119385,548.975647 625.076355,548.936096 625.092346,548.595093 
	C625.060486,547.840393 625.012634,547.426636 624.964844,547.012878 
	C626.202148,547.503540 627.439514,547.994202 628.676819,548.484863 
	C628.035095,549.600037 627.393372,550.715149 626.413818,551.918823 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M381.883362,558.752930 
	C381.504211,559.587036 380.988800,560.143188 380.023621,560.980469 
	C379.321991,561.491821 379.172546,561.776062 379.125580,562.114136 
	C379.125580,562.114136 379.171021,562.158508 379.164703,562.190186 
	C376.293945,559.836548 378.251495,558.444153 380.532104,556.944580 
	C381.143646,557.381348 381.445374,557.928162 381.883362,558.752930 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M539.783386,545.062561 
	C539.195007,546.197693 538.180725,547.307007 536.896973,548.576050 
	C535.709839,547.886841 534.792114,547.037842 532.958435,545.341492 
	C535.746338,545.208679 537.551880,545.122681 539.783386,545.062561 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M393.855011,579.217529 
	C394.600952,580.345215 395.019409,581.609497 395.752380,583.057251 
	C396.052063,583.609802 396.037231,583.978760 395.986694,584.621521 
	C395.214874,584.912720 394.478790,584.930115 393.374084,584.945679 
	C393.179504,583.080627 393.353516,581.217285 393.855011,579.217529 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M293.321503,489.454346 
	C292.835114,488.435272 292.648407,487.493622 292.461700,486.552002 
	C293.330170,486.696289 294.198608,486.840607 295.067078,486.984924 
	C294.585114,487.833893 294.103119,488.682861 293.321503,489.454346 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M619.500244,550.880615 
	C618.496521,552.894348 617.177979,554.913086 615.562500,557.060425 
	C615.864929,555.423462 616.464417,553.657898 617.207397,551.576538 
	C617.962402,551.135681 618.573914,551.010742 619.500244,550.880615 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M361.443634,485.232208 
	C360.360291,485.973969 359.199860,486.402771 358.039429,486.831573 
	C357.927490,486.194122 357.815552,485.556702 357.703613,484.919250 
	C358.924591,484.919250 360.145569,484.919250 361.443634,485.232208 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M463.227905,567.996460 
	C464.013519,568.845581 468.817749,564.528198 466.451447,570.335938 
	C465.792786,570.801453 465.233673,570.981018 464.379211,571.171204 
	C463.812897,570.241882 463.541901,569.301880 463.227905,567.996460 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M427.641510,574.122742 
	C426.859589,575.233276 426.077667,576.343750 425.027527,577.645508 
	C424.191040,576.547302 423.622803,575.257812 423.020508,573.551880 
	C423.727112,573.078430 424.467743,573.021545 425.843689,573.103516 
	C426.866547,573.535828 427.254028,573.829285 427.641510,574.122742 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M590.988770,512.626648 
	C590.247131,512.989258 589.499146,512.990784 588.377197,512.991211 
	C588.269470,511.805908 588.535706,510.621765 588.802002,509.437622 
	C589.528809,510.380249 590.255615,511.322876 590.988770,512.626648 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M558.055176,483.539612 
	C559.204102,483.980713 560.120728,484.610046 561.037415,485.239410 
	C560.545532,485.693298 560.053650,486.147186 559.561829,486.601044 
	C558.982178,485.643311 558.402527,484.685608 558.055176,483.539612 
z"/>
<path fill="#1DA3DB" opacity="1.000000" stroke="none" 
	d="
M590.817261,414.940857 
	C590.079712,415.535583 589.381775,415.818878 588.683899,416.102173 
	C588.891541,415.108002 589.099121,414.113800 589.306702,413.119629 
	C589.823425,413.622864 590.340088,414.126129 590.817261,414.940857 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M351.927643,471.141479 
	C350.921631,470.948364 350.143066,470.542572 349.364532,470.136780 
	C349.878540,469.611420 350.392578,469.086029 350.906586,468.560669 
	C351.322784,469.350037 351.738953,470.139435 351.927643,471.141479 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M385.438354,547.282593 
	C385.421661,548.434326 385.103943,549.513184 384.786194,550.592102 
	C384.226166,550.274902 383.666168,549.957703 383.106140,549.640503 
	C383.783203,548.830261 384.460266,548.020020 385.438354,547.282593 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M546.267883,485.476166 
	C547.664490,485.281647 548.836243,485.281647 550.007935,485.281647 
	C549.923462,485.826233 549.838989,486.370850 549.754517,486.915436 
	C548.517334,486.500519 547.280151,486.085602 546.267883,485.476166 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M501.114105,558.275208 
	C499.992126,558.485657 498.838989,558.376404 497.270569,558.135803 
	C498.233948,555.354614 499.642883,555.313538 501.114105,558.275208 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M642.451416,553.942200 
	C642.363342,552.309937 642.619812,550.594238 642.850708,548.497375 
	C646.495239,550.048950 645.397522,551.957703 642.451416,553.942200 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M537.691040,505.309479 
	C538.510254,504.488464 539.241333,503.949677 539.972473,503.410889 
	C540.121826,503.973511 540.271179,504.536102 540.420471,505.098724 
	C539.481323,505.263062 538.542114,505.427399 537.691040,505.309479 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M418.805725,561.243652 
	C418.133698,560.587219 417.550415,559.631836 416.967163,558.676392 
	C417.452728,558.442078 417.938324,558.207703 418.423920,557.973389 
	C418.580780,558.963806 418.737610,559.954224 418.805725,561.243652 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M406.710571,556.046143 
	C407.614655,556.811646 408.233612,557.614441 408.937744,558.712036 
	C408.747559,559.265625 408.472229,559.524536 408.004700,559.993896 
	C406.947479,559.327576 406.082397,558.450745 405.110840,557.268799 
	C405.478058,556.670166 405.951752,556.376831 406.710571,556.046143 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M401.396790,577.898254 
	C401.384369,577.249207 401.682770,576.465515 402.318878,575.343994 
	C402.853363,574.857056 402.974609,574.661316 403.020355,574.418762 
	C403.496124,574.032227 403.971863,573.645630 404.742065,573.171448 
	C403.926819,574.643738 402.817169,576.203674 401.396790,577.898254 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M418.998779,568.986938 
	C417.744934,568.206177 416.491119,567.425354 415.237274,566.644592 
	C415.425293,566.275146 415.613312,565.905701 415.801300,565.536316 
	C416.729645,565.878113 417.657990,566.219971 418.792908,567.111328 
	C418.999268,568.102905 418.999023,568.544922 418.998779,568.986938 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M371.475128,549.131958 
	C371.701843,550.255188 371.593201,551.361694 371.078674,552.534424 
	C369.826996,552.166199 368.981201,551.731750 368.135376,551.297302 
	C368.360657,550.739807 368.585938,550.182251 368.964783,549.365417 
	C369.792145,549.109131 370.465942,549.112183 371.475128,549.131958 
z"/>
<path fill="#3999C9" opacity="1.000000" stroke="none" 
	d="
M286.642151,558.076843 
	C285.314667,558.570984 283.986389,558.726624 282.319031,558.926758 
	C282.998932,556.538391 284.227295,554.896973 286.642151,558.076843 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M424.050110,582.094055 
	C425.250580,582.100769 426.235138,582.338928 427.538025,582.553223 
	C427.014069,583.272217 426.171783,584.015076 425.329498,584.757996 
	C424.831085,583.947144 424.332672,583.136353 424.050110,582.094055 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M312.803223,530.230835 
	C313.401245,530.001221 313.800781,530.001038 314.497925,530.002625 
	C314.904633,530.438721 315.013733,530.872925 315.136780,531.642578 
	C314.034576,532.416748 312.918396,532.855530 310.843506,533.671326 
	C311.713776,532.084595 312.159241,531.272400 312.803223,530.230835 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M397.342285,470.981140 
	C397.735840,469.987000 398.471680,468.973999 399.207550,467.961029 
	C399.628937,468.363037 400.050354,468.765015 400.471741,469.167023 
	C399.542694,469.765442 398.613617,470.363861 397.342285,470.981140 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M289.206116,467.142395 
	C289.748718,466.641022 290.309326,466.425659 290.869934,466.210327 
	C290.924225,466.934174 290.978516,467.658020 291.032806,468.381866 
	C290.429901,468.064056 289.826996,467.746216 289.206116,467.142395 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M464.173950,575.502808 
	C465.253723,575.306519 466.123505,575.306519 466.993317,575.306519 
	C466.897339,575.742859 466.801361,576.179199 466.705383,576.615540 
	C465.791595,576.310059 464.877808,576.004639 464.173950,575.502808 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M505.360962,575.218628 
	C504.936249,575.862488 504.237122,576.417786 503.537994,576.973083 
	C503.301361,576.574707 503.064758,576.176331 502.828156,575.777893 
	C503.580963,575.561951 504.333771,575.346008 505.360962,575.218628 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M281.225128,368.351685 
	C281.273224,367.345184 281.410522,366.616028 281.547791,365.886871 
	C281.957916,366.080139 282.368042,366.273438 282.778137,366.466736 
	C282.290192,367.187500 281.802277,367.908295 281.225128,368.351685 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M373.334930,539.100281 
	C372.468903,538.647949 371.808899,538.000977 371.148926,537.354004 
	C371.595703,537.050476 372.042511,536.747009 372.489288,536.443542 
	C372.839874,537.264221 373.190430,538.084961 373.334930,539.100281 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M388.024902,583.947632 
	C388.443878,584.958008 388.583710,585.958130 388.723511,586.958191 
	C388.229065,586.950684 387.734650,586.943176 387.240204,586.935669 
	C387.408691,585.936218 387.577209,584.936768 388.024902,583.947632 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M127.237938,529.243713 
	C127.613625,528.198669 128.217163,527.358398 129.008636,526.265747 
	C130.062256,527.885254 131.001251,529.794067 127.237938,529.243713 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M594.770508,556.081116 
	C595.945312,556.856506 596.898987,557.627197 597.916199,558.707275 
	C596.903381,558.997559 595.827026,558.978516 594.349365,558.914307 
	C593.977051,558.164734 594.006165,557.460266 594.071411,556.406128 
	C594.107666,556.056519 594.549438,556.076477 594.770508,556.081116 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M467.675598,561.997803 
	C467.360809,561.557983 467.350525,561.089722 467.418579,560.058533 
	C467.508698,559.324219 467.520477,559.152954 467.532227,558.981689 
	C468.243225,559.490295 468.954193,559.998840 469.665222,560.507446 
	C469.103516,560.994812 468.541779,561.482117 467.675598,561.997803 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M465.244629,533.167358 
	C466.371918,532.146851 467.756531,531.344971 469.487610,530.497498 
	C468.390015,531.429932 466.945984,532.407959 465.244629,533.167358 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M336.529633,559.951172 
	C336.917419,560.110718 336.935059,560.277832 336.953247,560.697083 
	C335.870667,561.704468 334.787537,562.459778 333.377197,563.146973 
	C333.054199,562.277588 333.058441,561.476318 333.138458,560.278870 
	C334.195984,559.908081 335.177734,559.933411 336.529633,559.951172 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M369.405579,529.982544 
	C370.018890,528.801758 371.017273,527.601685 372.347107,526.309631 
	C371.715942,527.466248 370.753296,528.714783 369.405579,529.982544 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M482.674652,489.004822 
	C482.531311,489.612488 482.060211,490.223785 481.295166,490.918762 
	C480.625061,490.614166 480.248871,490.225922 479.872711,489.837646 
	C480.697418,489.561249 481.522125,489.284851 482.674652,489.004822 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M444.084229,481.531616 
	C444.843964,481.440674 445.423309,481.529419 446.002686,481.618134 
	C445.845245,481.976868 445.687805,482.335602 445.530396,482.694366 
	C444.988220,482.366669 444.446045,482.038940 444.084229,481.531616 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M344.037048,482.710815 
	C343.746155,481.285095 343.717407,479.630493 343.638397,477.566406 
	C347.408752,478.453369 344.616791,480.632843 344.037048,482.710815 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M303.082764,489.278320 
	C304.178040,488.883209 305.354828,488.765259 306.767029,488.823639 
	C305.723053,489.185150 304.443665,489.370300 303.082764,489.278320 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M279.708466,384.278381 
	C279.742340,383.608398 279.962036,383.128357 280.181763,382.648346 
	C280.569183,383.043060 280.956573,383.437744 281.343994,383.832458 
	C280.860779,384.044434 280.377533,384.256378 279.708466,384.278381 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M512.125305,540.027588 
	C511.388733,540.349487 510.659882,540.411621 509.931000,540.473816 
	C509.936127,540.153381 509.941284,539.832947 509.946411,539.512512 
	C510.675293,539.597595 511.404144,539.682678 512.125305,540.027588 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M525.874390,537.972290 
	C526.628723,537.648621 527.375366,537.584839 528.122009,537.521057 
	C528.099243,537.843262 528.076538,538.165527 528.053772,538.487793 
	C527.324768,538.402588 526.595764,538.317383 525.874390,537.972290 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M605.071533,547.341614 
	C604.942139,546.668823 605.070740,546.049072 605.199341,545.429382 
	C605.574585,545.624329 605.949890,545.819336 606.325134,546.014343 
	C605.993286,546.474487 605.661438,546.934631 605.071533,547.341614 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M463.920349,538.469971 
	C463.157501,538.561401 462.575775,538.472351 461.994019,538.383362 
	C462.152252,538.023071 462.310425,537.662781 462.468567,537.302429 
	C463.012878,537.631470 463.557159,537.960449 463.920349,538.469971 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M337.001221,557.000000 
	C336.304626,556.222046 335.608032,555.444153 334.911407,554.666199 
	C335.328888,554.400391 335.746338,554.134521 336.163788,553.868713 
	C336.438934,554.691467 336.714050,555.514160 336.994568,556.668457 
	C337.000000,557.000000 337.001221,557.000000 337.001221,557.000000 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M306.666565,553.002075 
	C306.820496,554.033752 306.639771,555.065002 306.459045,556.096313 
	C306.201538,556.113708 305.944000,556.131042 305.686462,556.148438 
	C305.459930,555.325562 305.233398,554.502625 304.999939,553.343506 
	C305.439362,553.005432 305.885620,553.003601 306.666565,553.002075 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M283.605835,402.588684 
	C284.681305,402.745880 285.441040,403.194702 286.200745,403.643494 
	C285.677734,404.345184 285.154694,405.046844 284.290100,405.733704 
	C283.729034,404.772736 283.509552,403.826508 283.605835,402.588684 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M561.724609,547.711792 
	C562.391907,547.745544 562.869995,547.964172 563.348083,548.182861 
	C562.954834,548.568665 562.561584,548.954468 562.168335,549.340271 
	C561.957397,548.859070 561.746399,548.377930 561.724609,547.711792 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M396.010406,547.198059 
	C396.476105,547.630798 396.682739,548.064514 396.889343,548.498169 
	C396.309204,548.501038 395.729034,548.503906 395.148895,548.506775 
	C395.349701,548.070862 395.550507,547.634888 396.010406,547.198059 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M648.463745,552.087036 
	C648.552429,552.841919 648.463989,553.417053 648.375610,553.992126 
	C648.021179,553.835388 647.666748,553.678650 647.312378,553.521973 
	C647.637085,552.983765 647.961792,552.445557 648.463745,552.087036 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M435.357086,528.995728 
	C436.086334,528.338684 437.176941,527.677368 438.628174,527.011658 
	C437.898712,527.668701 436.808563,528.330078 435.357086,528.995728 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M580.152344,559.209106 
	C579.816650,559.277222 579.440857,559.169006 579.248718,559.133545 
	C579.052917,556.429932 579.992004,556.044250 582.121094,558.163391 
	C581.743103,558.641357 581.115540,558.891174 580.152344,559.209106 
z"/>
<path fill="#5F7FA9" opacity="1.000000" stroke="none" 
	d="
M384.716125,475.572327 
	C385.058533,475.663818 385.094543,475.868164 385.079224,476.379456 
	C384.499481,477.350525 383.971069,478.014618 383.161316,478.835876 
	C383.389893,477.890411 383.899811,476.787811 384.716125,475.572327 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M481.023773,559.304077 
	C481.675720,560.085449 482.281769,561.274658 482.753510,562.970459 
	C482.172546,563.734436 481.725891,563.991699 481.279236,564.248962 
	C481.279236,564.248962 480.989655,563.998718 480.840332,563.875488 
	C480.786652,562.405457 480.882263,561.058594 481.023773,559.304077 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M488.358856,552.909302 
	C487.662567,553.566467 486.637299,554.231323 485.286621,554.853333 
	C485.984131,554.179321 487.006989,553.548096 488.358856,552.909302 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M561.606750,554.284912 
	C561.364685,554.746399 561.068054,554.989624 560.771362,555.232788 
	C560.663147,554.843933 560.554993,554.455078 560.446838,554.066284 
	C560.815247,554.066406 561.183716,554.066528 561.606750,554.284912 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M468.239868,486.015137 
	C467.841400,486.339661 467.451508,486.488983 467.055573,486.507172 
	C466.974579,486.510895 466.870941,486.022858 466.777740,485.761505 
	C467.267273,485.772217 467.756775,485.782898 468.239868,486.015137 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M474.722961,561.389893 
	C474.939972,561.815125 474.939606,562.184692 474.939270,562.554260 
	C474.550415,562.443909 474.161591,562.333618 473.772766,562.223267 
	C474.017059,561.926941 474.261353,561.630554 474.722961,561.389893 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M624.637695,547.028687 
	C625.012634,547.426636 625.060486,547.840393 625.094910,548.565979 
	C624.284851,548.862488 623.488281,548.847229 622.291382,548.827881 
	C622.697632,548.230652 623.504089,547.637512 624.637695,547.028687 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M329.250854,557.289551 
	C328.983459,556.934814 328.986145,556.747803 328.995605,556.280396 
	C329.935181,556.433411 330.867889,556.866882 331.912842,557.605103 
	C331.190338,557.759033 330.355621,557.608154 329.250854,557.289551 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M438.237579,546.015015 
	C437.840424,546.338440 437.451874,546.487244 437.057251,546.505371 
	C436.976593,546.509094 436.873383,546.022705 436.780548,545.762268 
	C437.268372,545.772888 437.756195,545.783569 438.237579,546.015015 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M419.269043,569.122986 
	C418.999023,568.544922 418.999268,568.102905 419.000366,567.329224 
	C419.558533,566.725708 420.115845,566.453857 420.964233,566.069336 
	C421.299957,567.048279 421.344543,568.139832 421.287506,569.630737 
	C420.637024,569.773071 420.088165,569.516052 419.269043,569.122986 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M432.099670,544.623901 
	C431.852997,545.045044 431.458405,545.297058 431.063782,545.549072 
	C430.969208,545.288391 430.874603,545.027832 430.779999,544.767212 
	C431.170593,544.663086 431.561157,544.558960 432.099670,544.623901 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M555.899719,544.376343 
	C556.146362,543.955261 556.541016,543.703369 556.935669,543.451477 
	C557.030151,543.712036 557.124695,543.972595 557.219238,544.233093 
	C556.828735,544.337219 556.438171,544.441406 555.899719,544.376343 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M489.100952,486.623169 
	C488.853821,487.045013 488.458557,487.297394 488.063293,487.549744 
	C487.968567,487.288757 487.873840,487.027740 487.779114,486.766724 
	C488.170319,486.662354 488.561554,486.558014 489.100952,486.623169 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M303.283630,549.390015 
	C303.747162,549.633301 303.991425,549.931458 304.235687,550.229614 
	C303.845001,550.338318 303.454346,550.447021 303.063721,550.555786 
	C303.063934,550.185486 303.064148,549.815247 303.283630,549.390015 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M612.744263,571.040039 
	C613.424011,571.380920 613.800171,571.835632 614.218079,572.583191 
	C613.323303,572.867432 612.386780,572.858887 610.980713,572.788147 
	C611.154297,572.201904 611.797424,571.677856 612.744263,571.040039 
z"/>
<path fill="#0CB0F5" opacity="1.000000" stroke="none" 
	d="
M385.098785,467.292053 
	C385.755554,466.999329 386.500122,466.998596 387.616852,466.997742 
	C387.054871,467.193085 386.120728,467.388580 385.098785,467.292053 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M382.291260,507.998413 
	C382.648712,508.437714 382.734222,508.896881 382.692871,509.688965 
	C381.942200,509.694641 381.318390,509.367401 380.694580,509.040161 
	C381.136139,508.699493 381.577698,508.358856 382.291260,507.998413 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M502.898315,542.377502 
	C503.145691,541.955505 503.541443,541.703003 503.937225,541.450500 
	C504.032043,541.711670 504.126862,541.972778 504.221710,542.233948 
	C503.830048,542.338318 503.438385,542.442688 502.898315,542.377502 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M271.939392,379.557800 
	C272.591370,379.217804 273.402466,379.114319 274.622986,379.026703 
	C274.958923,379.433228 274.885468,379.823853 274.708069,380.493408 
	C273.768890,380.446320 272.933716,380.120331 271.939392,379.557800 
z"/>
<path fill="#5D87AC" opacity="1.000000" stroke="none" 
	d="
M575.197876,573.186584 
	C575.957520,572.972473 576.724915,573.054688 577.867859,573.228638 
	C577.230835,573.374512 576.218201,573.428711 575.197876,573.186584 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M623.300659,572.662048 
	C624.014893,572.583191 624.697998,572.837524 625.711243,573.248169 
	C625.117432,573.268066 624.193542,573.131653 623.300659,572.662048 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M430.101685,533.300293 
	C430.758423,533.008423 431.503265,533.007019 432.620575,533.006470 
	C432.058624,533.201782 431.124207,533.396301 430.101685,533.300293 
z"/>
<path fill="#05B0ED" opacity="1.000000" stroke="none" 
	d="
M513.603394,428.075989 
	C513.559631,427.533142 513.677979,427.146179 513.796326,426.759155 
	C514.052368,426.866699 514.308472,426.974243 514.564514,427.081787 
	C514.298218,427.465179 514.031921,427.848511 513.603394,428.075989 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M578.679443,559.042175 
	C578.059998,561.063599 576.829956,561.727722 575.087524,559.333923 
	C576.080444,559.021545 577.174744,559.021729 578.679443,559.042175 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M511.722351,543.389832 
	C511.939362,543.815186 511.938965,544.184875 511.938599,544.554504 
	C511.549713,544.444092 511.160858,544.333679 510.771973,544.223267 
	C511.016296,543.926880 511.260651,543.630493 511.722351,543.389832 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M129.385529,519.722900 
	C129.094116,518.596130 129.007935,517.203674 129.064209,515.412048 
	C129.334686,516.494324 129.462723,517.975830 129.385529,519.722900 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M512.322754,534.104980 
	C511.916992,534.384766 511.529144,534.462158 511.141266,534.539551 
	C511.160309,534.280029 511.179382,534.020508 511.198425,533.760986 
	C511.579132,533.808228 511.959869,533.855408 512.322754,534.104980 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M426.925751,558.222351 
	C426.685791,559.011902 426.111816,559.827148 425.241211,560.732971 
	C425.493622,559.965088 426.042664,559.106567 426.925751,558.222351 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M275.684998,354.950256 
	C274.918915,355.076965 274.185638,354.923126 273.093689,354.653473 
	C273.577515,354.319397 274.420013,354.101166 275.481567,353.993958 
	C275.706390,354.293243 275.712128,354.481476 275.684998,354.950256 
z"/>
<path fill="#BC495B" opacity="1.000000" stroke="none" 
	d="
M591.320923,573.049377 
	C591.031616,572.378479 591.064697,571.788696 591.097778,571.198975 
	C591.672180,571.578369 592.246643,571.957764 592.885193,572.623291 
	C592.513977,572.983093 592.078674,573.056824 591.320923,573.049377 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M598.996948,573.996826 
	C598.751282,574.765076 598.508606,575.536499 598.265930,576.307922 
	C598.025513,576.187134 597.785095,576.066345 597.544678,575.945557 
	C597.877563,575.398071 598.210449,574.850647 598.774414,574.153381 
	C599.005493,574.003540 599.000000,574.000000 598.996948,573.996826 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M401.960449,534.382507 
	C401.795563,534.143066 401.787109,533.907959 401.778625,533.672791 
	C401.911285,533.676575 402.043915,533.680359 402.176575,533.684204 
	C402.156677,533.918396 402.136780,534.152588 401.960449,534.382507 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M274.825409,430.845795 
	C275.272339,430.623779 275.874329,430.564117 276.912415,430.648438 
	C276.559143,430.864349 275.769806,430.936249 274.825409,430.845795 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M512.301575,481.762543 
	C512.244751,482.047028 512.082825,482.218018 511.920898,482.388977 
	C511.831146,482.291534 511.741425,482.194122 511.651672,482.096680 
	C511.833252,481.947479 512.014832,481.798248 512.301575,481.762543 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M542.943726,572.311401 
	C543.522461,572.257080 544.089966,572.472168 544.838135,572.942749 
	C544.323425,572.992432 543.627991,572.786560 542.943726,572.311401 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M479.300903,481.763367 
	C479.244171,482.046967 479.082764,482.217407 478.921326,482.387878 
	C478.831879,482.290741 478.742432,482.193604 478.652985,482.096466 
	C478.834045,481.947693 479.015106,481.798950 479.300903,481.763367 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M479.380127,542.039429 
	C479.142090,542.203247 478.908356,542.211548 478.674622,542.219849 
	C478.678467,542.087952 478.682312,541.956177 478.686157,541.824341 
	C478.918915,541.844177 479.151672,541.864075 479.380127,542.039429 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M424.301514,541.762817 
	C424.244659,542.047058 424.082794,542.217957 423.920959,542.388855 
	C423.831268,542.291443 423.741577,542.194092 423.651917,542.096680 
	C423.833435,541.947632 424.014954,541.798523 424.301514,541.762817 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M370.236816,466.300842 
	C369.953094,466.244171 369.782562,466.082733 369.612061,465.921295 
	C369.709198,465.831818 369.806366,465.742340 369.903534,465.652863 
	C370.052338,465.833954 370.201172,466.015015 370.236816,466.300842 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M466.960205,546.380249 
	C466.796356,546.142212 466.787994,545.908325 466.779663,545.674500 
	C466.911530,545.678345 467.043396,545.682129 467.175262,545.685974 
	C467.155426,545.918823 467.135559,546.151611 466.960205,546.380249 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M276.649567,306.977356 
	C276.258362,307.135101 275.873413,307.037720 275.317413,306.841064 
	C275.482086,306.467926 275.817780,306.194031 276.153442,305.920166 
	C276.320923,306.187531 276.488342,306.454895 276.649567,306.977356 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M642.860535,547.910645 
	C642.477295,548.219971 642.078064,548.253967 641.381653,548.235596 
	C641.372009,547.715759 641.659668,547.248230 641.947266,546.780701 
	C642.246399,547.065552 642.545471,547.350403 642.860535,547.910645 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M430.700256,540.235901 
	C430.756836,539.953064 430.917816,539.783081 431.078827,539.613098 
	C431.167999,539.709961 431.257202,539.806824 431.346375,539.903687 
	C431.165802,540.052063 430.985229,540.200378 430.700256,540.235901 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M431.154480,576.503540 
	C431.562714,576.094177 431.946045,575.985779 432.627869,575.857056 
	C432.506775,576.498108 432.087097,577.159485 431.370453,577.913147 
	C431.092163,577.605164 431.110870,577.204834 431.154480,576.503540 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M543.518494,546.918579 
	C543.143127,546.586426 543.003113,546.164062 542.827209,545.409180 
	C543.112183,545.660522 543.432983,546.244446 543.518494,546.918579 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M428.905640,581.766785 
	C428.990295,581.041931 429.461487,580.239807 430.252380,579.283508 
	C430.145477,579.982727 429.718842,580.836121 428.905640,581.766785 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M639.714172,570.751221 
	C639.674622,570.206177 639.938721,569.684387 640.472168,569.029785 
	C640.500244,569.522827 640.259033,570.148682 639.714172,570.751221 
z"/>
<path fill="#3999C9" opacity="1.000000" stroke="none" 
	d="
M274.882080,570.161011 
	C275.319885,570.502930 275.583801,571.018005 275.796143,571.827515 
	C275.399109,571.526062 275.053650,570.930115 274.882080,570.161011 
z"/>
<path fill="#777396" opacity="1.000000" stroke="none" 
	d="
M279.895294,412.807678 
	C279.858795,413.262054 279.664886,413.525024 279.250305,413.894287 
	C278.886688,413.432098 278.743469,412.863403 278.799042,412.148438 
	C279.341522,412.090149 279.588287,412.294952 279.895294,412.807678 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M308.299805,495.763885 
	C308.243256,496.046906 308.082214,496.216980 307.921143,496.387054 
	C307.831909,496.290100 307.742676,496.193176 307.653442,496.096222 
	C307.834076,495.947784 308.014709,495.799377 308.299805,495.763885 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M467.368774,558.731445 
	C467.520477,559.152954 467.508698,559.324219 467.356323,559.711060 
	C466.606384,559.894226 465.997009,559.862000 464.936707,559.744629 
	C465.120514,559.131897 465.755280,558.604248 466.706543,557.994141 
	C467.083862,558.101562 467.144592,558.291382 467.368774,558.731445 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M576.771118,494.153992 
	C576.038452,494.039215 575.241333,493.527527 574.295044,492.688110 
	C574.999451,492.825989 575.853088,493.291534 576.771118,494.153992 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M441.197968,560.788330 
	C440.287537,560.593689 439.567902,560.177429 438.730347,559.456726 
	C439.366760,559.202515 440.121033,559.252686 441.429749,559.442566 
	C441.785675,559.910461 441.587189,560.238586 441.197968,560.788330 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M640.141663,557.766968 
	C640.175659,557.069031 640.578003,556.324585 641.256104,555.408203 
	C641.191162,556.064331 640.850525,556.892334 640.141663,557.766968 
z"/>
<path fill="#3999C9" opacity="1.000000" stroke="none" 
	d="
M278.797424,561.128723 
	C278.661530,561.728394 278.202667,562.371643 277.457275,563.119629 
	C277.605377,562.540283 278.039948,561.856262 278.797424,561.128723 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M344.014313,557.526489 
	C343.583405,557.772095 343.082184,557.787903 342.222748,557.679199 
	C342.557709,557.468750 343.250824,557.382690 344.014313,557.526489 
z"/>
<path fill="#02AFF2" opacity="1.000000" stroke="none" 
	d="
M278.364166,360.911682 
	C278.051575,360.544983 277.979523,360.102448 277.898254,359.322998 
	C278.127625,359.602631 278.366180,360.219208 278.364166,360.911682 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M539.419312,553.932129 
	C539.493469,553.332825 539.868591,552.617554 540.547791,551.785950 
	C540.474670,552.385193 540.097473,553.100647 539.419312,553.932129 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M601.237061,549.300964 
	C600.953247,549.244263 600.782593,549.082764 600.612000,548.921326 
	C600.709167,548.831787 600.806335,548.742249 600.903503,548.652710 
	C601.052368,548.833862 601.201294,549.015015 601.237061,549.300964 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M514.666687,550.999390 
	C514.378052,551.167419 513.756165,551.334778 513.067139,551.251099 
	C513.444458,550.999573 513.888916,550.999146 514.666687,550.999390 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M493.694580,553.970642 
	C493.269562,553.641113 493.097412,553.205688 492.925354,552.417236 
	C493.379181,552.043274 493.832916,552.022339 494.626678,552.004395 
	C494.626984,552.626404 494.287201,553.245544 493.694580,553.970642 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M130.075821,446.356689 
	C129.694168,445.969452 129.325592,445.295044 129.093475,444.316162 
	C129.516251,444.697601 129.802582,445.383575 130.075821,446.356689 
z"/>
<path fill="#3999C9" opacity="1.000000" stroke="none" 
	d="
M275.579224,565.008667 
	C275.662781,565.470825 275.536591,566.028687 275.218994,566.806641 
	C275.141571,566.385864 275.255554,565.745117 275.579224,565.008667 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M222.719269,427.138916 
	C222.984085,427.327148 223.089310,427.721985 223.200623,428.422363 
	C223.206680,428.727936 222.733932,428.823883 222.495285,428.837677 
	C222.357651,428.349457 222.458679,427.847473 222.719269,427.138916 
z"/>
<path fill="#21A4DD" opacity="1.000000" stroke="none" 
	d="
M481.965637,556.246277 
	C481.981750,556.622192 481.720337,557.137146 481.241913,557.848389 
	C481.246002,557.491516 481.467072,556.938416 481.965637,556.246277 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M401.521667,557.434631 
	C401.608643,557.015137 402.026245,556.562378 402.740662,555.987549 
	C403.051727,556.031860 403.065948,556.198181 403.069855,556.614502 
	C402.657135,557.043457 402.254730,557.222412 401.521667,557.434631 
z"/>
<path fill="#15A8E6" opacity="1.000000" stroke="none" 
	d="
M530.210999,571.924561 
	C530.506714,571.722229 531.209412,571.546509 532.081787,571.633545 
	C531.706970,571.914490 531.162476,571.932861 530.210999,571.924561 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M129.371780,251.828644 
	C129.116272,251.545074 129.030167,251.097900 129.078125,250.328995 
	C129.321854,250.559845 129.431519,251.112442 129.371780,251.828644 
z"/>
<path fill="#8C637F" opacity="1.000000" stroke="none" 
	d="
M369.142670,572.115112 
	C369.069946,571.728882 369.098877,571.562866 369.161621,571.145996 
	C369.472015,571.317932 369.748505,571.740723 370.060547,572.415833 
	C369.734741,572.755371 369.450806,572.644409 369.142670,572.115112 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M273.150330,333.323273 
	C273.430237,333.113037 273.946808,333.089020 274.840454,333.170288 
	C275.191132,333.441101 275.164734,333.606567 275.086975,334.019897 
	C274.486053,334.014984 273.936493,333.762238 273.150330,333.323273 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M130.111633,438.744324 
	C130.178299,439.023895 129.691177,439.073059 129.447083,439.054535 
	C129.120117,438.580078 129.037231,438.124176 129.079269,437.337769 
	C129.484436,437.493134 129.764694,437.978943 130.111633,438.744324 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M383.169556,570.936768 
	C382.979919,570.337097 383.243988,569.701965 383.756744,568.819824 
	C384.135925,569.065552 384.266418,569.558289 384.462524,570.427429 
	C384.226471,570.836304 383.924835,570.868774 383.169556,570.936768 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M341.814087,527.469482 
	C342.297089,527.496338 342.746399,527.851624 343.234161,528.507568 
	C342.775177,528.471436 342.277771,528.134766 341.814087,527.469482 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M385.232727,573.896729 
	C385.540680,573.704773 385.970032,573.736328 386.720215,573.813721 
	C386.478729,573.946472 385.916443,574.033386 385.232727,573.896729 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M337.929688,558.202148 
	C338.058563,558.483032 337.738983,558.690857 337.552856,558.720520 
	C337.297058,558.460266 337.227417,558.170410 337.079529,557.440308 
	C337.001221,557.000000 337.000000,557.000000 336.999481,557.000000 
	C337.266266,557.307068 337.533539,557.614197 337.929688,558.202148 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M518.119507,557.986084 
	C517.716003,558.120605 517.585632,557.806763 517.462463,557.232422 
	C517.660706,556.866882 517.851868,556.761902 518.363770,556.593140 
	C518.587280,556.865540 518.489990,557.201660 518.119507,557.986084 
z"/>
<path fill="#3999C9" opacity="1.000000" stroke="none" 
	d="
M280.696533,559.997131 
	C280.867706,560.286865 280.686890,560.521545 280.132141,560.658203 
	C280.016754,560.388794 280.212219,560.182739 280.696533,559.997131 
z"/>
<path fill="#17A8E0" opacity="1.000000" stroke="none" 
	d="
M638.084351,572.734680 
	C637.976318,572.606079 638.141418,572.288025 638.311768,572.237549 
	C638.385498,572.412415 638.288940,572.637817 638.084351,572.734680 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M569.941040,559.235229 
	C569.968567,559.389832 569.772217,559.692322 569.329224,560.118042 
	C569.294067,559.955261 569.505615,559.669128 569.941040,559.235229 
z"/>
<path fill="#1EA3DF" opacity="1.000000" stroke="none" 
	d="
M129.091827,441.227386 
	C129.191711,440.967987 129.684662,440.930603 129.932343,440.954956 
	C130.020538,441.254852 129.830063,441.506989 129.411407,441.866058 
	C129.140137,441.826538 129.066055,441.656677 129.091827,441.227386 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M504.864563,566.809570 
	C504.726715,566.842041 504.494049,566.642029 504.173401,566.204773 
	C504.306396,566.180298 504.534515,566.383545 504.864563,566.809570 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M471.951904,528.777100 
	C471.888550,528.562012 472.083435,528.208557 472.581299,527.773865 
	C472.659607,528.007996 472.434845,528.323364 471.951904,528.777100 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M577.913879,496.676941 
	C577.663025,496.883270 577.453796,496.762695 577.382812,496.266907 
	C577.598389,496.190369 577.743286,496.343140 577.913879,496.676941 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M422.337433,563.282715 
	C422.407288,563.537231 422.154572,563.998840 421.522217,564.598145 
	C421.433319,564.320496 421.724091,563.905212 422.337433,563.282715 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M354.889557,556.849609 
	C354.992157,556.946838 354.509613,556.927795 354.270325,556.901123 
	C354.260620,556.653992 354.512604,556.613281 354.889557,556.849609 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M465.760437,489.007050 
	C466.014679,489.009125 465.648468,489.264587 465.648468,489.264587 
	C465.648468,489.264587 465.219757,489.213257 465.108765,489.108856 
	C465.167267,489.004608 465.336761,489.004791 465.760437,489.007050 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M462.230042,572.698181 
	C462.113037,572.382874 462.315338,572.189758 462.810089,572.043457 
	C463.001404,572.373535 462.816986,572.616821 462.230042,572.698181 
z"/>
<path fill="#15A8E6" opacity="1.000000" stroke="none" 
	d="
M524.029419,572.801025 
	C524.052002,572.647339 524.586182,572.490173 525.341309,572.535278 
	C525.221924,572.757568 524.881470,572.777527 524.029419,572.801025 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M552.054626,572.683411 
	C551.965088,572.483154 552.108032,572.072021 552.561462,571.513428 
	C552.676941,571.734863 552.482056,572.103699 552.054626,572.683411 
z"/>
<path fill="#15A8E6" opacity="1.000000" stroke="none" 
	d="
M528.160156,573.255066 
	C528.029419,573.174255 528.460144,572.936646 528.700195,572.883179 
	C528.856567,573.187439 528.640137,573.356140 528.160156,573.255066 
z"/>
<path fill="#15A8E6" opacity="1.000000" stroke="none" 
	d="
M537.019958,573.835693 
	C537.021362,573.685425 537.262146,573.444458 537.749817,573.127197 
	C537.750977,573.282288 537.505188,573.513672 537.019958,573.835693 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M551.052979,572.874939 
	C550.959106,572.746460 551.264587,572.536560 551.445801,572.548828 
	C551.627075,572.561157 551.542969,572.994263 551.542969,572.994263 
	C551.542969,572.994263 551.146912,573.003418 551.052979,572.874939 
z"/>
<path fill="#17A6E3" opacity="1.000000" stroke="none" 
	d="
M545.020752,346.005066 
	C545.150085,340.857635 545.140747,336.199371 545.460266,331.563721 
	C545.655151,328.735321 546.811584,326.279297 550.248718,326.268433 
	C553.828369,326.257141 554.599304,328.845001 554.739624,331.679016 
	C554.945007,335.826447 555.044678,339.983093 555.048096,344.135895 
	C555.063538,363.090363 555.032837,382.044800 555.006897,400.999268 
	C555.005066,402.328308 554.967407,403.662781 554.843811,404.985138 
	C554.549133,408.138306 553.803101,411.266113 549.903870,411.284668 
	C545.763733,411.304413 545.374268,407.891357 545.347290,404.855072 
	C545.174500,385.405212 545.116516,365.954315 545.020752,346.005066 
z"/>
<path fill="#E4363C" opacity="1.000000" stroke="none" 
	d="
M306.364105,428.739014 
	C301.745941,428.834381 297.576233,428.777100 293.424164,429.020630 
	C289.822296,429.231873 288.839142,427.503845 288.845215,424.229279 
	C288.913574,387.414673 288.939575,350.599884 288.881805,313.785278 
	C288.876251,310.253662 289.939575,308.608917 293.722870,308.670654 
	C303.548950,308.831055 313.380066,308.818756 323.207184,308.702972 
	C326.903961,308.659454 328.608337,310.181732 329.117371,313.835266 
	C330.840424,326.202240 332.700043,338.550995 334.618439,350.889435 
	C335.276154,355.119598 336.260559,359.298981 337.095703,363.501526 
	C337.525635,363.490814 337.955597,363.480133 338.385559,363.469421 
	C339.385925,356.170349 340.397003,348.872742 341.384033,341.571869 
	C342.632050,332.340637 343.957947,323.118256 345.048950,313.868317 
	C345.480988,310.205353 347.071838,308.647003 350.838501,308.700867 
	C360.664764,308.841461 370.495850,308.819397 380.322388,308.678009 
	C384.011810,308.624939 385.277405,310.145416 385.270660,313.743439 
	C385.201508,350.558929 385.194183,387.374756 385.291321,424.190155 
	C385.301331,427.980713 383.792297,429.203247 380.231049,429.020477 
	C375.078064,428.756012 369.909698,428.569214 364.753387,428.660339 
	C360.681000,428.732239 359.255829,427.123047 359.273895,423.023773 
	C359.355316,404.535889 359.175842,386.046906 359.100464,367.558289 
	C359.080780,362.725281 359.097504,357.892151 359.097504,353.059082 
	C358.418030,353.035614 357.738556,353.012146 357.059113,352.988708 
	C356.408386,356.325775 355.586548,359.639862 355.135010,363.003662 
	C352.390350,383.451477 349.700592,403.906830 347.076172,424.370392 
	C346.718506,427.159088 345.523804,428.642731 342.647064,428.617859 
	C338.989166,428.586212 335.261658,428.987793 331.705597,428.377930 
	C330.182800,428.116760 328.089203,425.927002 327.833130,424.356232 
	C325.477325,409.905243 323.484680,395.395294 321.350800,380.907776 
	C320.163483,372.846832 318.934204,364.791809 317.652100,356.745514 
	C317.491852,355.739746 316.891510,354.804108 315.459656,353.941437 
	C315.346497,355.670471 315.139252,357.399200 315.134460,359.128510 
	C315.078217,379.450897 315.062256,399.773376 315.013519,420.095795 
	C314.993866,428.290436 314.969177,428.290375 306.364105,428.739014 
z"/>
<path fill="#03AFF2" opacity="1.000000" stroke="none" 
	d="
M164.160416,339.694153 
	C163.756149,338.336212 163.377548,336.561798 162.997650,334.381348 
	C163.392944,335.742737 163.789520,337.510223 164.160416,339.694153 
z"/>
<path fill="#03AFF2" opacity="1.000000" stroke="none" 
	d="
M202.670959,333.941223 
	C202.616821,334.685638 202.228989,335.413483 201.841156,336.141327 
	C201.573502,335.621704 201.305832,335.102051 201.020111,334.273590 
	C201.447113,333.951385 201.892166,333.938019 202.670959,333.941223 
z"/>
<path fill="#E6343B" opacity="1.000000" stroke="none" 
	d="
M399.540039,421.934906 
	C399.552277,385.615204 399.620911,349.787384 399.497955,313.960175 
	C399.484070,309.917725 401.006317,308.625092 404.755035,308.701752 
	C410.918182,308.827789 417.093353,308.927032 423.248871,308.677307 
	C427.731628,308.495453 429.547180,310.108307 429.432831,314.707733 
	C429.209259,323.700623 429.383301,332.703278 429.385132,341.701904 
	C429.390503,367.864685 429.394684,394.027466 429.393799,420.190247 
	C429.393494,428.628265 429.317505,428.670074 420.649048,428.647125 
	C415.650696,428.633881 410.636353,428.481415 405.657990,428.817413 
	C400.764526,429.147675 398.869720,427.152863 399.540039,421.934906 
z"/>
<path fill="#22A5DF" opacity="1.000000" stroke="none" 
	d="
M363.434296,499.492371 
	C364.635132,501.068085 365.717041,502.325134 366.378571,503.774109 
	C367.677490,506.619202 366.739258,508.227905 363.465607,508.209137 
	C358.830048,508.182617 354.194366,508.182343 349.558716,508.163818 
	C346.254669,508.150604 345.533173,506.559723 346.714081,503.652496 
	C349.436676,496.949890 356.909332,494.930481 363.434296,499.492371 
z"/>
<path fill="#ED3034" opacity="1.000000" stroke="none" 
	d="
M551.236450,497.572144 
	C552.746887,498.235229 554.093689,498.597015 554.500732,498.706360 
	C554.140198,509.426270 553.787720,519.907959 553.435242,530.389587 
	C552.702026,530.450806 551.968811,530.512024 551.235596,530.573181 
	C550.804382,529.069092 550.031982,527.571899 550.001099,526.059631 
	C549.868958,519.579773 549.953430,513.095459 549.962219,506.612732 
	C549.974792,497.412079 547.330750,495.097504 538.077942,496.206757 
	C537.336121,496.295685 536.589966,496.348755 535.331787,496.466583 
	C541.419922,491.542236 546.488342,491.943604 551.236450,497.572144 
z"/>
<path fill="#ED3037" opacity="1.000000" stroke="none" 
	d="
M531.232727,499.267822 
	C530.604919,509.381561 529.977112,519.495300 529.329651,529.924988 
	C525.320862,527.174683 524.493835,500.996948 527.916077,494.424591 
	C528.772461,495.935760 529.510437,497.238037 530.641235,498.785828 
	C531.034119,499.031372 531.232727,499.267822 531.232727,499.267822 
z"/>
<path fill="#03AFF2" opacity="1.000000" stroke="none" 
	d="
M579.253906,509.418823 
	C580.520630,509.676178 582.644714,510.291382 582.680298,511.010040 
	C582.957886,516.614624 582.833923,522.239136 582.833923,527.857483 
	C582.431519,527.869812 582.029175,527.882141 581.626770,527.894470 
	C581.082397,523.389893 580.538025,518.885254 579.672485,514.189697 
	C579.282288,512.607422 579.213135,511.216003 579.253906,509.418823 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M576.678894,532.740784 
	C576.878723,533.910522 576.746338,534.952087 576.613953,535.993652 
	C576.351440,536.043945 576.088989,536.094177 575.826477,536.144470 
	C575.556580,535.300781 575.286743,534.457092 575.002258,533.269897 
	C575.440674,532.821716 575.893677,532.717163 576.678894,532.740784 
z"/>
<path fill="#ED3037" opacity="1.000000" stroke="none" 
	d="
M530.934937,498.913086 
	C531.234924,498.683746 531.634094,498.572723 532.033325,498.461670 
	C531.890381,498.682709 531.747498,498.903748 531.418701,499.196289 
	C531.232727,499.267822 531.034119,499.031372 530.934937,498.913086 
z"/>
<path fill="#E6353C" opacity="1.000000" stroke="none" 
	d="
M663.666077,370.274597 
	C658.009705,378.559723 649.633057,379.962463 641.001831,380.148438 
	C628.728088,380.412872 630.007141,378.801880 629.946045,391.571838 
	C629.893555,402.561127 629.772095,413.551819 629.900085,424.539307 
	C629.943176,428.237976 628.638550,429.535492 624.925415,429.417328 
	C617.939941,429.195007 610.940613,429.315491 603.948975,429.422333 
	C600.494873,429.475098 598.785645,428.332825 598.793335,424.523071 
	C598.868469,387.223297 598.793518,349.923157 598.941223,312.623840 
	C598.947021,311.164734 600.767700,308.443237 601.736389,308.446045 
	C619.137207,308.497101 636.722168,306.028137 653.791626,311.468262 
	C659.283875,313.218628 663.265991,317.415375 664.439270,322.568756 
	C667.911316,337.819336 667.590637,353.273895 664.599976,368.642151 
	C664.509888,369.105133 664.117737,369.509369 663.666077,370.274597 
M640.568115,338.905273 
	C640.124573,336.637878 640.350952,333.817627 639.058167,332.251099 
	C637.626526,330.516449 634.872070,329.644196 632.529968,329.096252 
	C632.095398,328.994568 630.218140,332.184662 630.149963,333.900970 
	C629.886536,340.529083 630.037109,347.176422 630.140076,353.814911 
	C630.175598,356.106293 629.011841,359.238831 632.901794,359.604004 
	C636.212830,359.914856 639.094849,358.774261 639.538757,355.120361 
	C640.154541,350.052063 640.254272,344.921051 640.568115,338.905273 
z"/>
<path fill="#19AAE4" opacity="1.000000" stroke="none" 
	d="
M424.522583,508.360718 
	C419.459320,508.353241 414.396057,508.345734 408.732361,508.070221 
	C407.514740,506.785797 406.897552,505.769348 406.280334,504.752930 
	C406.962860,503.460724 407.645355,502.168518 408.747192,500.651489 
	C409.166565,500.426697 409.450714,500.169220 409.450714,500.169220 
	C409.450714,500.169220 409.367493,499.794678 409.367493,499.794678 
	C410.709412,498.961304 412.051331,498.127930 414.118347,497.267212 
	C416.227600,497.162811 417.611755,497.085754 418.995941,497.008698 
	C420.906036,498.152100 422.816101,499.295502 424.960022,501.037140 
	C424.970123,503.877167 424.746368,506.118958 424.522583,508.360718 
z"/>
<path fill="#07B0F6" opacity="1.000000" stroke="none" 
	d="
M412.531128,514.529541 
	C413.371307,516.601807 413.864899,518.535156 414.381989,520.560669 
	C409.205139,521.751770 408.901489,518.205261 408.027954,514.804749 
	C408.149933,514.315308 408.326630,514.241455 408.822021,514.483398 
	C409.427399,514.751465 409.714081,514.703674 410.000793,514.655884 
	C410.728729,514.567444 411.456635,514.479004 412.531128,514.529541 
z"/>
<path fill="#04AFF6" opacity="1.000000" stroke="none" 
	d="
M409.589935,488.998322 
	C409.395050,489.931122 408.791351,490.861023 408.072571,492.108215 
	C407.954071,492.708282 407.811707,492.861450 407.391541,492.789581 
	C406.283112,492.673676 405.297852,492.538788 404.348877,492.668854 
	C403.590302,492.772827 402.875854,493.199036 401.540619,493.714081 
	C402.729401,488.979797 405.928040,488.941956 409.589935,488.998322 
z"/>
<path fill="#876786" opacity="1.000000" stroke="none" 
	d="
M424.883484,508.508606 
	C424.746368,506.118958 424.970123,503.877167 425.204468,501.258942 
	C428.825653,504.714172 428.832184,506.440277 424.883484,508.508606 
z"/>
<path fill="#ED3034" opacity="1.000000" stroke="none" 
	d="
M430.185059,524.382690 
	C429.330902,524.974365 428.689850,525.350220 428.048828,525.726135 
	C427.766235,525.377075 427.483643,525.027954 427.201080,524.678894 
	C428.329987,523.858765 429.458923,523.038635 430.587860,522.218445 
	C430.524658,522.867981 430.461426,523.517456 430.185059,524.382690 
z"/>
<path fill="#ED3034" opacity="1.000000" stroke="none" 
	d="
M423.974823,529.536682 
	C422.875519,529.742004 421.986725,529.742004 421.097900,529.742004 
	C421.151031,529.310242 421.204163,528.878418 421.257294,528.446655 
	C422.233307,528.741577 423.209320,529.036438 423.974823,529.536682 
z"/>
<path fill="#876786" opacity="1.000000" stroke="none" 
	d="
M406.012573,504.917664 
	C406.897552,505.769348 407.514740,506.785797 408.297729,508.115845 
	C405.541779,508.950867 404.699463,507.783264 406.012573,504.917664 
z"/>
<path fill="#876786" opacity="1.000000" stroke="none" 
	d="
M418.732574,496.795715 
	C417.611755,497.085754 416.227600,497.162811 414.435425,497.151001 
	C415.508057,496.902283 416.988617,496.742493 418.732574,496.795715 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M432.613159,525.727173 
	C432.711304,525.930786 432.533600,526.271179 432.125519,526.789124 
	C432.042542,526.599121 432.189911,526.231506 432.613159,525.727173 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M409.774902,514.493225 
	C409.714081,514.703674 409.427399,514.751465 409.072754,514.623657 
	C409.186218,514.408875 409.367615,514.369690 409.774902,514.493225 
z"/>
<path fill="#876786" opacity="1.000000" stroke="none" 
	d="
M409.214905,499.798279 
	C409.367493,499.794678 409.450714,500.169220 409.450714,500.169220 
	C409.450714,500.169220 409.166565,500.426697 408.982666,500.369080 
	C408.761230,500.076782 408.849091,499.906921 409.214905,499.798279 
z"/>
<path fill="#15A8E6" opacity="1.000000" stroke="none" 
	d="
M488.271362,521.286621 
	C486.250519,513.774109 484.782867,506.768890 490.451050,500.340607 
	C496.210785,493.808502 506.541687,497.200470 508.694794,505.631134 
	C509.495667,508.766998 509.443970,512.229858 509.195679,515.499207 
	C508.808655,520.595825 506.733856,525.005005 501.399536,526.538940 
	C496.063995,528.073242 491.582520,526.424316 488.271362,521.286621 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M478.961090,516.557800 
	C477.874969,517.064880 476.892426,517.144653 475.422913,517.325317 
	C474.935913,517.426086 474.973907,517.428955 474.956787,517.449463 
	C472.838165,514.370972 470.736694,511.271942 468.635223,508.172943 
	C469.017029,507.757294 469.398834,507.341644 469.780609,506.925995 
	C471.317688,508.206696 472.854736,509.487396 474.478912,510.840668 
	C474.849945,509.415558 475.275391,507.781586 475.886414,505.434753 
	C476.862915,507.325531 477.487122,508.534210 478.396606,509.873108 
	C478.809448,512.045715 478.937073,514.088135 478.961090,516.557800 
z"/>
<path fill="#03AFF2" opacity="1.000000" stroke="none" 
	d="
M457.994690,517.009766 
	C455.357544,517.334045 452.720398,517.658386 449.686401,517.986328 
	C449.169617,514.188660 449.049713,510.387299 449.115662,506.213867 
	C453.931244,506.084412 454.326111,508.681030 453.287598,513.020325 
	C454.967163,512.063354 456.646698,511.106384 458.639404,510.467346 
	C458.633240,512.860107 458.313934,514.934937 457.994690,517.009766 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M257.310516,380.012512 
	C257.190216,379.757660 257.398682,379.524811 257.967163,379.373383 
	C258.021759,379.592285 257.792419,379.785522 257.310516,380.012512 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M257.247040,333.940125 
	C257.239197,333.787445 257.448608,333.537659 257.905243,333.240509 
	C257.969543,333.458954 257.740143,333.675568 257.247040,333.940125 
z"/>
<path fill="#03B0F5" opacity="1.000000" stroke="none" 
	d="
M458.173279,517.301270 
	C458.313934,514.934937 458.633240,512.860107 458.976257,510.393250 
	C461.475647,509.239075 462.958801,509.445007 463.141266,512.725037 
	C463.236420,514.434998 464.794556,516.063599 466.289429,518.837891 
	C466.255707,518.881287 464.962250,521.136719 464.542328,520.984436 
	C462.356384,520.191406 460.392853,518.785461 458.173279,517.301270 
z"/>
<path fill="#07B0F6" opacity="1.000000" stroke="none" 
	d="
M428.093658,516.361877 
	C425.083038,516.749817 421.878418,516.919861 418.673798,517.089905 
	C418.666748,516.774597 418.659698,516.459290 418.652679,516.143982 
	C421.735016,516.143982 424.817322,516.143982 428.093658,516.361877 
z"/>
<path fill="#07B0F6" opacity="1.000000" stroke="none" 
	d="
M424.145599,523.202026 
	C423.183167,522.427551 422.097748,521.537109 421.012329,520.646606 
	C421.586761,520.189758 422.161194,519.732910 422.735596,519.276062 
	C423.201721,520.354675 423.667847,521.433228 424.214783,522.897339 
	C424.295624,523.282837 424.022614,523.086060 424.145599,523.202026 
z"/>
<path fill="#DC3842" opacity="1.000000" stroke="none" 
	d="
M302.740417,476.771790 
	C314.394196,476.642700 325.685822,476.685730 337.437286,476.681915 
	C339.041077,477.987488 340.185028,479.339844 341.328979,480.692200 
	C339.705231,481.481873 338.107300,482.894104 336.453705,482.962616 
	C323.278503,483.508575 323.286194,483.430450 323.302490,496.720276 
	C323.313904,506.039429 323.408722,515.361267 323.201447,524.675964 
	C323.147858,527.083374 325.003906,531.247192 320.381744,531.232361 
	C315.693481,531.217285 317.136902,526.991272 317.108429,524.306519 
	C316.984955,512.659058 316.899719,501.005310 317.173401,489.362640 
	C317.282318,484.728516 316.125580,482.396576 311.063751,483.005981 
	C308.439392,483.321930 305.716400,483.307953 303.097137,482.976135 
	C301.721710,482.801880 300.473724,481.621277 299.168213,480.895142 
	C300.238220,479.578064 301.308228,478.260956 302.740417,476.771790 
z"/>
<path fill="#01B2F6" opacity="1.000000" stroke="none" 
	d="
M312.998810,489.463318 
	C313.001007,487.972839 313.003204,486.940796 313.006561,485.524353 
	C316.702545,487.849060 314.796265,490.999207 313.387939,494.311005 
	C313.042206,492.998871 313.020508,491.460327 312.998810,489.463318 
z"/>
<path fill="#01B2F6" opacity="1.000000" stroke="none" 
	d="
M310.653442,485.053345 
	C310.369232,485.262360 309.762238,485.408783 309.089111,485.316406 
	C309.458893,485.048615 309.894775,485.019684 310.653442,485.053345 
z"/>
<path fill="#5D87AC" opacity="1.000000" stroke="none" 
	d="
M627.056824,565.125244 
	C628.869629,564.000061 630.425964,563.116150 631.982300,562.232300 
	C632.328735,562.692505 632.675232,563.152771 633.021667,563.612976 
	C631.466553,564.953430 629.911377,566.293823 628.356262,567.634277 
	C627.837585,566.878418 627.318970,566.122559 627.056824,565.125244 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M618.504028,563.007751 
	C618.657593,564.428711 618.811157,565.849609 619.151489,567.564148 
	C618.754639,568.017517 618.171082,568.177246 617.279419,568.373047 
	C616.653992,567.515686 616.336609,566.622253 616.215698,565.262207 
	C616.630249,564.562561 616.711731,564.288879 616.656616,563.974487 
	C616.656616,563.974487 616.942749,563.612366 617.394897,563.366272 
	C618.066040,563.082703 618.285034,563.045227 618.504028,563.007751 
z"/>
<path fill="#03AFF2" opacity="1.000000" stroke="none" 
	d="
M637.398315,552.874695 
	C637.249512,552.798950 637.295288,552.499146 637.516113,552.132263 
	C637.658508,552.260376 637.625732,552.455444 637.398315,552.874695 
z"/>
<path fill="#A55268" opacity="1.000000" stroke="none" 
	d="
M320.262909,561.243408 
	C325.443237,561.049683 320.382568,565.302246 322.515442,566.431580 
	C321.587921,567.870972 320.660431,569.310303 319.732941,570.749695 
	C318.738129,569.710327 317.743317,568.670959 316.860443,566.945435 
	C318.069244,564.587341 319.166077,562.915344 320.262909,561.243408 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M320.230957,560.986816 
	C319.166077,562.915344 318.069244,564.587341 316.812683,566.563293 
	C315.698212,566.917908 314.743439,566.968506 313.430969,566.984741 
	C313.077087,566.545837 313.080872,566.141357 313.369019,565.208862 
	C314.387390,562.836487 315.121429,560.992188 316.006134,559.009644 
	C316.156830,558.871521 316.456329,558.593201 316.456329,558.593201 
	C317.458893,559.094177 318.461456,559.595215 319.802490,560.156250 
	C320.160278,560.387512 320.179626,560.558838 320.230957,560.986816 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M332.843872,563.047974 
	C333.200073,563.255859 333.311707,563.476562 333.333557,564.058960 
	C332.694550,564.240479 332.145325,564.060181 331.596069,563.879883 
	C331.930481,563.606812 332.264893,563.333801 332.843872,563.047974 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M397.089233,566.751831 
	C399.223358,564.497681 401.342072,562.579224 404.270416,559.927673 
	C403.863068,566.663208 400.675171,569.133484 397.089233,566.751831 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M403.001129,573.999634 
	C402.974609,574.661316 402.853363,574.857056 402.497498,575.080383 
	C401.189667,575.096619 400.040985,575.038513 398.586029,575.042786 
	C398.251495,574.510742 398.223206,573.916443 398.118591,572.881287 
	C399.093872,571.311096 400.145447,570.181702 402.158844,568.019287 
	C402.556671,570.707397 402.769287,572.143921 403.001129,573.999634 
z"/>
<path fill="#AE4C60" opacity="1.000000" stroke="none" 
	d="
M432.022217,564.059204 
	C433.063751,562.950134 434.105255,561.841064 435.447937,560.591431 
	C436.180573,561.814941 436.612000,563.178955 436.886169,564.760010 
	C436.134247,565.830933 435.539581,566.684937 434.944885,567.538940 
	C433.810455,568.287659 432.675995,569.036377 431.252869,569.857056 
	C430.452698,569.640747 429.941223,569.352417 429.243439,568.695801 
	C430.045502,566.904724 431.033844,565.481934 432.022217,564.059204 
z"/>
<path fill="#7C6B8D" opacity="1.000000" stroke="none" 
	d="
M431.659180,563.986938 
	C431.033844,565.481934 430.045502,566.904724 428.950439,568.637329 
	C427.711517,568.579407 426.579224,568.211609 425.446960,567.843872 
	C426.565521,566.264832 427.684113,564.685791 429.094391,562.993713 
	C430.022797,563.225342 430.659454,563.570068 431.659180,563.986938 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M427.897461,573.976624 
	C427.254028,573.829285 426.866547,573.535828 426.202606,573.004395 
	C426.636719,572.304138 427.347290,571.841919 428.057861,571.379639 
	C428.089691,572.196594 428.121552,573.013550 427.897461,573.976624 
z"/>
<path fill="#7C6B8D" opacity="1.000000" stroke="none" 
	d="
M367.956726,551.421875 
	C368.981201,551.731750 369.826996,552.166199 370.878510,552.809326 
	C370.942657,554.391541 370.801056,555.765198 370.554810,557.541748 
	C370.069061,558.202332 369.687988,558.459961 369.089294,558.799316 
	C367.989502,557.530396 367.107300,556.179749 365.942322,554.728149 
	C366.049652,553.940552 366.439758,553.253906 366.935242,552.288208 
	C367.290283,551.860901 367.536102,551.706726 367.956726,551.421875 
z"/>
<path fill="#7C6B8D" opacity="1.000000" stroke="none" 
	d="
M363.672180,563.214722 
	C363.742859,562.645569 364.060730,561.922729 364.698792,561.134216 
	C364.652435,561.732727 364.285889,562.396851 363.672180,563.214722 
z"/>
<path fill="#717396" opacity="1.000000" stroke="none" 
	d="
M362.144470,568.583801 
	C361.991577,568.610474 361.801697,568.399475 361.731842,568.063721 
	C361.851837,567.938904 362.107513,568.346191 362.144470,568.583801 
z"/>
<path fill="#7C6B8D" opacity="1.000000" stroke="none" 
	d="
M365.310913,560.981689 
	C365.307129,560.796997 365.547119,560.569397 366.042908,560.325317 
	C366.128876,560.611145 365.880859,560.821106 365.310913,560.981689 
z"/>
<path fill="#5788B9" opacity="1.000000" stroke="none" 
	d="
M590.723755,560.032959 
	C590.807922,560.271545 590.609314,560.483826 590.162170,560.727539 
	C589.976196,560.438660 590.151978,560.187988 590.723755,560.032959 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M524.453247,553.990601 
	C525.466553,553.056519 526.776672,552.115417 528.426147,551.204346 
	C528.402649,553.709961 528.039734,556.185547 527.350220,558.841919 
	C526.075256,557.428894 530.240417,553.528015 524.453247,553.990601 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M518.711853,561.553711 
	C518.183594,560.903687 517.797668,559.899048 517.562866,558.671082 
	C518.094116,559.364868 518.474182,560.281982 518.711853,561.553711 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M521.690552,563.114990 
	C521.811829,563.297485 521.715210,563.625244 521.394897,564.095276 
	C521.271667,563.911743 521.372131,563.586060 521.690552,563.114990 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M523.013794,554.790283 
	C522.903320,554.471497 523.135559,554.260132 523.706055,554.154480 
	C523.832397,554.489441 523.603088,554.701843 523.013794,554.790283 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M525.063232,560.234924 
	C525.028076,560.438477 524.743164,560.601501 524.209106,560.722229 
	C524.244568,560.518066 524.529053,560.356201 525.063232,560.234924 
z"/>
<path fill="#CB404D" opacity="1.000000" stroke="none" 
	d="
M602.048950,559.028015 
	C602.048950,559.028015 602.495850,558.886780 602.715332,558.804565 
	C605.525635,558.922607 608.116394,559.122986 611.462708,559.381775 
	C610.734131,560.896240 610.219177,561.966675 609.384033,563.277588 
	C607.245667,563.380737 605.397766,563.392517 603.622803,563.038513 
	C602.823486,562.879150 602.169678,561.990173 601.608398,560.845703 
	C601.860901,559.848816 601.954895,559.438416 602.048950,559.028015 
z"/>
<path fill="#3C95C6" opacity="1.000000" stroke="none" 
	d="
M601.642334,559.012451 
	C601.954895,559.438416 601.860901,559.848816 601.580505,560.517822 
	C600.770691,560.871216 600.147217,560.966064 599.207886,561.090454 
	C598.601624,560.619934 598.311218,560.119812 598.001953,559.319702 
	C599.067200,559.012146 600.151489,559.004517 601.642334,559.012451 
z"/>
<path fill="#4494C5" opacity="1.000000" stroke="none" 
	d="
M312.093781,558.362915 
	C312.375946,557.908386 312.600739,557.721619 312.930176,557.326660 
	C313.775391,557.135071 314.487732,557.117737 315.554169,557.134888 
	C316.020874,557.489807 316.133514,557.810181 316.351227,558.361877 
	C316.456329,558.593201 316.156830,558.871521 315.665588,558.990356 
	C314.093872,559.362976 313.013397,559.616699 311.932953,559.870422 
	C311.976868,559.468445 312.020752,559.066528 312.093781,558.362915 
z"/>
<path fill="#19AAE4" opacity="1.000000" stroke="none" 
	d="
M356.859314,561.306885 
	C357.757507,561.671143 358.458710,562.329529 359.291260,563.350586 
	C358.932526,564.555115 358.442413,565.397095 357.796875,566.493774 
	C354.995575,567.901428 352.349762,569.054504 349.304688,570.194275 
	C348.905426,570.180908 348.887085,570.125427 348.749939,569.763245 
	C348.484894,569.190186 348.357025,568.979370 348.229156,568.768555 
	C349.755646,567.471191 351.282104,566.173828 352.808594,564.876404 
	C354.093170,563.784607 355.377808,562.692871 356.859314,561.306885 
z"/>
<path fill="#BC495B" opacity="1.000000" stroke="none" 
	d="
M481.149048,564.671692 
	C481.725891,563.991699 482.172546,563.734436 482.819153,563.308594 
	C483.460907,563.711426 483.902740,564.282654 484.626984,564.923279 
	C484.846405,565.423035 484.783447,565.853516 484.374023,566.679077 
	C484.273987,567.742249 484.520386,568.410339 484.766785,569.078369 
	C484.766785,569.078369 484.901703,569.085938 484.923340,569.150330 
	C484.979553,570.250549 485.014160,571.286438 485.052856,572.712524 
	C481.689392,574.819092 480.374329,573.386169 480.482788,570.026550 
	C480.535980,568.378479 480.832458,566.738220 481.149048,564.671692 
z"/>
<path fill="#2BA0D5" opacity="1.000000" stroke="none" 
	d="
M379.336273,561.989502 
	C379.172546,561.776062 379.321991,561.491821 379.782776,561.134460 
	C379.936981,561.341736 379.788757,561.627563 379.336273,561.989502 
z"/>
<path fill="#0AADEB" opacity="1.000000" stroke="none" 
	d="
M435.259705,567.337585 
	C435.539581,566.684937 436.134247,565.830933 437.134827,564.955322 
	C436.885315,565.667847 436.229889,566.402039 435.259705,567.337585 
z"/>
<path fill="#22A5DF" opacity="1.000000" stroke="none" 
	d="
M593.598022,564.351074 
	C593.752502,566.248718 594.894958,569.423157 590.772705,567.194824 
	C591.494385,566.000366 592.356079,565.137085 593.598022,564.351074 
z"/>
<path fill="#6A779D" opacity="1.000000" stroke="none" 
	d="
M348.082520,568.877625 
	C348.357025,568.979370 348.484894,569.190186 348.722168,569.754272 
	C348.532959,569.733887 348.234436,569.360291 348.082520,568.877625 
z"/>
<path fill="#797091" opacity="1.000000" stroke="none" 
	d="
M494.328857,563.567749 
	C495.460724,563.022705 496.694092,562.751892 498.603851,562.332520 
	C498.289429,564.592896 498.038788,566.394592 497.729980,568.573364 
	C496.330170,569.031738 494.988525,569.113037 493.646912,569.194336 
	C493.908081,567.410278 494.169250,565.626160 494.328857,563.567749 
M495.511505,567.529053 
	C495.511505,567.529053 495.492493,567.480896 495.511505,567.529053 
z"/>
<path fill="#BC495B" opacity="1.000000" stroke="none" 
	d="
M311.726318,559.958008 
	C313.013397,559.616699 314.093872,559.362976 315.514893,559.128540 
	C315.121429,560.992188 314.387390,562.836487 313.390076,564.905823 
	C312.080963,565.307190 311.035217,565.483643 309.397858,565.759827 
	C310.099335,563.775330 310.605621,562.343079 311.111511,560.571167 
	C311.111115,560.231506 311.519653,560.045593 311.726318,559.958008 
z"/>
<path fill="#6A779D" opacity="1.000000" stroke="none" 
	d="
M337.124939,569.150146 
	C337.274811,569.049011 337.453705,569.126770 337.846069,569.403687 
	C337.757660,569.511597 337.455811,569.420288 337.124939,569.150146 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M484.785217,568.706909 
	C484.520386,568.410339 484.273987,567.742249 484.252350,566.982788 
	C484.585968,567.372742 484.694824,567.854065 484.785217,568.706909 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M465.505829,563.081177 
	C465.740997,563.280212 465.653137,563.551392 465.226013,563.881409 
	C465.023163,563.684570 465.121826,563.422302 465.505829,563.081177 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M462.325500,561.932983 
	C462.244568,561.748962 462.379028,561.456360 462.749634,561.076172 
	C462.947571,561.325867 462.799286,561.604431 462.325500,561.932983 
z"/>
<path fill="#8C637F" opacity="1.000000" stroke="none" 
	d="
M618.501770,562.782104 
	C618.285034,563.045227 618.066040,563.082703 617.517700,563.170898 
	C617.382751,562.916870 617.577087,562.612183 618.037964,562.228516 
	C618.304443,562.149597 618.499512,562.556519 618.501770,562.782104 
z"/>
<path fill="#9D5971" opacity="1.000000" stroke="none" 
	d="
M616.488403,564.103760 
	C616.711731,564.288879 616.630249,564.562561 616.220459,564.905457 
	C616.025452,564.717102 616.122559,564.456299 616.488403,564.103760 
z"/>
<path fill="#22A3DB" opacity="1.000000" stroke="none" 
	d="
M640.571533,339.361084 
	C640.254272,344.921051 640.154541,350.052063 639.538757,355.120361 
	C639.094849,358.774261 636.212830,359.914856 632.901794,359.604004 
	C629.011841,359.238831 630.175598,356.106293 630.140076,353.814911 
	C630.037109,347.176422 629.886536,340.529083 630.149963,333.900970 
	C630.218140,332.184662 632.095398,328.994568 632.529968,329.096252 
	C634.872070,329.644196 637.626526,330.516449 639.058167,332.251099 
	C640.350952,333.817627 640.124573,336.637878 640.571533,339.361084 
z"/>
<path fill="#8E5F7B" opacity="1.000000" stroke="none" 
	d="
M495.502014,567.505005 
	C495.492493,567.480896 495.511505,567.529053 495.502014,567.505005 
z"/>


        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}